import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  History,
  NoteDrawer,
  SimpleTabs,
} from 'components';
import { loadActions } from 'store';
import {
  FindLoad,
  Images,
  PermissionsList,
  RolePermission, useModal,
} from 'utils';
import { loadsStyle } from '../../../pages/loads/styles';
import { GroupDetails } from './core/groupDetails';
import { CustomDeleteModal } from '../../modals';
import { groupStatusEnums } from './core/constants';
import { GroupTransactions } from './core/groupTransactions';

const CANCEL_ACTION_TYPE = 'CANCEL_GROUPED_LOADS';

export const GroupedLoadDetails = () => {
  const { groupById } = useSelector((state) => ({
    groupById: state.loads.groupById,
  }));
  const { open, close } = useModal();
  const classes = loadsStyle();
  const params = useParams();
  const dispatch = useDispatch();
  const cancelLoader = FindLoad(CANCEL_ACTION_TYPE);
  const history = useHistory()
  const info = history?.location?.state

  useEffect(() => {
    dispatch(loadActions.getGroupById(params.id));
  }, [dispatch]);

  const tabsLabels = [
    {
      label: 'Details',
    },
    {
      label: 'Transactions',
    },
    {
      label: 'History',
    },
  ];

  const tabsContent = [
    {
      tabComponent: <GroupDetails />,
      tab: 'Details',
    },
    {
      tabComponent: <GroupTransactions />,
      tab: 'Transactions',
    },
    {
      tabComponent: <History onModel={'loadGroup'} resourceId={params.id} />,
      tab: 'History',
    },
  ];


  return (
    <div>
      <>
        <SimpleTabs
          headerWidth={'480px'}
          head={
            <div className="space-between" style={{ gap: 15 }}>
              <div className={classes.loadIdWrapper}>
                <img src={Images.loadFillBlue} alt={'icon'} />
                <p>{groupById?.displayId} </p>
              </div>
              <NoteDrawer title={'Load'} />
            </div>
          }
          noBread={true}
          border={'blue'}
          height={'full'}
          tabsLabels={tabsLabels}
          tabsContent={tabsContent}
          defaultTab={'Details'}
        />
        {RolePermission([PermissionsList.CANCEL_LOAD?.code], 'agentView') &&
          groupById?.status &&
          groupById?.status !== groupStatusEnums.CANCELLED && (!info?.activeTab || info?.activeTab === 'Details') &&
          <div className={classes.cancelLoadWrapper}>
            <button
              className={classes.cancelLoad}
              onClick={() => open(
                <CustomDeleteModal
                  modalText={'Are you sure you want to cancel this Group loads?'}
                  cancelButton={'Cancel Group'}
                  closeButton={'Go Back'}
                  loading={!!cancelLoader?.length}
                  actionType={CANCEL_ACTION_TYPE}
                  text="Cancel Group Loads?"
                  info={'Load'}
                  handleDel={() => dispatch(loadActions.cancelGroup(params?.id))}
                  afterSuccess={()=> close()}
                />,
              )}
            >
              Cancel Group Loads
            </button>
          </div>
        }
      </>
    </div>
  );
};