import React from 'react';
import { DateRow, LoadIconAndTitle, PriceRow, TextRow, TypeRow } from 'components';
import { Images, PermissionsList, RolePermission, userType } from 'utils';
import { statusRow } from '../../../../pages/loads/constants';
import { handleGetTypes } from '../../transactions/core/constants';
import { vendorEnums } from '../../../../pages';


export const groupLoadsHead = [
  { name: 'displayId', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false, },
  // { name: 'createdDate', title: 'Date', icon: 'date', smallSize: 6, type: 'date', width: '90px' },
  { name: 'customer', searchKey: 'CUSTOMER', title: 'Customer', custom: false },
  { name: 'carrier', searchKey: 'CARRIER', title: 'Vendor', custom: false },
  { name: '', title: 'Pick Up', custom: false },
  { name: '', title: 'Drop Off', custom: false },
  { name: 'customerRate', title: 'Customer Rate', smallSize: 4, custom: false },
  { name: 'carrierRate', title: 'Vendor Rate', smallSize: 4, custom: false },
  { name: 'profit', title: 'Load Profit', custom: false },
  { name: 'status', title: 'Status', custom: false, width: '110px' },
];

export const groupLoadsBody = [
  { rowText: (item) => <LoadIconAndTitle icon={Images.loadOutline} title={item?.displayId} item={item} /> },
  {
    rowText: (item) =>
      <div>
        <TextRow name={item?.customer?.name} textWidth={8} />
        <div className={'table-second-item'}>
          <TextRow name={item?.customerReference ? item?.customerReference : ''} textWidth={8}
                   color={'#9C9EA9'} />
        </div>
      </div>,
  },
  {
    rowText: (item) =>
      <div>
        <TextRow name={item?.carrier?.name ? item?.carrier?.name : 'N/A'} textWidth={8} />
        <div className={'table-second-item'}>
          <TextRow name={item?.carrierReference ? item?.carrierReference : ''} textWidth={8}
                   color={'#9C9EA9'} />
        </div>
      </div>,
  },
  {
    rowText: (item) => {
      const pick = item?.stops?.find((i) => i.type === 'PICKUP');

      return pick ? <div>
          <TextRow
            name={`${pick?.address?.city ? pick?.address?.city : ''} ${pick?.address?.state ? pick?.address?.state : ''}`}
            textWidth={8} />
          <div className={'table-second-item'}>
            <DateRow date={pick?.date} color={'#9C9EA9'} />
          </div>
        </div>
        :
        '...';
    },
  },
  {
    rowText: (item) => {
      const pick = item?.stops?.find((i) => i.type === 'DROPOFF');

      return pick ? <div>
          <TextRow
            name={`${pick?.address?.city ? pick?.address?.city : ''} ${pick?.address?.state ? pick?.address?.state : ''}`}
            textWidth={8} />
          <div className={'table-second-item'}>
            <DateRow date={pick?.date} color={'#9C9EA9'} />
          </div>
        </div>
        :
        '...';
    },
  },
  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
  { rowText: (item) => <PriceRow info={item?.carrierRate} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate - item?.carrierRate - item?.agentCharges} /> },
  { rowText: (item) => statusRow(item) },
];

export const ACTION_TYPE ='GET_GROUP_LOADS'

export const groupStatusEnums = {
  CANCELLED: 'CANCELLED',
}

export const GROUP_TXNS_ACTION_TYPE = 'GET_GROUP_LOADS_TXNS'


export const groupTransactionHead = [
  { name: '', title: 'Load Id', icon: 'date', custom: false },
  { name: '', title: 'Created Date', icon: 'date', custom: false, width: '100px' },
  { name: '', title: 'Type', custom: false },
  { name: '', title: 'Rate', custom: false },
  { name: '', title: 'Qty', custom: false, width: '100px' },
  { name: '', title: 'Total', custom: false },
  { name: '', title: 'Description', custom: false },
  { name: '', title: 'Entity', custom: false },
];

export const groupTransactionBody =  [
  { rowText: (item) => <TextRow name={item?.load?.displayIdString} /> },
  { rowText: (item) => <DateRow date={item?.createdDate} /> },
  { rowText: (item) => <div>{handleGetTypes(item)}</div> },
  { rowText: (item) => <PriceRow info={item?.amount}  />},
  { rowText: (item) => <TextRow name={item?.qty ? item?.qty : 1} textWidth={10} /> },
  { rowText: (item) => <PriceRow info={item?.totalAmount}/>, },
  { rowText: (item) => <TextRow name={item?.description}/>, },
  { rowText: (item) => <TypeRow text={item?.onModel === vendorEnums?.CARRIER ? 'Vendor' : item?.onModel} />, },
];
