import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const loadsStyle = makeStyles(() => ({
    filtersWrapper: {
        right: 0,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    loadIdWrapper: {
        display: 'flex',
        alignItems: 'center',
        zIndex: '99',
        '& p': {
            marginLeft: '4px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,
            whiteSpace: 'noWrap',
        },
    },
    breadAndTime: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    timing: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextSecondary,
        margin: '5px 0 30px 0',
    },
    customClass: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px 40px 0 40px',
    },
    inputAndButton: {
        display: 'flex',
        width: '100%',
    },
    inputStyle: {
        width: '246px',
        height: '36px',
        background: '#FFFFFF',
        border: '1px solid #9C9EA9',
        borderRadius: '4px',
        padding: '0 16px',
    },
    exportButton: {
        width: '100%',
        height: '48px',
        background: '#00C851',
        boxShadow: '0px 0px 6px rgba(0, 200, 81, 0.3)',
        borderRadius: '8px',
        border: 'none',
        color: 'white',
        fontSize: '16px',
        fontWeight: '600',
    },
    cancelLoadWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: '24px',
    },
    cancelLoad: {
        width: '206px',
        height: '48px',
        background: '#D72525',
        borderRadius: '8px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        border: 'none',
    },
    exportCsvDate: {
        width: '100%',
        height: '48px',
        background: '#FFFFFF',
        border: '1px solid #9C9EA9',
        borderRadius: '4px',
        padding: '0 8px',
    },
    inputWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',

        '& p': {
            fontSize: '16px',
            fontWeight: '600',
            color: Colors.TextSecondary,
            marginBottom: '4px',
        },
    },

    customClassWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: '6px',
    },

    subTitle: {
        margin: '16px 0',
        color: '#51566D',
        fontSize: '16px',
        lineHeight: '24px',
    },

    rejectOrSuccessWrapper: {
        width: '100%',
        borderRadius: '4px',
        background: '#F7F9FC',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        marginBottom:'24px',

        '& img': {
            marginRight: '15px',
            width:'24px',
            height:'24px',
        },

        '& p': {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
        },
    }
}));
