import React from 'react';
import { TextRow } from 'components';
import { Images } from 'utils';


export const loadCustomerInfoHead = [
  { name: '', title: 'Order Number', custom: false, },
  { name: '', title: 'Pallet Count',  custom: false, },
  { name: '', title: 'Qty Per Pallet',  custom: false, },
  { name: '', title: 'Case Qty', custom: false, },
  { name: '', title: 'Weight', custom: false, },
  { name: '', title: 'Dimensions',  custom: false, },
  { name: '', title: 'Commodity', custom: false, },
  { name: '', title: 'Class', custom: false, },
  { name: '', title: 'AWB/ABL Number',  custom: false, },
  { name: '', title: 'Memo',  custom: false, },
  { name: '', title: '  ',  custom: false, },
];

export const loadCustomerInfoBody = [
  { rowText: (item) => <TextRow name={item?.orderNumber} /> },
  { rowText: (item) => <TextRow name={item?.palletCount} /> },
  { rowText: (item) => <TextRow name={item?.qtyPerPallet} /> },
  { rowText: (item) => <TextRow name={item?.caseQty} /> },
  { rowText: (item) => <TextRow name={item?.weight} /> },
  { rowText: (item) => <TextRow name={item?.dimensions} /> },
  { rowText: (item) => <TextRow name={item?.commodity} /> },
  { rowText: (item) => <TextRow name={item?.classInfo} /> },
  { rowText: (item) => <TextRow name={item?.awbAblNumber} /> },
  { rowText: (item) => <TextRow name={item?.memo} /> },
  {
    button: (item) =>
      <div className="align-center" style={{gap: 8}}>
        <button
          onClick={() => item('edit')}
          className="reset-btn">
          <img src={Images.edit} alt="edit" />
        </button>
        <button
          onClick={() => item('delete')}
          className="reset-btn">
          <img src={Images.clear} alt="edit" />
        </button>
      </div>, notClickable: true,
  },
];

export const DELETE_TYPE = 'DELETE_CUSTOMER_ORDER';