import React, { useEffect, useState } from 'react';
import { CheckEdit, Colors, useGlobalStyles, useGlobalText } from 'utils';
import {
  AddCircle,
  Circle,
  CreateChancel,
  Line, NoYet,
  SimpleModal,
} from 'components';
import { createLoadStyle } from './styles';
import { CANCELLED } from '../../../../pages/accounting/constants';
import { StopType } from 'utils/types';
import { Items } from '../../../loads/createLoad/core/common/items';
import { DropPickModal } from '../../../loads/createLoad/core/common/dropPickModal';

export const FactoredDestination = ({ info, id }) => {
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalInfo, setModalInfo] = useState(null);

  useEffect(() => {
    if (info?.stops) {
      info?.stops.sort(custom_sort);
    }
  }, [info]);
  const handleOpenClose = (type) => {
    setModalType(type);
    setOpen(!open);
    setModalInfo(null);
  };

  const handleEditOpen = (info, type) => {
    setOpen(true);
    setModalType(type);
    setModalInfo(info);
  };

  function custom_sort(a, b) {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  }

  return (
    <div>
      <div className={classes.titleAndSwitcher}>
        <div className={globalStyles.centerItem}>
          <Circle number={3} back={Colors.ThemeAqua} />
          <p className={globalText.title}>Destination</p>
        </div>
      </div>
      <div className={globalStyles.flexAndFullWidth}>
        <div className={'MuiAccordionDetails-root'}>
          <Line height={'450px'} />
        </div>
        <div style={{ width: '100%' }}>
          <div className={classes.destinationWrapper}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>
                <p className={classes.cardtTitle}>Pickups</p>
                {info?.status !== CANCELLED &&
                  <AddCircle
                    handleCLic={() => handleOpenClose('pickup')}
                    text={'Add Pickup'}
                  />
                }
              </div>
              <div className={classes.cardBody}>
                {info && info?.stops && info?.stops ? info.stops.map((i, k) => (
                    i.type === 'PICKUP' &&
                    <React.Fragment key={k}>
                      <Items
                        info={info}
                        item={i}
                        type={'pickup'}
                        handleEditOpen={handleEditOpen}
                      />
                    </React.Fragment>
                  )) :
                  <NoYet position={'center'} text={'No Pickup Yet'} />
                }
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardHeader}>
                <p className={classes.cardtTitle}>Dropoffs</p>
                {info?.status !== CANCELLED &&
                  <AddCircle
                    handleCLic={() => handleOpenClose('dropoff')}
                    text={'Add Dropoff'}
                  />
                }
              </div>
              <div className={classes.cardBody}>
                {info && info?.stops && info?.stops ? info.stops.map((i, k) => (
                    i.type === StopType.DROPOFF &&
                    <React.Fragment key={k}>
                      <Items
                        info={info}
                        item={i}
                        type={'dropoff'}
                        handleEditOpen={handleEditOpen}
                      />
                    </React.Fragment>
                  )) :
                  <NoYet position={'center'} text={'No Dropoff Yet'} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={open}
        content={
          <DropPickModal
            id={id}
            modalInfo={modalInfo}
            type={modalType}
            handleClose={handleOpenClose}
          />
        }
      />
    </div>
  );
};