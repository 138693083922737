import React from "react";
import {Link, useLocation} from "react-router-dom";
import {ListItem, ListItemText} from "@material-ui/core";
import {navBarStyles} from "./style";

export const ListItemRow = ({list, open, setAccounting}) => {
    const classes = navBarStyles();
    const location = useLocation()
    const renderActiveClass = (item) => {
        if (location?.pathname === item?.path || location?.pathname === item?.create || location?.pathname?.slice(0, 9) === item?.path?.slice(0, 9)) {
            return classes.imageBackActive
        } else {
            return classes.imageBackPassive
        }
    }

    const renderMenuActiveClass = (item) => {
        if (location?.pathname === item?.path || location?.pathname === item?.create || location?.pathname?.slice(0, 6) === item?.path?.slice(0, 6)) {
            return classes.menuActiveItemsStyle
        } else {
            return classes.menuItemsStyle
        }
    }

    return (
        <div>
            {list?.map((item, i) => item && (
                <Link
                    onClick={setAccounting}
                    key={i}
                    to={item.path}
                >
                    <ListItem className={classes.ListItem} button>
                        {
                            <div style={{marginLeft:'-3px'}} className={renderActiveClass(item)}>
                                <img
                                    src={location?.pathname === item.path ? item.activeIcon : item.icon}
                                    alt={'icon'}
                                />
                            </div>
                        }
                        {open && (
                            <ListItemText
                                className={renderMenuActiveClass(item)}
                                primary={ item.name}
                            />
                        )}
                    </ListItem>
                </Link>
            ))}
        </div>
    )
}