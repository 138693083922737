import React from 'react';
import { Colors, Images, PermissionsList, RolePermission, userType } from 'utils';
import { IconAndTitle, MinLoader, PhoneRow, TextRow } from 'components';

export const vendorEnums = {
  CARRIER: 'CARRIER',
  CO_BROKER: 'CO_BROKER',
  WAREHOUSE: 'WAREHOUSE',
  CUSTOM_BROKER: 'CUSTOM_BROKER',
  FREIGHT_FORWARDER: 'FREIGHT_FORWARDER',
  INTERMODAL_FREIGHT_FORWARDER: 'INT_FREIGHT',
  FACTORED: 'FACTOR_CARRIER',
  VENDOR: 'VENDOR',
  INHOUSE_PROFILE: 'CONNECTING',
};

export const VendorTables = (type) => {

  switch (type) {
    case vendorEnums.CARRIER:
      return {
        title: 'Carrier',
        type: vendorEnums.CARRIER,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: 'docketNumber', title: 'Authority Number', custom: false },
          { name: 'name', title: 'Carrier Name' },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
          { name: '', title: 'App Invitation', custom: false },
          { name: '', title: 'RMIS', custom: false },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
            { button: (item, info) => renderAppInvitation(item, info, loaders), notClickable: true },
            { button: (item, info) => renderRmis(item, info, loaders), notClickable: true },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatuses,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };


    case vendorEnums.CO_BROKER:
      return {
        title: 'Co-Broker',
        type: vendorEnums.CO_BROKER,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: 'docketNumber', title: 'Authority Number', custom: false },
          { name: 'name', title: 'Co-Broker' },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };


    case vendorEnums.WAREHOUSE:
      return {
        title: 'Warehouse',
        type: vendorEnums.WAREHOUSE,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: 'docketNumber', title: 'Authority Number', custom: false },
          { name: '', title: 'Warehouse', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };

    case vendorEnums.CUSTOM_BROKER:
      return {
        title: 'Custom Broker',
        type: vendorEnums.CUSTOM_BROKER,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: '', title: 'CBP Licence Number', custom: false },
          { name: '', title: 'Custom Broker', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };

    case vendorEnums.FREIGHT_FORWARDER:
      return {
        title: 'Freight Forwarder',
        type: vendorEnums.FREIGHT_FORWARDER,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: '', title: 'FF Number', custom: false },
          { name: '', title: 'Freight Forwarder', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };


    case vendorEnums.INTERMODAL_FREIGHT_FORWARDER:
      return {
        title: 'Int. Freight',
        type: vendorEnums.INTERMODAL_FREIGHT_FORWARDER,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: 'docketNumber', title: 'Authority Number', custom: false },
          { name: '', title: 'Int. Freight', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
        },
      };

    case vendorEnums.FACTORED:
      return {
        title: 'Factored Carrier',
        type: vendorEnums.FACTORED,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: 'docketNumber', title: 'Authority Number', custom: false },
          { name: '', title: 'Factored Carrier', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };


    case vendorEnums.VENDOR:
      return {
        title: 'Vendor',
        type: vendorEnums.VENDOR,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: '', title: 'Vendor Name', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };

    case vendorEnums.INHOUSE_PROFILE:
      return {
        title: 'Inhouse Profile',
        type: vendorEnums.INHOUSE_PROFILE,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: '', title: 'Inhouse Profile', custom: false },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatusesNoPending,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
        },
      };

    default:
      return {
        title: 'Carrier',
        type: vendorEnums.CARRIER,
        head: [
          { name: 'companyName', title: 'Company Name', searchKey: 'companyName' },
          { name: 'docketNumber', title: 'Authority Number', custom: false },
          { name: 'name', title: 'Carrier Name' },
          { name: 'phone', title: 'Phone Number', custom: false, searchKey: 'phone' },
          RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
          { name: '', title: 'App Invitation', custom: false },
          { name: '', title: 'RMIS', custom: false },
          { name: '', title: 'Status', custom: false },
        ],
        body: (loaders) => {
          return [
            { rowText: (item) => <IconAndTitle icon={Images.carrier} title={item?.companyName} textWidth={10} /> },
            { rowText: (item) => <TextRow name={item?.docketNumber} textWidth={10} /> },
            { rowText: (item) => <TextRow name={`${item?.firstName} ${item?.lastName}`} textWidth={10} /> },
            { rowText: (item) => <PhoneRow phone={item?.phone} /> },
            RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
            { button: (item, info) => renderAppInvitation(item, info, loaders), notClickable: true },
            { button: (item, info) => renderRmis(item, info, loaders), notClickable: true },
            { button: (item, info) => renderStatus(item, info, loaders), notClickable: true },
          ];
        },
        vendorCsv: {
          type: 'carrier',
          statusList: carrierStatuses,
          requiredStatus: true,
          statusEnum: 'statuses',
          statusesEnum: statusesEnum,
          w9: true,
        },
      };
  }
};

export const ACTION_TYPE = 'GET_CARRIERS';
const renderRmis = (data, info, loaders) => {
  if (info?.rmisStatus === 'INACTIVE') {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Inactive</p>
        {userType === 'ADMIN' &&
          <button
            onClick={() => data('rmisInvite')}
          >
            {loaders?.loadRmis?.length && loaders?.id === info?.id ?
              <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
              :
              'Reinvite'
            }
          </button>
        }
      </div>
    );
  } else if (info?.rmisStatus === 'ACTIVE') {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Active</p>
      </div>
    );
  }
};

const renderAppInvitation = (data, info, loaders) => {
  if (appStatus?.INACTIVE === info?.appStatus) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Inactive</p>
        <button
          onClick={() => data('appReInvitation')}
        >
          {loaders?.reInviteLoader?.length && info?.id === loaders?.id ?
            <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
            :
            'ReInvite'
          }
        </button>
      </div>
    );
  } else if (appStatus?.ACTIVE === info?.appStatus) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Active</p>
      </div>
    );
  } else {
    return '';
  }
};


const renderStatus = (data, info, loaders) => {
  if (info?.status === statusesEnum?.ACTIVE) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Active</p>
      </div>
    );
  } else if (info?.status === statusesEnum?.INACTIVE) {
    return (
      <div className={'reinviteRmisWrapper'}>
        <p>Inactive</p>
      </div>
    );
  } else if (info?.status === statusesEnum?.PENDING) {
    return (
      <div className="acceptDenyWrapper">
        {loaders?.acceptDenyLoader?.length && info?.id === loaders?.id ?
          <MinLoader style={{ margin: 0, position: 'relative' }} color={Colors.ThemeBlue} />
          :
          <>
            <button onClick={() => data('accept')}>
              <img src={Images.approveIcon} alt="icons" />
            </button>
            <button onClick={() => data('deny')}>
              <img src={Images.clear} alt="icons" />
            </button>
          </>
        }
      </div>
    );
  } else {
    return '';
  }
};

export const appStatus = {
  PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
};
export const carrierStatuses = [
  // { id: 'All', name: 'All' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'PENDING', name: 'Pending' },
];
export const carrierStatusesNoPending = [
  // { id: 'All', name: 'All' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
];
export const carrierStatusesEnum = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'PENDING', name: 'Pending' },
];
export const statusesEnum = {
  'ACTIVE': 'ACTIVE',
  'INACTIVE': 'INACTIVE',
  'PENDING': 'PENDING',
};

