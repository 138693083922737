import React from 'react';
import { Colors, useGlobalStyles, useGlobalText } from 'utils';
import { Circle, Line, PriceRow } from 'components';
import { createLoadStyle } from '../../createLoad/core';

export const GroupTotals = ({ groupById }) => {
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();

  const list = [
    { title: 'Total Customer Rate', value: groupById?.totalCustomerRate || 0, background: 'rgba(67, 138, 254, 0.10)' },
    { title: 'Total Vendor Rate', value: groupById?.totalVendorRate || 0, background: 'rgba(0, 200, 81, 0.10)' },
    { title: 'Agent Charges', value: groupById?.totalAgentCharges || 0, background: 'rgba(236, 199, 17, 0.15)' },
    { title: 'Total Agent Profit', value: groupById?.totalAgentProfit || 0, background: 'rgba(63, 81, 181, 0.10)' },
  ];

  return (
    <div>
      <div className={classes.titleAndSwitcher}>
        <div className={globalStyles.centerItem}>
          <Circle number={1} back={Colors.ThemeAqua} />
          <p className={globalText.title}>
            Totals
          </p>
        </div>
      </div>
      <div className={globalStyles.flexAndFullWidth}>
        <div className={'MuiAccordionDetails-root'}>
          <Line height={'100px'} />
        </div>
        <div className={classes.totalWrapper}>
          {list.map((item, index) => (
            <div key={index} className={classes.documentItem} style={{ background: item.background }}>
              <p className={classes.documentTitle}>{item.title}</p>
              <PriceRow info={item.value} styles={{ color: '#2A374E', fontWeight: 600, fontSize: 16 }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};