import {
    GET_CUSTOMER_AVAILABLE_DEBT_SUCCESS,
    GET_CUSTOMER_BY_ID_SUCCESS,
    GET_CUSTOMER_DEBT_SUCCESS,
    GET_CUSTOMER_DENY_SUCCESS, GET_CUSTOMER_LOADS_SUCCESS,
    GET_CUSTOMERS_SUCCESS,
} from './customers.types';

const initialState = {
    customersList: [],
    customerById: null,
    denyInfo: [],
    debtInfo: null,
    availableCredit: null,
    customerLoads: []
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Carriers */

        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customersList: action.payload,
            }

        case GET_CUSTOMER_BY_ID_SUCCESS:
            return {
                ...state,
                customerById: action.payload,
            }

        /** End */

        /** AutoDeny  */

        case GET_CUSTOMER_DENY_SUCCESS:
            return {
                ...state,
                denyInfo: action.payload
            }

        case GET_CUSTOMER_DEBT_SUCCESS:
            return {
                ...state,
                debtInfo: action.payload
            }

        case GET_CUSTOMER_AVAILABLE_DEBT_SUCCESS:
            return {
                ...state,
                availableCredit: action.payload
            }

        /** End */


        case GET_CUSTOMER_LOADS_SUCCESS:
            return {
                ...state,
                customerLoads: action.payload
            }

        default:
            return state;
    }
};
