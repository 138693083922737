import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddModalButton, FullTable, SimpleModal, ValidationInput } from 'components';
import { customerActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { customerLoadsHead, customerLoadsBody, CUSTOMER_LOAD_ACTION_TYPE } from './constants';
import { FindErrorItem, FindLoad, FindSuccessItem, Images, useGlobalStyles } from 'utils';
import { getCustomerFactoredLoads, getCustomerLoads } from '../../store/customers/customers.action';

const ACTION_TYPE = 'VERIFY_CUSTOMER';
export const CustomerLoads = ({}) => {
  const { customerLoads } = useSelector((state) => ({
    customerLoads: state.customers.customerLoads,
  }));
  const globalStyles = useGlobalStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const [verified, setVerified] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(null);
  const loader = FindLoad(ACTION_TYPE);
  const success = FindSuccessItem(ACTION_TYPE);
  const backError = FindErrorItem(ACTION_TYPE);
  const params = useParams()

  useEffect(() => {
    const localVerified = localStorage.getItem('verifiedCustomer') ;
    if (localVerified === 'verified') {
      setVerified(true);
    } else {
      setVerified(false);
    }
  }, []);

  const getList = () => {
    const filteredInfo = {
      ...info,
      customer: params?.id,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    dispatch(customerActions.getCustomerFactoredLoads(filteredInfo));
  };

  useEffect(() => {
    if (verified) {
      getList();
    }
  }, [info, verified]);

  useEffect(() => {
    if (success) {
      localStorage.setItem('verifiedCustomer', 'verified');
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      setVerified(true);
    }
  }, [success]);

  const handleVerify = () => {
    if (password) {
      dispatch(customerActions.verifyCustomer(params?.id, password));
    } else {
      setError('password');
    }
  };

  return (
    <div>
      <SimpleModal
        openDefault={!verified}
        content={
          <div className="check-customer-modal">
            <div className="check-customer-modal-header">
              <img src={Images.verification} alt="verification" />
            </div>
            <p className="modal-title">Verify With Password</p>
            <ValidationInput
              className={info && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'password'}
              label={'Write your password*'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
                if(backError?.error) dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
              }}
              typeError={
                error === 'password' ? 'Password is required' :
                  backError?.error ? backError.error : null
              }
            />
            <AddModalButton
              styles={{ marginTop: 12 }}
              loading={!!loader?.length}
              handleClick={handleVerify}
              text={'Verify'}
            />
          </div>
        }
      />

      {verified &&
        <div className="align-justify-center" style={{ height: '100vh', padding: '0 24px' }}>
          <FullTable
            head={customerLoadsHead}
            body={customerLoadsBody}
            loadingType={CUSTOMER_LOAD_ACTION_TYPE}
            list={customerLoads?.loads}
            listCount={customerLoads?.count}
            noText={'Customers'}
          />
        </div>
      }
    </div>
  );
};
