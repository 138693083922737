import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { carrierActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, mcActions } from 'store';
import {
  createInputsWrapper,
  EmailValidator,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccessItem,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import { CreateChancel, ValidationInput, SelectInput, SelectTypeAutocomplete } from 'components';
import { vendorEnums } from 'pages';
import { createFlowWithoutDocket, createPageTitle, createVendorRequiredFields, typeEnums } from './constants';
import { RenderDetails } from '../../carrierInfo/core/constants';

const CREATE_TYPE = 'CREATE_CARRIER';

export const CreateCarriersInput = ({ handleChangeName }) => {
  const { mcsList } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
  }));
  const globalText = useGlobalText();
  const globalStyles = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState({});
  const [email, setEmail] = useState('');
  const actionType = CREATE_TYPE;
  const loader = FindLoad(CREATE_TYPE);
  const success = FindSuccessItem(CREATE_TYPE);
  const backError = FindErrorItem(actionType);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;
  const info = history?.location?.state;
  const pageInformation = createPageTitle(info?.vendorType);
  const carrierInfo = RenderDetails(info?.vendorType)?.Information;
  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  useEffect(() => {
    dispatch(mcActions.getMcs());
    setError('');
    removeError();
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_TYPE));
      navigateByParams();
    }
  }, [success]);

  const navigateByParams = () => {
    history.push({
      pathname: `/vendors`,
      state: { vendorType: info?.vendorType },
    });
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError(''),
    e.target.name === 'companyName' && handleChangeName(e.target.value),
      removeError();
  };

  const handleCreate = () => {
    const factoredCarrier = info?.vendorType === vendorEnums.FACTORED;
    const data = {
      'firstName': inputs.firstName,
      'lastName': inputs.lastName,
      'email': inputs.email,
      'companyName': inputs.companyName,
      'docketType': inputs.docketType,
      'docketNumber': inputs.docketNumber,
      'type': info?.vendorType,
      'isFactored': factoredCarrier,
    };

    // if (factored && inputs.loadMC) {
    if (factoredCarrier) {
      data.loadMC = inputs.loadMC;
    } else {
      delete data.loadMC;
    }

    // const checkFactored = factored ? !!inputs.loadMC : true;
    // if (inputs.firstName && inputs.lastName && inputs.email && !email && inputs.companyName && inputs.docketType && inputs.docketNumber && checkFactored) {

    const checkFactored = factoredCarrier ? !!inputs.loadMC : true;

    if (createVendorRequiredFields(inputs, info?.vendorType) && !email) {
      dispatch(carrierActions.createCarrier(data));
    } else {
      const checkDocket = carrierInfo?.companyTypeList && !inputs.docketType ? 'docketType' : null;
      setError(
        !inputs.firstName ? 'firstName' :
          !inputs.lastName ? 'lastName' :
            !inputs.email ? 'email' :
              !inputs.companyName ? 'companyName' :
                checkDocket ? 'docketType' :
                  !inputs.docketNumber ? 'docketNumber' :
                    // !inputs.docketType ? 'docketType' :
                    !checkFactored ? 'loadMC' :
                      '',
      );
    }
  };

  return (
    <div className={globalScreens.createInputsWrapper}>
      <div className={globalStyles.flexAble}>
        <div className={globalStyles.fullWidth}>
          <p className={globalStyles.totalTitle} style={{ marginRight: 0 }}>{pageInformation?.pageTitle}</p>
          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Basic Information</p>
            <div className="carrier-inputs-wrapper">
              <div className="input-wrapper">
                <ValidationInput
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'firstName'}
                  type={'text'}
                  label={'First Name*'}
                  typeError={error === 'firstName' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs.firstName}
                />
              </div>
              <div className="input-wrapper">
                <ValidationInput
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'lastName'}
                  type={'text'}
                  label={'Last Name*'}
                  typeError={error === 'lastName' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs.lastName}
                />
              </div>
              <div className="input-wrapper">
                <ValidationInput
                  validator={EmailValidator}
                  variant={'outlined'}
                  name={'email'}
                  type={'email'}
                  label={'Email Address*'}
                  typeError={
                    error === 'email' ? ErrorText.field :
                      email === 'Not valid email' ? 'Not valid email' :
                        errorText === 'Carrier already exist' ? 'Carrier already exist' :
                          errorText === 'Carrier already exists' ? 'Carrier already exists' :
                            errorText === 'User already exists.' ? 'User already exists' :
                              errorText === 'User already exists' ? 'User already exists' :
                                ''}
                  sendBoolean={(bool) => bool === true ? setEmail('Not valid email') : setEmail('')}
                  onChange={handleChange}
                  value={inputs.email}
                />
              </div>

              <div className="input-wrapper">
                <ValidationInput
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'companyName'}
                  type={'text'}
                  label={'Company Name*'}
                  typeError={error === 'companyName' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs.companyName}
                />
              </div>

              {!createFlowWithoutDocket(info?.vendorType) &&
                <>
                  {carrierInfo?.companyTypeList &&
                    <div className="input-wrapper">
                      <SelectInput
                        style={globalStyles.simpleInput}
                        name={'docketType'}
                        label={'Type*'}
                        handleSelect={handleChange}
                        value={inputs.docketType}
                        list={carrierInfo?.companyTypeList}
                        typeError={error === 'docketType' ? ErrorText.field : ''}
                        type={'id'}
                      />
                    </div>
                  }
                  {carrierInfo?.authorityNumberName &&
                    <div className="input-wrapper">
                      <ValidationInput
                        variant={'outlined'}
                        name={'docketNumber'}
                        label={carrierInfo?.authorityNumberName}
                        type={'text'}
                        typeError={
                          error === 'docketNumber' ? ErrorText.field :
                            errorText === 'A Carrier with this Authority Number already exists.' ? 'A Carrier with this Authority Number already exists.' :
                              ''
                        }
                        onChange={handleChange}
                        value={inputs.docketNumber}
                        Length={20}
                      />
                    </div>
                  }
                  <div className="input-wrapper"></div>
                </>
              }
            </div>

            {info?.vendorType === vendorEnums.FACTORED &&
              <div className="input-wrapper" style={{ height: '60px', width: '32%' }}>
                <SelectTypeAutocomplete
                  style={globalStyles.simpleInput}
                  name={'loadMC'}
                  label={'name'}
                  type={'mcNumber'}
                  title={'Load MC Number*'}
                  handleSelect={handleChange}
                  defaultValue={inputs?.loadMC}
                  list={mcsList?.mcs?.filter((i) => i?.authorityType === 'CARRIER')}
                  error={error}
                  typeError={error === 'loadMC' ? ErrorText.field : ''}
                  right={'0'}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <p>{error === 'Required field' ? error : ''}</p>
      <CreateChancel
        loader={!!loader.length}
        classes={globalStyles.buttonsStyle}
        create={'Create'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={navigateByParams}
      />
    </div>
  );
};
