import { Colors, Images, PermissionsList, RolePermission, useModal } from 'utils';
import React from 'react';
import { DateRow, IconAndTitle, MinLoader, PriceRow, TextRow, TypeRow } from 'components';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { NOT_BILLABLE } from '../../fragments/billing/billingDetails/core/constants';
import { CustomerInformation } from 'fragments';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const billingsHead = [
  { name: 'loadId', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false, },
  {
    name: 'createdDate',
    title: 'Processed Date',
    icon: 'date',
    smallSize: 9,
    bigSize: 11,
    type: 'date',
    width: '130px',
  },
  { name: 'customer', searchKey: 'CUSTOMER', title: 'Customer', custom: false },
  { name: 'customerReference', title: 'Ref.', custom: false },
  { name: '', nameRow: 'balance', title: 'Balance', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'mc', nameRow: 'mc', title: 'Mc', custom: false },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code]) &&
  { name: '', title: 'Action ', custom: false },
];

export const billingsBody = [
  { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
  { rowText: (item) => <DateRow date={item?.bill?.processedDate} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.customerReference} textWidth={12} /> },
  { rowText: (item) => <PriceRow info={item?.bill?.totalRate - item?.bill?.totalPaid} /> },
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.mc?.name} textWidth={12} /> },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code]) &&
  {
    button: (item) => <button className={'submit-button'} onClick={() => item('submit')}>Submit</button>,
    notClickable: true,
  },
];

export const ACTION_TYPE = 'GET_LOADS_BY_STATUS';
export const BILLABLE = 'BILLABLE';
export const RECEIVABLE = 'RECEIVABLE';
export const PAYABLE = 'PAYABLE';
export const VERIFICATION = 'PMT_VERIFICATION';
export const LOAD_VERIFICATION = 'LOAD_VERIFICATION';
export const PROCESSING = 'PROCESSING';
export const SEND_TO_BILLING = 'SEND_TO_BILLING';
export const NOT_PAYABLE = 'NOTPAYABLE';
export const NOTBILLABLE = 'NOTBILLABLE';
export const LOAD_REVISION = 'LOAD_REVISION';
export const CANCELLED = 'CANCELLED';
export const PAID = 'PAID';
export const IN_TRANSIT = 'INTRANSIT';
export const REJECTED = 'REJECTED';
export const DENIED = 'DENIED';
export const COMPLETED = 'COMPLETED';
export const PENDING = 'PENDING';
export const CREDITCHECK = 'CREDITCHECK';
export const CONSIGNMENT = 'CONSIGNMENT';
export const CONSIGNMENT_REQUEST = 'CONSIGNMENT_REQUEST';
export const ON_CLAIM = 'ON_CLAIM';
// Receivables

export const receivablesHead = [
  { name: 'loadId', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false, },
  { name: 'bill', nameRow: 'lastInvoiceDate', title: 'Billed Date', icon: 'date', type: 'date' },
  { name: 'customerReference', searchKey: 'CUSTOMER_REF', title: 'Ref.', custom: false },
  { name: 'customer', searchKey: 'CUSTOMER', title: 'Customer', custom: false },
  { name: 'mc', searchKey: 'MC', title: 'Authority', custom: false },
  { name: 'bill', searchKey: 'BILL_RATE', title: 'Total Billed', custom: false },
  { name: 'paid', searchKey: 'BILL_PAID', title: 'Paid Loads', custom: false, smallSize: 4 },
  {
    name: 'agingBucket', title: 'Aging',
    filterList: [
      { name: 'All', id: '' },
      { name: '0+', id: 'CURRENT' },
      { name: '30+', id: 'DUE_NOW' },
      { name: '60+', id: 'PAST_DUE' },
      { name: '90+', id: 'URGENT' },
    ],
  },
  { name: '', nameRow: 'dueDate', title: 'Due Date', custom: false, type: 'date' },
  { name: 'balance', searchKey: 'BILL_BALANCE', title: 'Balance', custom: false },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_RECEIVABLES?.code, PermissionsList.VERIFY_RECEIVABLES?.code]) &&
  { name: '', title: 'Verified', custom: false, width: '100px' },
];

const renderDate = (item) =>{
  const result = new Date(item?.bill?.lastInvoiceDate);
  result.setDate(result.getDate() + item?.bill?.term);
  return result;
}
export const receivablesBody = (open) => {
  return [
    { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
    { rowText: (item) => <DateRow date={item?.bill?.lastInvoiceDate} /> },
    { rowText: (item) => <TextRow name={item?.customerReference} textWidth={10} /> },
    {
      rowText: (item) => <div className="modal-hyper-link"
                              onClick={() => open(<CustomerInformation id={item?.customer?.id} />)}>
        <TextRow name={item?.customer?.name} textWidth={7} />
      </div>, notClickable: true,
    },
    { rowText: (item) => <TextRow name={item?.mc?.name} textWidth={7} /> },
    { rowText: (item) => <PriceRow info={item?.bill?.totalRate} /> },
    { rowText: (item) => <PriceRow info={item?.bill?.totalPaid} /> },
    { rowText: (item) => <div>{handleGetDate(item?.bill?.lastInvoiceDate)}</div>, type: 'returnColor' },
    { rowText: (item) => <DateRow date={renderDate(item)} /> },
    { rowText: (item) => <PriceRow info={item?.bill?.totalRate - item?.bill?.totalPaid + (item?.bill?.corporateCharges ? item?.bill?.corporateCharges : 0)} /> },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_RECEIVABLES?.code, PermissionsList.VERIFY_RECEIVABLES?.code]) &&
    {
      button: (item, info, load) => <div className="align-justify-center">{handleReturnCheck(item, info, load)}</div>,
      notClickable: true,
    },
  ];
};

const handleReturnCheck = (item, info, load) => {
  const date = handleGetDate(info?.bill?.lastInvoiceDate);
  let disabled = RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.VERIFY_RECEIVABLES?.code]) ? date > 7 : true;

  return load === true ?
    <MinLoader style={{ margin: 0 }} color={Colors.ThemeBlue} />
    :
    <Checkbox
      disabled={disabled}
      icon={icon}
      checkedIcon={checkedIcon}
      style={{ color: disabled ? 'lightgray' : '#438AFE', padding: 0 }}
      onClick={() => !disabled && item('verify', info.id)}
      checked={info?.bill?.verified}
    />;
};

export const handleGetDate = (info) => {
  const date1 = new Date(info);
  const date2 = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / oneDay);
};

const handleReturnColor = (date, termDay) => {
  const days = handleGetDate(date);

  const finalDate = Math.ceil(days / termDay);
  if (finalDate <= 1) {
    return '#00C8511A';
  } else if (finalDate > 1 && finalDate <= 2) {
    return '#438AFE1A';
  } else if (finalDate > 2 && finalDate <= 3) {
    return '#FFA3301A';
  } else {
    return '#D725251A';
  }
};


export const processingHead = [
  { name: 'loadUd', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false },
  { name: 'processedDate', title: 'Process. Date', icon: 'date', type: 'date', width: '130px' },
  { name: 'customer', searchKey: 'CUSTOMER', title: 'Customer', custom: false },
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_TO_BILLING?.code]) &&
  {
    name: '', title: 'Billing',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Sent to Billing', id: 'billing' },
      { name: 'Not Sent to Billing', id: 'notBilling' },
      { name: 'Corrections Needed', id: 'corrections' },
    ],
  },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_TO_PAYABLE?.code]) &&
  {
    name: '', title: 'Payable',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Sent to Payables', id: 'payables' },
      { name: 'Corrections Needed', id: 'needed' },
      { name: 'Not Sent to Payables', id: 'notPayables' },
      { name: 'Awaiting Verification', id: 'awaiting' },
    ],
  },
];

export const processingBody = [
  { rowText: (item) => <div>{item?.displayId}</div> },
  { rowText: (item) => <DateRow date={item?.processedDate} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_TO_BILLING?.code]) &&
  { button: (item, info, load) => renderBilling(item, info, load), notClickable: true },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_TO_PAYABLE?.code]) &&
  { button: (item, info, load) => renderPayable(item, info, load), notClickable: true },
];


const renderPayable = (data, info, load) => {
  // if (info?.payable?.finalAmount <= 0) {
  //     return <p style={{color: Colors.ThemeRed}}>Balance is $0 or less</p>
  // } else {

  if (info?.payable?.status === 'PAID') {
    return <div className="sendTo">
      <img src={Images.greenCheckmark} alt="icon" />
      <p className="needed">Fully Paid</p>
    </div>;
  } else if (info?.payable?.notes && info?.payable?.status === NOT_PAYABLE) {
    return <p className="needed">Corrections Needed</p>;
  } else if (info.loadVerification === true) {
    return <p className="awaiting">Awaiting Verification</p>;
  } else if (info?.payable?.status !== NOT_PAYABLE) {
    return <div className="sendTo">
      <img src={Images.greenCheckmark} alt="icon" />
      <p className="needed">Sent to Payables</p>
    </div>;
  } else {
    return <button className="blueButton" onClick={() => data('sendToPayables')}>
      {load ?
        <MinLoader style={{ margin: 0 }} position={'relative'} color={Colors.TextWhite} />
        :
        'Send to Payable'
      }
    </button>;
  }
  // }
};

const renderBilling = (data, info, load) => {
  if (info?.status === 'PAID') {
    return <div className="sendTo">
      <img src={Images.greenCheckmark} alt="icon" />
      <p className="needed">Fully Paid</p>
    </div>;
  } else {
    if (info?.bill?.notes) {
      return <p className="needed">Corrections Needed</p>;
    } else if (info.bill?.status === NOT_BILLABLE || !info.bill?.status) {
      return <button
        onClick={() => data('sendToBilling')}
        className="blueButton">
        {load === true ?
          <MinLoader style={{ margin: 0 }} color={Colors.TextWhite} />
          :
          'Send to Billing'
        }
      </button>;
    } else {
      return <div className="sendTo">
        <img src={Images.greenCheckmark} alt="icon" />
        <p className="needed">Sent to Billing</p>
      </div>;
    }
  }
};


export const PaymentHead = [
  { name: 'loadId', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false },
  { name: 'carrier', searchKey: 'CARRIER', title: 'Vendor', icon: 'date', custom: false },
  { name: 'payable', nameRow: 'payeeName', title: 'Payee', custom: false },
  {
    name: '', title: 'Method', custom: false,
    // filterList: [
    //     {name: 'All', id: ''},
    //     {name: 'Check', id: 'CHECK'},
    //     {name: 'Ach', id: 'ACH'},
    //     {name: 'Efs', id: 'EFS'},
    // ]
  },
  {
    name: '', title: 'Term', custom: false,
    // filterList: [
    //     {name: 'All', id: ''},
    //     {name: 'Custom Quick Pay', id: 'CUSTOM_QUICKPAY'},
    //     {name: '30 Day', id: 'THIRTYDAY'},
    //     {name: 'Quick Pay', id: 'QUICKPAY'},
    // ]
  },
  { name: '', title: 'Amount', custom: false },
  { name: '', title: 'Charges', smallSize: 6, custom: false },
  { name: '', title: 'Paid Amount', custom: false, smallSize: 6 },
  { name: '', title: 'Final Amount', custom: false, smallSize: 6 },
  { name: '', title: 'Actions', custom: false },
];

export const PaymentVBody = [
  { rowText: (item) => <div>{item?.displayId}</div> },
  { rowText: (item) => <TextRow name={item?.carrier?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.payable?.payeeName} textWidth={12} /> },
  { rowText: (item) => <TypeRow text={item?.payable?.pmtMethod ? item?.payable?.pmtMethod : ''} /> },
  { rowText: (item) => <TypeRow text={item?.payable?.pmtTerm ? item?.payable?.pmtTerm : ''} /> },
  { rowText: (item) => <PriceRow info={item?.carrierRate} /> },
  { rowText: (item) => <PriceRow info={item?.carrierCharges} /> },
  { rowText: (item) => <PriceRow info={item?.carrierPaid} /> },
  { rowText: (item) => <PriceRow info={item?.carrierRate - item?.carrierCharges - item?.carrierPaid} /> },
  { button: (item, info, load) => renderPaymentVerification(item, info, load), notClickable: true },
];

const renderPaymentVerification = (data, info, load) => {

  if (info?.doNotPay === true) {
    return <div className="do-not-pay-text"><p>Do not pay is ON</p></div>;
  } else {
    if (info?.payable?.finalAmount <= 0) {
      return <p style={{ color: Colors.ThemeRed }}>Carrier is negative</p>;
    } else {
      return <div style={{ display: 'flex' }}>
        {info?.carrier?.debt?.length > 0 && info?.carrier?.outstandingDebt > 0 ?
          <button
            style={{ width: '70px', marginRight: '16px' }}
            onClick={() => data('COLLECT')}
            className="blue-button"
          >
            Collect
          </button>
          : ''}
        <button
          style={{ width: '80px', background: Colors.ThemeGreen }}
          onClick={() => data('APPROVE')}
          className="blue-button"
        >
          {load ?
            <MinLoader position={'relative'} style={{ margin: 0 }} color={'white'} />
            :
            'Approve'
          }
        </button>
      </div>;
    }
  }
};

export const LoadVerificationHead = [
  { name: 'loadId', searchKey: 'LOAD_DISPLAY_ID', title: 'Load ID', custom: false },
  { name: 'createdDate', title: 'Created Date', icon: 'date', type: 'date', width: '130px' },
  { name: 'customer', searchKey: 'CUSTOMER', title: 'Customer', custom: false },
  { name: 'carrier', searchKey: 'CARRIER', title: 'Vendor', custom: false },
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'instructions', title: 'Note', custom: false },
];

export const LoadVerificationBody = [
  { rowText: (item) => <TextRow name={item?.displayId} textWidth={12} /> },
  { rowText: (item) => <DateRow date={item?.createdDate} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.carrier?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.instructions} textWidth={12} /> },
];

export const receivablesCsv = {
  type: 'receivables',
  statusEnum: 'department',
};