import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors, useGlobalStyles, useGlobalText } from 'utils';
import { Circle, DocumentsComponent, Line } from 'components';
import { httpRequestsOnLoadActions, httpRequestsOnSuccessActions, loadActions, LoadServices, } from 'store';
import { createLoadStyle } from '../../createLoad/core';

const REQUEST_TYPE = 'ADD_GROUP_DOC';
const DELETE_REQUEST_TYPE = 'DELETE_GROUP_DOC';
export const GroupDocuments = ({ info, id }) => {
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();
  const dispatch = useDispatch();
  const [docs, setDocks] = useState([]);

  useEffect(() => {
    if (info?.documents) {
      setDocks(info.documents);
    }
  }, [info]);
  const handleFilter = e => {
    if (e.target.value === 'All DocumentsSection') {
      setDocks(info.documents);
    } else {
      const newList = info && info.documents.filter((i) => i.type === e.target.value);
      setDocks(newList);
    }
  };
  const handleUpload = async (loadDoc) => {
    try {
      const currentFile = { ...loadDoc };
      currentFile.file.originalName = currentFile?.name;
      await LoadServices.UploadGroupDocument(id, currentFile);
      dispatch(loadActions.getGroupById(id, 'noLoad'));
      dispatch(httpRequestsOnLoadActions.removeLoading(REQUEST_TYPE));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(REQUEST_TYPE));
    }
  };
  const handleDelete = async (loadDoc) => {
    dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_REQUEST_TYPE))
    dispatch(httpRequestsOnLoadActions.appendLoading(DELETE_REQUEST_TYPE))
    try {
      const currentFile = { ...loadDoc };
      await LoadServices.DeleteGroupDocument(id, currentFile);
      dispatch(httpRequestsOnSuccessActions.appendSuccess(DELETE_REQUEST_TYPE))
      dispatch(loadActions.getGroupById(id, 'noLoad'));
      dispatch(httpRequestsOnLoadActions.removeLoading(REQUEST_TYPE));
    } catch (e) {
      dispatch(httpRequestsOnLoadActions.removeLoading(REQUEST_TYPE));
    }finally {
      dispatch(httpRequestsOnLoadActions.removeLoading(DELETE_REQUEST_TYPE))
    }
  }

  return (
    <div>
      <div className={classes.titleAndSwitcher}>
        <div className={globalStyles.centerItem}>
          <Circle number={4} back={Colors.ThemeAqua} />
          <p className={globalText.title}>
            Documents
          </p>
        </div>
      </div>
      <div className={globalStyles.flexAndFullWidth}>
        <div className={'MuiAccordionDetails-root'}>
          <Line height={'330px'} />
        </div>
        <div className={classes.documentSection}>
          <DocumentsComponent
            removeActionType={DELETE_REQUEST_TYPE}
            actionType={REQUEST_TYPE}
            handleFilter={handleFilter}
            handleUpload={handleUpload}
            handleDeleteFile={handleDelete}
            // fileTypeList={docTypes}
            // docTypes={[{ name: 'All Documents', id: 'All DocumentsSection' }, ...docTypes]}
            documents={docs}
          />
        </div>
      </div>
    </div>
  );
};