import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { loadActions } from 'store';
import { useParams } from 'react-router-dom';
import { FullTable } from 'components';
import { GROUP_TXNS_ACTION_TYPE, groupTransactionBody, groupTransactionHead } from './constants';

export const GroupTransactions = ({}) => {
  const { groupLoadTxns } = useSelector((state) => ({
    groupLoadTxns: state.loads.groupLoadTxns,
  }));
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(loadActions.getGroupLoadsTxns(params?.id));
  }, []);

  return (
    <div>
      <FullTable
        head={groupTransactionHead}
        body={groupTransactionBody}
        loadingType={GROUP_TXNS_ACTION_TYPE}
        list={groupLoadTxns}
        noText={'Transactions'}
      />
    </div>
  );
};