import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { customerActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import {
  createInputsWrapper,
  EmailValidator,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccess,
  PermissionsList,
  RolePermission,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import {
  CreateChancel,
  Line,
  ValidationInput,
  EditSaveButtons,
  SelectInput, MaskInput, MaskNoChar, FormatAddress,
} from 'components';

const typeList = [
  { name: 'Broker', id: 'BROKER' },
  { name: 'Shipper', id: 'SHIPPER' },
  { name: 'Freight Forwarder', id: 'FORWARDER' },
  { name: 'Medical Transportation', id: 'MEDICAL_TRANSPORTATION' },
  { name: 'Waste Management', id: 'WASTE_MANAGEMENT' },
];

export const CreateCustomerInputs = ({ handleChangeName, info }) => {
  const globalStyle = useGlobalText();
  const globalWrapper = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const dispatch = useDispatch();
  const history = useHistory();
  const [disables, setDisabled] = useState(!!info);
  const [fullAddress, setFullAddress] = useState(info?.address ? info?.address : null);
  const [fullSecondAddress, setFullSecondAddress] = useState(info?.secondAddress ? info?.secondAddress : null);
  const [error, setError] = useState('');
  const [mailError, setMailError] = useState('');
  const [billing, setBilling] = useState('');
  const [inputs, setInputs] = useState(info ? { ...info } : {});
  const actionType = info ? 'EDIT_CUSTOMER' : 'CREATE_CUSTOMER';
  const loader = FindLoad('CREATE_CUSTOMER');
  const success = FindSuccess('EDIT_CUSTOMER');
  const backError = FindErrorItem(actionType);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;

  useEffect(() => {
    return () => {
      removeError();
    };
  }, []);

  useEffect(() => {
    if (success?.length) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      setDisabled(true);
    }
  }, [success]);

  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  const handleCheck = (bool) => {
    if (bool === true) {
      setMailError('Not valid email');
    } else {
      setMailError('');
    }
  };
  const handleCheckBilling = (bool) => {
    if (bool === true) {
      setBilling('Not valid email');
    } else {
      setBilling('');
    }
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
    e.target.name === 'name' && handleChangeName(e.target.value);
    removeError();
  };

  const handleFullAddress = (ev) => {
    setFullAddress(ev);
    removeError();
    if (error === 'address') setError('');
  };

  const handleFullSecondAddress = (ev) => {

    setFullSecondAddress(ev);
    removeError();
    if (error === 'secondAddress') setError('');
  };


  const handleCreate = () => {
    const phoneNumber = inputs?.phone && parseInt(inputs?.phone.replace(/[^0-9]/g, '')) + '';
    const addressIsValid = fullAddress?.street && fullAddress?.city && fullAddress?.country;

    const data = {
      'name': inputs.name,
      'email': inputs.email,
      'phone': phoneNumber,
      'billingEmail': inputs.billingEmail,
      'address': fullAddress,
      'secondAddress': fullSecondAddress,
      'type': inputs.type,
      'mc': inputs?.mc,
      'dot': inputs?.dot,
      'onboardedBy': inputs?.onboardedBy ? inputs?.onboardedBy : null,
      'pmtTerm': parseInt(inputs.pmtTerm),
    };

    const customerId = info ? info.id : '';

    if (!mailError.length && !billing.length) {
      if (inputs.name && inputs.type && inputs?.mc && inputs?.dot && inputs.phone && inputs.email && inputs.billingEmail && inputs.pmtTerm && addressIsValid) {
        if (info) {
          dispatch(customerActions.editCustomer(customerId, data));
        } else {
          dispatch(customerActions.createCustomer(data));
        }
      } else {
        setError(
          !inputs.name ? 'name' :
            !inputs.type ? 'type' :
              !inputs?.mc ? 'mc' :
                !inputs?.dot ? 'dot' :
                  !inputs.phone ? 'phone' :
                    !inputs.email ? 'email' :
                      !inputs.billingEmail ? 'billingEmail' :
                        !inputs.pmtTerm ? 'pmtTerm' :
                          !addressIsValid ? 'address' : '',
        );
      }
    }
  };

  const handleChancel = () => {
    setDisabled(true);
    setInputs({ ...info });
    setFullAddress(info?.address);
    setFullSecondAddress(info?.secondAddress ? info?.secondAddress : null);
    setError('');
    removeError();
  };

  return (
    <div className={globalScreens.createInputsWrapper}>
      <div className={globalWrapper.spaceBetween}>
        <div className={globalWrapper.centerItem}>
          {!info && <p className={globalStyle.title}>Customer Information</p>}
        </div>
        {info && RolePermission([PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
          <EditSaveButtons
            margin={true}
            type={'EDIT_CUSTOMER'}
            handleChancel={handleChancel}
            handleSetEdit={() => setDisabled(false)}
            handleSaveInfo={handleCreate}
          />}
      </div>

      <div className={globalWrapper.flexAble}>
        {info && <Line height={'550px'} />}
        <div className={globalWrapper.fullWidth}>
          <div className={globalScreens.basicInfo}>
            <p className={globalStyle.smallText}>Basic Information</p>
            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                className={info && globalWrapper.inputTextFieldBlue}
                style={globalWrapper.simpleInput}
                variant={'outlined'}
                name={'name'}
                type={'text'}
                label={'Name*'}
                typeError={
                  error === 'name' ? ErrorText.field :
                    errorText === 'Customer with this name exits' ? 'Customer with this name exits' :
                      ''}
                onChange={handleChange}
                value={inputs.name}
                disabled={disables}
              />
              <SelectInput
                className={info && globalWrapper.inputTextFieldBlue}
                style={globalWrapper.simpleInput}
                disabled={disables}
                name={'type'}
                label={'Type*'}
                handleSelect={handleChange}
                value={inputs.type}
                list={typeList}
                typeError={error === 'type' ? ErrorText.field : ''}
                type={'id'}
              />
              <MaskNoChar
                mask={'999999999999999'}
                name="mc"
                label="Authority Number*"
                value={inputs?.mc}
                handleChange={handleChange}
                info={info?.mc}
                error={error === 'mc' ? ErrorText.field : ''}
                disables={disables}
              />
              <MaskNoChar
                styles={{ marginRight: 0 }}
                mask={'999999999999999'}
                name="dot"
                label="DOT Number*"
                value={inputs?.dot}
                handleChange={handleChange}
                info={info?.dot}
                error={error === 'dot' ? ErrorText.field : ''}
                disables={disables}
              />
            </div>

            <div className={globalScreens.basicInfoInputs}>
              <MaskInput
                name="phone"
                label="Phone Number*"
                value={inputs.phone}
                disables={disables}
                handleChange={handleChange}
                info={info}
                error={
                  error === 'phone' ? ErrorText.field :
                    errorText === 'phone must be a valid phone number' && ErrorText.phone}
              />
              <ValidationInput
                className={info && globalWrapper.inputTextFieldBlue}
                style={globalWrapper.simpleInput}
                validator={EmailValidator}
                variant={'outlined'}
                name={'email'}
                type={'email'}
                label={'Email Address*'}
                typeError={error === 'email' ? ErrorText.field : mailError === 'Not valid email' ? 'Not valid email' : ''}
                sendBoolean={handleCheck}
                onChange={handleChange}
                value={inputs.email}
                disabled={disables}
              />
              <ValidationInput
                className={info && globalWrapper.inputTextFieldBlue}
                style={globalWrapper.simpleInput}
                validator={EmailValidator}
                variant={'outlined'}
                name={'billingEmail'}
                type={'email'}
                label={'Billing Email*'}
                typeError={error === 'billingEmail' ? ErrorText.field : billing === 'Not valid email' ? 'Not valid email' : ''}
                sendBoolean={handleCheckBilling}
                onChange={handleChange}
                value={inputs.billingEmail}
                disabled={disables}
              />
              <MaskNoChar

                mask={'999999999999999'}
                name="pmtTerm"
                label="Pmt Term*"
                value={inputs?.pmtTerm}
                handleChange={handleChange}
                info={info?.pmtTerm}
                error={error === 'pmtTerm' ? ErrorText.field : ''}
                disables={disables}
              />
              <ValidationInput
                styles={{ marginRight: 0 }}
                className={info && globalWrapper.inputTextFieldBlue}
                style={globalWrapper.simpleInputNoRight}
                variant={'outlined'}
                name={'onboardedBy'}
                type={'text'}
                label={'Onboarded by'}
                onChange={handleChange}
                value={inputs?.onboardedBy}
                disabled={disables}
              />

            </div>
          </div>

          <div className={globalScreens.basicInfo}>
            <p className={globalStyle.smallText}>Address*</p>
            <div className={globalScreens.basicInfoInputs}>
              <FormatAddress
                noBlue={!info?.address}
                setCurrentAddress={handleFullAddress}
                selectedAddress={info?.address}
                errorBoolean={error === 'address'}
                disabled={disables}
              />
            </div>
          </div>
          <div className={globalScreens.basicInfo}>
            <p className={globalStyle.smallText}>Second Address</p>
            <div className={globalScreens.basicInfoInputs}>
              <FormatAddress
                noBlue={!info?.secondAddress}
                setCurrentAddress={handleFullSecondAddress}
                selectedAddress={info?.secondAddress}
                errorBoolean={error === 'secondAddress'}
                disabled={disables}
                notRequired={true}
                placeholder={'Physical Address'}
              />
            </div>
          </div>
        </div>
      </div>

      {!info &&
        <CreateChancel
          loader={!!loader.length}
          classes={globalWrapper.buttonsStyle}
          create={'Create'}
          chancel={'Cancel'}
          onCreate={handleCreate}
          onClose={() => history.push('/customers')}
        />
      }
    </div>
  );
};