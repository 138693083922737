import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, FullTable } from 'components';
import { DELETE_TYPE, loadCustomerInfoBody, loadCustomerInfoHead } from './constants';
import { useGlobalText, useModal } from 'utils';
import { CreateCustomerOrder } from './createCustomerOrder';
import { loadActions } from 'store';
import { CustomDeleteModal } from '../../modals';

export const LoadCustomerInfo = ({ fromTab }) => {
  const { loadById } = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const dispatch = useDispatch();
  const globalText = useGlobalText();
  const { open, close } = useModal();

  const handleCLick = (item, name) => {
    if (name === 'edit') {
      open(<CreateCustomerOrder info={item} />);
    }
    if (name === 'delete') {
      open(
        <CustomDeleteModal
          actionType={'DELETE_CUSTOMER_ORDER'}
          text="Delete Customer Order?"
          info={'Customer Order'}
          handleDel={() => dispatch(loadActions.deleteCustomerOrder(loadById?.id, item?.id))}
          afterSuccess={close}
        />,
      );
    }
  };

  return (
    <div>
      <div className={fromTab ? 'flex-end' : "space-between"}>
        {!fromTab &&
          <p className={globalText.title}>Customer Order Info</p>
        }
        <AddButton
          handleClick={() => open(<CreateCustomerOrder />)}
          text={'Add Customer Order'}
        />
      </div>
      <FullTable
        height={'medium'}
        head={loadCustomerInfoHead}
        body={loadCustomerInfoBody}
        list={loadById?.customerOrdersInfo || []}
        noText={'Other Transaction'}
        handleClickButton={handleCLick}
      />
    </div>
  );
};