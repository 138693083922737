import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const createLoadStyle = makeStyles(() => ({

  pickUp: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  pickUpRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pickupDropWrapper: {
    height: '182px',
    boxShadow: '0px 0px 6px #8A8A8A29',
    borderRadius: '8px',
    width: '100%',
    padding: '24px',
  },
  pickupWrapper: {
    display: 'flex',
    marginTop: '24px',
  },
  titles: {
    fontSize: '16px',
    fontWeight: '600',
    color: Colors.TextSecondary,
    marginTop: '8px',
  },
  titleAndSwitcher: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  documentSection: {
    width: '100%',
    marginTop: '24px',
  },
  editSaveButtons: {
    marginLeft: '24px',
  },
  destinationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '16px',
    '@media (min-width: 1920px)': {
      marginTop: '24px',
    },

  },
  carrierRate: {
    width: '100%',
    marginTop: '16px',
    '@media (min-width: 1920px)': {
      marginTop: '24px',
    },
  },
  card: {
    width: '49%',
    background: '#F7F9FC 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    height: '408px',
    padding: '16px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardBody: {
    marginTop: '24px',
    overflow: 'auto',
    height: '328px',
  },
  cardtTitle: {
    fontWeight: 600,
    fontSize: '18px',
    color: Colors.TextSecondary,
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: '16px',
    width: '100%',
    '@media (min-width: 1919px)': {
      marginLeft: '16px',
    },
    '& p': {
      marginRight: '20px',
      marginTop: '-20px',
      fontWeight: 'bold',
    },
  },
  inputsWrapper: {
    display: 'flex',
    width: '50%',
    marginRight: '20px',
  },
  halfScreen: {
    display: 'flex',
    width: '50%',
  },


  inputTextFieldRed: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeRed,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeRed,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeRed}!important`,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: Colors.ThemeRed,
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeRed,
    },

    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-root': {
      height: '193px',
      minHeight: '193px',
    },
  },

  inputTextFieldBlue: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '193px',
      minHeight: '193px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },


  inputDescBlue: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '266px',
      minHeight: '266px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },

  inputDesc: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiOutlinedInput-root': {
      height: '266px',
      minHeight: '266px',
    },
  },

  inputProcessingTextFieldBlue: {
    width: '100%',
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },

    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '60px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-root': {
      height: '70px',
      minHeight: '70px',
    },
  },

  inputDescription: {
    height: '96px',
    marginTop: '16px',
    width: '100%',
    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '96px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      height: '96px',
      minHeight: '96px',
    },
  },

  inputDescriptionBlue: {
    height: '96px',
    marginTop: '16px',
    width: '100%',
    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: '96px',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      height: '96px',
      minHeight: '96px',
    },
  },

  inputTextField: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiOutlinedInput-root': {
      height: '193px',
      minHeight: '193px',
    },
  },
  inputProcessingTextField: {
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiOutlinedInput-root': {
      height: '70px',
      minHeight: '70px',
    },
  },

  maxCharacters: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& p': {
      fontSize: '12px',
      color: Colors.TextLight,
      marginTop: '-20px',
      marginRight: '20px',
    },
  },

  basicInfoInputsWrapper: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    marginRight: '16px',
    '@media (min-width: 1280px)': {
      marginRight: '16px',
    },
    '@media (min-width: 1920px)': {
      marginRight: '40px',
    },
  },

  margins: {
    width: '100%',
    marginRight: '16px',
    '@media (min-width: 1280px)': {
      marginRight: '16px',
    },
    '@media (min-width: 1920px)': {
      marginRight: '40px',
    },
  },

  uploadedCard: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #8A8A8A29',
    borderRadius: '8px',
    padding: '24px',
    height: '284px',
    width: '50%',
    '@media (min-width: 1280px)': {
      marginRight: '16px',
    },
    '@media (min-width: 1920px)': {
      marginRight: '40px',
    },
  },
  uploadedCardHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  filterInput: {
    width: '160px',
    height: '36px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '36px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },
  },

  noItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    '& p': {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '50px',
      color: Colors.TextLight,
    },
  },

  awaitCarrier: {
    width: '153px',
    height: '36px',
    color: Colors.TextWhite,
    textTransform: 'capitalize',
    background: Colors.ThemeTurquoise,
    borderRadius: '18px',
    boxShadow: '0px 0px 6px #00BCD44D',
    fontSize: '14px',
    padding: 0,
    '& :hover': {
      background: '#03a5ba',
      width: '153px',
      height: '36px',
      color: Colors.TextWhite,
      borderRadius: '18px',
    },
  },

  greyButton: {
    textTransform: 'capitalize',
    width: '238px',
    height: '48px',
    background: Colors.ThemeGray,
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '600',
    color: Colors.TextPrimary,
  },
  uploadedDocumentsWrapper: {
    height: '200px',
    overflow: 'auto',
  },
  uploadedDocWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '15px',
    marginTop: '15px',
    alignItems: 'center',
    borderBottom: '1px solid black',
  },
  loader: {
    paddingBottom: '18px',
    marginTop: '25px',
    borderBottom: '1px solid black',
    width: '100%',
  },
  downloadButton: {
    '& a': {
      width: '50px',
    },
    width: '50px',
    background: 'none',
    border: 'none',
    outline: 'none',
  },

  download: {
    textDecoration: 'underline',
    '& img': {
      marginRight: '8px',
    },
  },

  flexAble: {
    width: '191px',
    display: 'flex',
    '& img': {
      marginRight: '8px',
    },
  },


  flex: {
    display: 'flex',
    alignItems: 'center',
  },


  sendButton: {
    width: '186px',
    height: '36px',
    background: '#00BCD4 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00BCD44D',
    borderRadius: '18px',
    color: 'white',
    fontSize: '14px',
    border: 'none',
  },

  visibleIcon: {
    cursor: 'pointer',
    marginRight: '10px',
    marginTop: '5px',
  },

  flexEnd: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '28px',
  },

  processingInputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '48px',
    marginTop: '16px',
  },

  statusAndButton: {
    marginTop: '28px',
  },

  weightFr: {
    width: '100%',
    display: 'flex',
    marginRight: '16px',
  },

  sendToRevision: {
    border: 'none',
    background: 'transparent',
    width: '140px',
    outline: 'none',
    color: Colors.ThemeBlue,
    textDecoration: 'underline',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',

    display: 'flex',
    justifyContent: 'center',
  },

  revisionNeeded: {
    width: '100%',
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 12px',
    background: 'rgba(255, 163, 48, 0.1)',
    borderRadius: '4px',
    marginBottom: '24px',

    '& img': {
      marginRight: '16px',
    },
  },
  canceledLoad: {
    background: 'rgba(215, 37, 37, 0.1)',
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 12px',
    borderRadius: '4px',
    marginBottom: '24px',

    '& img': {
      marginRight: '16px',
      height: '25px',
      width: '25px',
    },
  },
  notePadding: {
    marginBottom: '24px',
  },
  rejectedLoad: {
    background: 'rgba(215, 37, 37, 0.1)',
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 12px',
    borderRadius: '4px',
    marginBottom: '24px',

    '& p': {
      fontWeight: 600,
      fontSize: '16px',
      color: Colors.TextPrimary,
    },

    '& img': {
      marginRight: '16px',
      height: '25px',
      width: '25px',
    },
  },

  revisionNeededWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#51566D',
  },

  totalWrapper: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    width: '100%',
  },
  documentItem: {
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
    minHeight: '81px',
  },
  documentTitle: {
    color: '#51566D',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    marginBottom: 4
  },
}));
