import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {createInputsWrapper, ErrorText, FindError, useGlobalStyles,} from "utils";
import {ErrMessage, Line, SelectTypeAutocomplete, ValidationInput,} from "components";
import {createLoadStyle} from "./styles";
import { carrierActions } from 'store';

export const RequestACarrier = ({info, disables, handleChange, inputs, error, setInputs}) => {
    const {carriersList} = useSelector((state) => ({
        carriersList: state.carriers.carriersList,
    }));
    const dispatch = useDispatch()
    const globalScreens = createInputsWrapper();
    const globalStyles = useGlobalStyles();
    const classes = createLoadStyle();
    const err = FindError('NOT_CARRIER')
    const [carrierFiltered, setCarrierFiltered] = useState([]);
    const selectedVendor = info?.carrierId ? [
        {
            docketNumber: info?.carrierMC,
            companyName: info?.carrierName,
            id: info?.carrierId,
        },
    ] : [];

    useEffect(() => {
        if (carriersList?.carriers?.length) {
            const newList = carriersList?.carriers?.filter((i) => i?.doNotLoad === false && (i?.rmisRequired ? i?.rmisStatus === "ACTIVE" : true))
            setCarrierFiltered(newList)
        }
    }, [carriersList])

    const handleSearchVendor = (e) => {
        if (e.target.value.length > 3) {
            setTimeout(() => {
                dispatch(carrierActions.getCarriers({
                    statuses: ['ACTIVE'],
                    searchKey: 'companyName',
                    searchValue: e.target.value,
                }));
            }, 500);
        }
    };

    return (
        <div className='full-width-flex-able'>
            <div className={'MuiAccordionDetails-root'}>
                <Line height={'250px'}/>
            </div>
                <div className='load-row-wrapper' style={{marginTop:'12px'}}>
                    <div className={globalScreens.basicInfoInputs} style={{width:'100%'}}>
                        <div className={classes.basicInfoInputsWrapper}>
                            <SelectTypeAutocomplete
                              loadType={'GET_CARRIERS'}
                              style={globalStyles.simpleInput}
                              name={'carrier'}
                              label={'companyName'}
                              type={'docketNumber'}
                              title={'Vendor/MC*'}
                              handleSelect={handleChange}
                              handleType={handleSearchVendor}
                              defaultValue={inputs.carrier}
                              list={
                                  info && !carrierFiltered?.length ? selectedVendor :
                                    carrierFiltered
                              }
                              disabled={disables}
                              error={error}
                            />
                            {/*{carrierFiltered?.length ?*/}
                            {/*    <SelectTypeAutocomplete*/}
                            {/*        loadType={'GET_CARRIERS'}*/}
                            {/*        style={globalStyles.simpleInput}*/}
                            {/*        name={'carrier'}*/}
                            {/*        label={'companyName'}*/}
                            {/*        type={'docketNumber'}*/}
                            {/*        title={'Vendor/MC*'}*/}
                            {/*        handleSelect={handleChange}*/}
                            {/*        defaultValue={inputs.carrier}*/}
                            {/*        list={carrierFiltered}*/}
                            {/*        disabled={disables}*/}
                            {/*        error={error}*/}
                            {/*    />*/}
                            {/*    :*/}
                            {/*    <SelectTypeAutocomplete*/}
                            {/*        style={globalStyles.simpleInput}*/}
                            {/*        title={'Vendor/MC*'}*/}
                            {/*        list={[]}*/}
                            {/*        disabled={disables}*/}
                            {/*        error={error}*/}
                            {/*    />*/}
                            {/*}*/}
                            <div className={classes.carrierRate}>
                                <ValidationInput
                                    style={globalStyles.simpleInput}
                                    className={inputs.carrierRate && globalStyles.inputTextFieldBlue}
                                    variant={"outlined"}
                                    name={"carrierRate"}
                                    label={"Rate*"}
                                    type={'number'}
                                    typeError={error === 'carrierRate' && ErrorText.field}
                                    onChange={handleChange}
                                    value={inputs.carrierRate}
                                    disabled={disables}
                                />
                            </div>

                            <ValidationInput
                                style={globalStyles.simpleInput}
                                className={inputs.carrierReference && globalStyles.inputTextFieldBlue}
                                variant={"outlined"}
                                name={"carrierReference"}
                                label={"Vendor Reference"}
                                type={'text'}
                                typeError={error === 'carrierReference' && ErrorText.field}
                                onChange={handleChange}
                                value={inputs?.carrierReference}
                                disabled={disables}
                            />

                        </div>
                        <div style={{width: '50%'}}>
                            <ValidationInput
                                style={globalStyles.simpleInput}
                                className={inputs.carrierInstruction ? classes.inputTextFieldBlue : classes.inputTextField}
                                variant={"outlined"}
                                name={"carrierInstruction"}
                                label={"Specific Instruction"}
                                type={'text'}
                                multiline={true}
                                Length={200}
                                typeError={error === 'carrierInstruction' && ErrorText.field}
                                onChange={handleChange}
                                value={inputs.carrierInstruction}
                                disabled={disables}
                            />
                            <div className={classes.maxCharacters}>
                                <p>Max 200 characters</p>
                            </div>
                        </div>
                    </div>
                    {err && err.length && error === 'noLoadSub' ?
                        <ErrMessage type={"Pass"} text={'Please add carrier'}/> :
                        ''
                    }
            </div>
        </div>
    )
}