import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateWrapper } from 'components';
import { Images, useGlobalStyles } from 'utils';
import { CreateCarriersInput } from './core';
import { createPageTitle } from './core/constants';

export const CreateCarrier = ({}) => {
  const globalStyle = useGlobalStyles();
  const [name, setName] = useState('');
  const history = useHistory();
  const info = history?.location?.state;
  const pageInformation = createPageTitle(info?.vendorType);

  return (
    <div>
      <CreateWrapper
        head={
          <div className={globalStyle.createOfficeTableHead}>
            <img src={Images.carrierBold} alt={'Carrier'} />
            <p>{name ? name : pageInformation?.title}</p>
          </div>
        }
        body={
          <CreateCarriersInput
            handleChangeName={setName}
          />
        }
        parentLink={'/carriers'}
        parent={'Carriers'}
        child={'Add Carrier'}
      />
    </div>
  );
};
