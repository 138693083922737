import React from 'react';
import {
  Colors,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import { Circle, Line } from 'components';
import { createLoadStyle } from './styles';
import { LoadCustomerInfo } from '../../../loads/loadCustomerInfo';

export const CustomerInfo = ({ info, id }) => {
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();

  return (
    <div>
      <div className={classes.titleAndSwitcher}>
        <div className={globalStyles.centerItem}>
          <Circle number={2} back={Colors.ThemeAqua} />
          <p className={globalText.title}>
            Customer Order Info
          </p>
        </div>
      </div>
      <div className={globalStyles.flexAndFullWidth} style={{ marginBottom: 30 }}>
        <div className={'MuiAccordionDetails-root'}>
          <Line height={'auto'} />
        </div>
        <div className={classes.documentSection}>
          <LoadCustomerInfo fromTab={true}/>
        </div>
      </div>
    </div>
  );
};