import React, { useState } from 'react';
import { CheckboxesTags, MinLoader, modalsStyle } from 'components';
import { useGlobalTextStyles, useModal } from 'utils';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { token } from '../../../../utils/constants';

export const ExportInvoice = ({}) => {
  const { groupById, groupPayableLoads } = useSelector((state) => ({
    groupById: state.loads.groupById,
    groupPayableLoads: state.loads.groupPayableLoads,
  }));
  const classes = modalsStyle();
  const globalText = useGlobalTextStyles();
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const { close } = useModal();
  const [loads, setLoads] = useState([]);

  const handleExport = async () => {
    if (loads?.length) {
      setLoader(true);

      try {
        const date = {};

        if (loads?.length) {
          let newList = [];
          loads?.forEach((i) => {
            newList.push(i?.id);
          });
          date.loadIds = newList;
        } else {
          delete date.loadIds;
        }

        axios({
          url: `/loads/loadgroup/${groupById?.id}/invoices`,
          method: 'POST',
          responseType: 'blob',
          headers: { 'access-token': `${token}` },
          data: {
            ...date,
          },
        }).then((response) => {
          setLoader(false);
          const url = window.URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `group-invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          close();
        }).catch(() => {
          setLoader(false);
        });

      } catch (err) {
        if (err?.data?.message === 'Load was not found') {
          setError('Load was not found');
        }
        setLoader(false);
      }
    } else {
      setError('loads');
    }
  };

  const handleSelectStatuses = (e) => {
    setLoads(e);
  };

  return (
    <div className={classes.loadCsvModal}>
      <div>
        <p className={globalText.modalTitle}>{'Export Load Invoice'}</p>
        <p className={globalText.modalText}>
          All data within the selected loads.
        </p>
        <div>
          <div style={{ marginBottom: '20px' }}>
            <CheckboxesTags
              type={'mc'}
              handleChange={handleSelectStatuses}
              permissionsList={groupPayableLoads}
              value={loads}
              label={'Select Loads*'}
              typeError={error === 'loads'}
              renderName={(option) => option?.displayId}

            />
          </div>
        </div>
        <div className={classes.customClassWrapper}>
          <button onClick={handleExport} className={classes.exportButton}>
            {loader ?
              <MinLoader position={'relative'} color={'white'} />
              :
              'Export Invoice'
            }
          </button>
        </div>
      </div>
    </div>
  );
};