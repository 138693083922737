import React from 'react';
import { useHistory } from 'react-router-dom';
import { isFactored } from 'utils';
import { loadTabs } from './constants';
import { Groups, LoadCard } from './fragments';
import { FactoredLoads } from '../factored';

export const Loads = ({}) => {
  const history = useHistory();
  const info = history?.location?.state;

  return (
    <div>
      {isFactored ?
        <FactoredLoads />
        :
        info?.tabType === loadTabs?.LOAD_GROUPS ?
          <Groups />
          :
          <LoadCard />
      }
    </div>
  );
};
