import {
    GET_CUSTOMERS,
    CREATE_CUSTOMER,
    EDIT_CUSTOMER,
    GET_CUSTOMER_BY_ID,
    ACTIVATE_CUSTOMER,
    INACTIVATE_CUSTOMER,
    ADD_CUSTOMER_BLACKLIST,
    REMOVE_CUSTOMER_BLACKLIST,
    GET_CUSTOMER_DENY,
    ACTIVATE_DENY,
    INACTIVATE_DENY,
    GET_CUSTOMER_DEBT,
    ADD_CUSTOMER_DOCK,
    DELETE_CUSTOMER_DOCK,
    CUSTOMER_NON_FACTORING,
    CONSIGN_CUSTOMER,
    CUSTOMER_NO_POD, GET_CUSTOMERS_SEARCHED, EDIT_CUSTOMER_DOCK, GET_CUSTOMER_LOADS, VERIFY_CUSTOMER,
} from './customers.types';

/** Offices Requests, here is All requests for Customer page */


/** Create, Edit Customer */

export const createCustomer = (body) => {
    return {
        type: CREATE_CUSTOMER,
        payload: {body}
    }
}

export const editCustomer = (id, body) => {
    return {
        type: EDIT_CUSTOMER,
        payload: {id, body}
    }
}

/** End */

/** Get Carriers */

export const getCustomers = ( params, load ) => {
    return {
        type: GET_CUSTOMERS,
        payload: { params, load }
    }
}

export const getCustomersSearch = ( params ) => {
    return {
        type: GET_CUSTOMERS_SEARCHED,
        payload: {params}
    }
}

export const getCustomerById = (id, load) => {
    return {
        type: GET_CUSTOMER_BY_ID,
        payload: {id, load}
    }
}

/** End */

/** Activate or Inactivate Customer */

export const activateCustomer = (id) => {
    return {
        type: ACTIVATE_CUSTOMER,
        payload: {id}
    }
}

export const inactivateCustomer = (id) => {
    return {
        type: INACTIVATE_CUSTOMER,
        payload: {id}
    }
}

export const nonFactoringCustomer = (id, nonFactoring) => {
    return {
        type: CUSTOMER_NON_FACTORING,
        payload: {id, nonFactoring}
    }
}

export const nonNoPodCustomer = (id, noPod) => {
    return {
        type: CUSTOMER_NO_POD,
        payload: {id, noPod}
    }
}

/** End */

/**Customer Mc BlackList */

export const assignBlackList = (id, mcId, customerId, message) => {
    return {
        type: ADD_CUSTOMER_BLACKLIST,
        payload: {id, mcId, customerId, message}
    }
}

export const removeBlackList = (id, mcId, customerId) => {
    return {
        type: REMOVE_CUSTOMER_BLACKLIST,
        payload: {id, mcId, customerId}
    }
}

export const getCustomerDebt = ( id, mcId ) =>{
    return{
        type:GET_CUSTOMER_DEBT,
        payload: { id, mcId }
    }
}
/** End */

/** AutoDeny  */

export const getDeny = (id, mcId) => {
    return {
        type: GET_CUSTOMER_DENY,
        payload: {id, mcId}
    }
}

export const activateDeny = (id) => {
    return {
        type: ACTIVATE_DENY,
        payload: {id}
    }
}

export const inactivateDeny = (id) => {
    return {
        type: INACTIVATE_DENY,
        payload: {id}
    }
}

/** End */


/** Customer Documents */

export const addCustomerDocument = ( body ) => {
    return{
        type:ADD_CUSTOMER_DOCK,
        payload: body
    }
}

export const deleteCustomerDocument = ( id, dockId ) => {
    return{
        type:DELETE_CUSTOMER_DOCK,
        payload: { id, dockId }
    }
}

export const editCustomerDocument = ( id, dockId, body ) => {
    return{
        type:EDIT_CUSTOMER_DOCK,
        payload: { id, dockId, body }
    }
}

/** End */

export const consignCustomer = ( id, type ) => {
    return{
        type:CONSIGN_CUSTOMER,
        payload: { id, type }
    }
}


export const getCustomerFactoredLoads = ( params ) => {
    return{
        type:GET_CUSTOMER_LOADS,
        payload: { params }
    }
}

export const verifyCustomer = ( id, password ) => {
    return{
        type:VERIFY_CUSTOMER,
        payload: { id, password }
    }
}