import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CsvFile, FullTable, TableWrapper, CustomButtonsTab } from 'components';
import { loadActions } from 'store';
import { PermissionsList, RolePermission, userType } from 'utils';
import { loadTabs, GROUP_ACTION_TYPE, groupsBody, groupsHead, groupCsv } from './constants';

export const Groups = ({}) => {
  const { groupedLoads } = useSelector((state) => ({
    groupedLoads: state.loads.groupedLoads,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    info?.status === 'ALLLOADS' && delete filteredInfo.status;
    if (info?.from) {
      filteredInfo.from = moment(info?.from).format('YYYY-MM-DDT00:00:01');
    }
    if (!info?.to) {
      filteredInfo.to = moment().format('YYYY-MM-DDT23:59:59');
    } else {
      filteredInfo.to = moment(filteredInfo.to).format('YYYY-MM-DDT23:59:59');
    }
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    delete filteredInfo.tabType;

    if (userType === 'AGENT' && !info?.statuses) {
      // filteredInfo.statuses = ['PENDING', 'PROCESSING', 'COMPLETED'];
      filteredInfo.isRevision = true;
      filteredInfo.isVerification = true;
    }
    if (info?.statuses && info?.statuses?.length === 0) {
      delete filteredInfo.isRevision;
      delete filteredInfo.isVerification;
      delete filteredInfo.statuses;
    }
    return filteredInfo;
  };

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
      dispatch(loadActions.getGroupedLoads({ ...filteredInfo }, loader));
  };

  const pushPageDetails = async (id) => {
    history.push({
      pathname: `/load-group/${id}`,
      state: { loadParams: { ...info } },
    });
  };

  return (
    <div>
      <div className="space-between" style={{ marginBottom: '16px', alignItems: 'flex-start' }}>
        <CustomButtonsTab
          first={'loads'}
          second={'Load Groups' }
          width={'150px'}
          maxWidth={'320px'}
          firstName={loadTabs?.LOADS}
          secondName={loadTabs?.LOAD_GROUPS }
        />
        <div className="align-center">
          {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_LOAD_CSV?.code]) &&
            <CsvFile params={groupCsv} />
          }
          {userType !== 'ADMIN' &&
            <div className="align-center" style={{ marginBottom: '16px' }}>
                <div className="add-load-btn">
                  <button
                    onClick={() => window.location.replace( 'create-group')}>
                    <p className="plus"> + </p>
                    <p className="add-text">Add New Group</p>
                  </button>
                </div>
            </div>
          }
        </div>
      </div>

      <TableWrapper
        firstButton={'Active'}
        secondButton={'Inactive'}
        addButton={'Add Load'}
        buttonsTab={userType !== 'ADMIN'}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <FullTable
          head={groupsHead}
          body={groupsBody}
          loadingType={GROUP_ACTION_TYPE}
          list={ groupedLoads?.loadGroups}
          listCount={groupedLoads?.count}
          handleClick={pushPageDetails}
          noText={'Groups'}
          dateFilter={true}
          typeForFilter={true}
          dateFrom={true}
          padding={'12px 10px'}
          paddingHead={'16px 10px'}
        />
      </TableWrapper>
    </div>
  );
};
