import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FindLoad, FindSuccess } from 'utils';
import { FullTable } from 'components';
import { carrierActions, httpRequestsOnSuccessActions, rmisActions } from 'store';
import { VendorTables, ACTION_TYPE, vendorEnums } from './constants';
import { VendorTypesAndFilters } from './fragments';

const INVITE = 'INVITE_CARRIER_TO_APP';
const RE_INVITE = 'RE_INVITE_CARRIER_TO_APP';
const INVITE_RMIS = 'INVITE_RMIS';
const ACCEPT_DENY = 'INACTIVATE_ACTIVATE_CARRIER';

export const Vendors = () => {
  const { carriersList } = useSelector((state) => ({
    carriersList: state.carriers.carriersList,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const loadInvite = FindLoad(INVITE);
  const loadReInvite = FindLoad(RE_INVITE);
  const reInviteSuccess = FindSuccess(RE_INVITE);
  const inviteSuccess = FindSuccess(INVITE);
  const loadRmis = FindLoad(INVITE_RMIS);
  const acceptDeny = FindLoad(ACCEPT_DENY);
  const success = FindSuccess(ACCEPT_DENY);
  const acceptDenySuccess = FindSuccess(ACCEPT_DENY);
  const [selectedId, setSelectedId] = useState('');
  const currentTab = VendorTables(info?.vendorType);

  useEffect(() => {
    getList('loading');
  }, [info]);

  useEffect(() => {
    if (reInviteSuccess?.length) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(RE_INVITE));
      getList('noLoad');
    }
    if (inviteSuccess?.length) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(INVITE));
      getList('noLoad');
    }
    if (acceptDenySuccess?.length) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACCEPT_DENY));
      getList('noLoad');
    }
  }, [reInviteSuccess, inviteSuccess, acceptDenySuccess]);

  const getList = (loading) => {
    const filteredInfo = {
      ...info,
      type: currentTab?.type ? currentTab?.type : vendorEnums?.CARRIER,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    delete filteredInfo.vendorType;
    const loader = loading === 'loading' ? 'loading' : 'noLoad';

    if (info?.statuses) {
      const filterList = info?.statuses?.filter((i) => i.id !== 'All');
      filteredInfo.statuses = filterList?.map((i) => i.id);
    } else if (!info?.vendorType || info?.vendorType === vendorEnums?.CARRIER) {
      filteredInfo.statuses = ['ACTIVE', 'PENDING'];
    }
    dispatch(carrierActions.getCarriers({ ...filteredInfo }, loader));
  };


  const handleSubmit = (item, name) => {
    setSelectedId(item.id);
    if (name === 'appInvitation') {
      dispatch(carrierActions.inviteCarrierApp(item.id));
    }
    if (name === 'appReInvitation') {
      dispatch(carrierActions.reInviteCarrierApp(item.id));
    }
    if (name === 'rmisInvite') {
      dispatch(rmisActions.inviteRMIS(item.id));
    }
    if (name === 'accept' || name === 'deny') {
      const status = name === 'accept' ? 'ACTIVE' : 'INACTIVE';
      dispatch(carrierActions.inactivateActivateCarrier(item?.id, status));
    }
  };

  const pushPageDetails = (id) => {
    history.push({
      pathname: `/vendor/${id}`,
      state: { vendorType: currentTab?.type ? currentTab?.type : vendorEnums?.CARRIER },
    });
  };

  return (
    <div>
      <VendorTypesAndFilters />
      <FullTable
        head={currentTab.head}
        body={currentTab?.body ? currentTab?.body({
          inviteLoader: loadInvite,
          reInviteLoader: loadReInvite,
          loadRmis: loadRmis,
          acceptDenyLoader: acceptDeny,
          id: selectedId,
        }) : {}}
        loadingType={ACTION_TYPE}
        list={carriersList?.carriers}
        listCount={carriersList?.count}
        handleClick={pushPageDetails}
        noText={currentTab?.title}
        handleClickButton={handleSubmit}
      />
    </div>
  );
};