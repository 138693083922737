import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CreateWrapper, Loader } from 'components';
import { FindLoad } from 'utils';
import { LoadSummary } from '../../createLoad/core/loadSummary';
import { EditGroup } from './editGroup';
import { GroupLoads } from './groupLoads';
import { GroupDocuments } from './groupDocuments';
import { GroupTotals } from './groupTotals';

const ACTION_TYPE = 'GET_GROUP_BY_ID';

export const GroupDetails = ({}) => {
  const { groupById } = useSelector((state) => ({
    groupById: state.loads.groupById,
  }));
  const params = useParams();
  const loader = FindLoad(ACTION_TYPE);

  if(loader?.length){
    return  <Loader />
  }

  return (
    <div>
      <CreateWrapper
        type={params.id ? 'load' : ''}
        noHead={true}
        body={
          <div>
            <LoadSummary loadById={groupById} grouped={true} />
            <GroupTotals groupById={groupById}/>
            <EditGroup />
            <GroupLoads/>
            <GroupDocuments   info={groupById}  id={groupById?.id}/>
          </div>
        }
      />
    </div>
  );
};