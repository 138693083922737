import {
    createLoad,
    editLoad,
    getLoadById,
    getCustomerLoads,
    searchCustomerReceivables,
    filterByAlphabeticalReceivables,
    filterByDefaultReceivables,
    getCarrierLoads,
    getLoadTransactions,
    getLoadsByStatus,
    sendToPayable,
    approvePayable,
    getLoadsByPayable,
    getReceivablesInfo,
    getPastPayable,
    getPastBill,
    reBill,
    cancelLoad,
    searchList,
    removeSearchedList,
    getCarrierLastLoads,
    getLoadCarrierTransactions,
    createLoadTemplate,
    getLoadOtherTransactions,
    getLoadTotals,
    getRecNotes,
    createRecNote,
    editRecNote,
    deleteRecNote,
    getPayableInfo,
    uploadLoadList,
    createCustomerOrder,
    editCustomerOrder,
    deleteCustomerOrder,
    getGroupedLoads,
    getGroupById,
    getGroupLoads,
    cancelGroup, getGroupPayableLoads, getGroupLoadsTxns,
} from './loads.action';
import axios from "axios";

export {loadReducer} from './loads.reducer';
export {watchLoad} from './loads.saga';

export const loadActions = {
    /** Create, Edit Load */
    createLoad,
    createLoadTemplate,
    editLoad,
    cancelLoad,
    /** End */

    /** Get Loads */
    getLoadTotals,
    getLoadsByStatus,
    getCustomerLoads,
    getCarrierLoads,
    getCarrierLastLoads,
    getLoadById,
    getLoadsByPayable,
    getReceivablesInfo,
    getPayableInfo,
    /** End */

    /** Carriers CarrierPayment */
    searchCustomerReceivables,
    filterByAlphabeticalReceivables,
    filterByDefaultReceivables,
    /** End */

    /** Load Transactions */
    getLoadTransactions,
    getLoadCarrierTransactions,
    getLoadOtherTransactions,
    /** End */

    /** Load Payable */
    sendToPayable,
    approvePayable,
    /** End */

    /** Pasts */
    getPastPayable,
    getPastBill,
    reBill,
    /** End */

    searchList,
    removeSearchedList,

    /** Receivable Notes */
    getRecNotes,
    createRecNote,
    editRecNote,
    deleteRecNote,
    /** End */

    uploadLoadList,

    /** Customer Order */
    createCustomerOrder,
    editCustomerOrder,
    deleteCustomerOrder,
    /** End */

    /** Grouped Loads */
    getGroupedLoads,
    getGroupById,
    getGroupLoads,
    cancelGroup,
    getGroupPayableLoads,
    getGroupLoadsTxns,
    /** End */
}


export const loadAuthActions = {

    getReceivablesInfoService: (date) => axios.get(`/reports/loads`, {auth: true, params: {...date}}),

    verifyBilling: (id) => axios.patch(`/loads/${id}/billing/verify`, {}, {auth: true}),

    loadToRevision: (id, comment) => axios.post(`/loads/${id}/revision`, {comment: comment}, {auth: true}),

}