import {
    createCustomer,
    getCustomers,
    editCustomer,
    activateCustomer,
    inactivateCustomer,
    getCustomerById,
    assignBlackList,
    removeBlackList,
    getDeny,
    activateDeny,
    inactivateDeny,
    getCustomerDebt,
    addCustomerDocument,
    deleteCustomerDocument,
    nonFactoringCustomer,
    consignCustomer,
    nonNoPodCustomer,
    getCustomersSearch,
    editCustomerDocument,
    getCustomerLoads,
    verifyCustomer,
    getCustomerFactoredLoads,
} from './customers.action';

export {customerReducer} from './customers.reducer';
export {watchCustomer} from './customers.saga';

export const customerActions = {
    /** Create, Edit Customer */
    createCustomer,
    editCustomer,
    /** End */

    /** Get Carriers */
    getCustomers,
    getCustomersSearch,
    getCustomerById,
    /** End */

    /** Activate or Inactivate Customer */
    activateCustomer,
    inactivateCustomer,
    nonFactoringCustomer,
    nonNoPodCustomer,
    /** End */

    /** Customer Mc BlackList */
    assignBlackList,
    removeBlackList,
    getCustomerDebt,
    /** End */

    /** AutoDeny */
    getDeny,
    activateDeny,
    inactivateDeny,
    /** End */

    /** Customer Documents */
    addCustomerDocument,
    deleteCustomerDocument,
    editCustomerDocument,
    /** End */

    consignCustomer,

    /** Customer Verification */
    getCustomerFactoredLoads,
    verifyCustomer

}

