import { isFactored } from 'utils';
import { CreateLoadTable } from './createLoad';
import { CreateFactoredLoadTable } from './createFactoredLoad';

export const CreateLoadSwitcher = ({}) => {
  return (
    <div>
      {isFactored ?
        <CreateFactoredLoadTable />
        :
        <CreateLoadTable />
      }
    </div>
  );
};