import React from "react";
import {useSelector} from "react-redux";
import {ApDetailed, ApSummary, ApActivity, ApApplication} from "fragments";
import {ReportTabs} from "components";
import {PermissionsList, RolePermission} from "../../utils";

export const ApReport = ({}) => {
    const {apDetailed, apSummary, apActivity, apApplication} = useSelector((state) => ({
        apDetailed: state.reports.apDetailed,
        apSummary: state.reports.apSummary,
        apActivity: state.reports.apActivity,
        apApplication: state.reports.apApplication,
    }))

    const tabsLabels = [
        // RolePermission([PermissionsList?.READ_AP_DETAILED_REPORT?.code]) &&
        // {
        //     label: 'AP Detailed Report'
        // },
        RolePermission([PermissionsList?.READ_AP_SUMMARY_REPORT?.code]) &&
        {
            label: 'AP Summary'
        },
        // RolePermission([PermissionsList?.READ_AP_ACTIVITY_REPORT?.code]) &&
        // {
        //     label: 'AP Activity'
        // },
        // RolePermission([PermissionsList?.READ_AP_PAYMENT_APPLICATION_REPORT?.code]) &&
        // {
        //     label: 'Payment Application'
        // },
    ]

    const tabsContent = [
        // RolePermission([PermissionsList?.READ_AP_DETAILED_REPORT?.code]) &&
        // {
        //     tabComponent: <ApDetailed apDetailed={apDetailed}/>,
        //     tab: 'AP Detailed Report'
        // },
        RolePermission([PermissionsList?.READ_AP_SUMMARY_REPORT?.code]) &&
        {
            tabComponent: <ApSummary />,
            tab: 'AP Summary'
        },
        // RolePermission([PermissionsList?.READ_AP_ACTIVITY_REPORT?.code]) &&
        // {
        //     tabComponent: <ApActivity apActivity={apActivity}/>,
        //     tab: 'AP Activity'
        // },
        // RolePermission([PermissionsList?.READ_AP_PAYMENT_APPLICATION_REPORT?.code]) &&
        // {
        //     tabComponent: <ApApplication apApplication={apApplication}/>,
        //     tab: 'Payment Application'
        // }
    ];

    return (
        <div>
            <ReportTabs
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
                defaultTab={
                    // RolePermission([PermissionsList?.READ_AP_DETAILED_REPORT?.code]) ? 'AP Detailed Report' :
                        RolePermission([PermissionsList?.READ_AP_SUMMARY_REPORT?.code]) ? 'AP Summary' :
                            // RolePermission([PermissionsList?.READ_AP_ACTIVITY_REPORT?.code]) ? 'AP Activity' :
                            //     RolePermission([PermissionsList?.READ_AP_PAYMENT_APPLICATION_REPORT?.code]) ? 'Payment Application' :
                            //         'AP Detailed Report'
                                    'AP Summary'
                }
            />
        </div>
    )
}