import {ScrollToTop, Toast} from "components";
import {RouterSwitcher} from "./root/routerSwitcher";
import React, {useEffect} from "react";
import { Images, ToastFail, ToastSuccess } from './utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from './store';
import {useDispatch, useSelector} from "react-redux";
import { screensStyle } from './components/screens/styles';
import { toast, ToastContainer } from 'react-toastify';

function App() {
    const {httpOnError, httpOnSuccess} = useSelector((state) => ({
        httpOnLoad: state.httpOnLoad,
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
    }));
    const dispatch = useDispatch();
    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error);
    const classes = screensStyle()

    useEffect(() => {
        if (toastSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    // useEffect(() => {
    //     if (toastFail) {
    //         // dispatch(httpRequestsOnErrorsActions.removeError( error ))
    //     }
    // }, [toastFail]);

    useEffect(() => {
        // if (httpOnSuccess?.length) {
        //     httpOnSuccess?.forEach((item) => {
        //         const text = ToastSuccess(item?.type);
        //         if (text) {
        //             toast(
        //               <div className={classes.toastWrapper}>
        //                   <img src={Images.success} alt="success" />
        //                   <p className={classes.toastText}>{text}</p>
        //               </div>,
        //             );
        //         }
        //         dispatch(httpRequestsOnSuccessActions.removeSuccess(item?.type));
        //     });
        // }
        // if (httpOnError?.length) {
        //     httpOnError?.forEach((item) => {
        //         const toastFail = ToastFail(item?.type, item?.error);
        //         if (toastFail) {
        //             toast(
        //               <div className={classes.toastWrapper}>
        //                   <img src={Images.toastError } alt="success" />
        //                   <p className={classes.toastText}>{toastFail === 'backMessage' ? item?.error : toastFail }</p>
        //               </div>,
        //             );
        //         }
        //         dispatch(httpRequestsOnErrorsActions.removeError(item?.type));
        //     });
        // }
    }, [httpOnSuccess]);

    return (
        <div>
            <ScrollToTop/>
            <RouterSwitcher/>
            <Toast
                type={toastSuccess ? "success" : toastFail ? "error" : ""}
                text={toastSuccess ? toastSuccess : toastFail ? toastFail : ""}
                info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ""}
            />

            {/*<ToastContainer*/}
            {/*  className={`success ${classes.defaultToast}`}*/}
            {/*  autoClose={3500}*/}
            {/*  hideProgressBar*/}
            {/*  newestOnTop={false}*/}
            {/*  closeOnClick*/}
            {/*  rtl={false}*/}
            {/*  pauseOnFocusLoss*/}
            {/*  draggable={false}*/}
            {/*  pauseOnHover={false}*/}
            {/*  position={'bottom-right'}*/}
            {/*/>*/}
        </div>
    );
}

export default App;
