/** Ar Reports */
export const GET_AR_DETAILED = 'GET_AR_DETAILED';
export const GET_AR_DETAILED_SUCCESS = 'GET_AR_DETAILED_SUCCESS';

export const CLEAN_AR_DETAILED = 'CLEAN_AR_DETAILED';

export const GET_AR_SUMMARY = 'GET_AR_SUMMARY';
export const GET_AR_SUMMARY_SUCCESS = 'GET_AR_SUMMARY_SUCCESS';

export const CLEAN_AR_SUMMARY = 'CLEAN_AR_SUMMARY';

export const GET_AR_ACTIVITY = 'GET_AR_ACTIVITY';
export const GET_AR_ACTIVITY_SUCCESS = 'GET_AR_ACTIVITY_SUCCESS';

export const GET_AR_APPLICATION = 'GET_AR_APPLICATION';
export const GET_AR_APPLICATION_SUCCESS = 'GET_AR_APPLICATION_SUCCESS';


/** Ap Reports */
export const GET_AP_DETAILED = 'GET_AP_DETAILED';
export const GET_AP_DETAILED_SUCCESS = 'GET_AP_DETAILED_SUCCESS';

export const CLEAN_AP_DETAILED = 'CLEAN_AP_DETAILED';

export const GET_AP_SUMMARY = 'GET_AP_SUMMARY';
export const GET_AP_SUMMARY_SUCCESS = 'GET_AP_SUMMARY_SUCCESS';

export const CLEAN_AP_SUMMARY = 'CLEAN_AP_SUMMARY';

export const GET_AP_ACTIVITY = 'GET_AP_ACTIVITY';
export const GET_AP_ACTIVITY_SUCCESS = 'GET_AP_ACTIVITY_SUCCESS';

export const GET_AP_APPLICATION = 'GET_AP_APPLICATION';
export const GET_AP_APPLICATION_SUCCESS = 'GET_AP_APPLICATION_SUCCESS';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';