import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateWrapper } from 'components';
import { loadActions } from 'store';
import {
  CustomerCredit,
  DocumentsSection,
  Processing,
} from './core';
import {
  CANCELLED,
  CONSIGNMENT,
  CONSIGNMENT_REQUEST,
  CREDITCHECK,
  REJECTED,
} from '../../../pages/accounting/constants';
import { FactoredLoadSummary } from './core/factoredLoadSummary';
import { LoadErrAndNotes } from './core/loadErrAndNotes';
import { CustomerInfo } from './core/customerInfo';
import { FactoredDestination } from './core/destination';
import { StopType } from '../../../utils/types';
import { LoadActions } from '../createLoad/core/loadActions';

export const CreateFactoredLoadTable = ({ }) => {
  const { loadById, customers } = useSelector((state) => ({
    loadById: state.loads.loadById,
    customers: state.loads.customers,
  }));
  const dispatch = useDispatch();
  const params = useParams();
  const [documents, setDocuments] = useState(false);
  const [last, setLast] = useState(false);
  const [tonu, setTonu] = useState(loadById && loadById?.isTonu);
  const drop = loadById && loadById?.stops?.length && loadById?.stops.filter((i) => i.type === StopType.DROPOFF);
  const pick = loadById && loadById?.stops?.length && loadById?.stops.filter((i) => i.type === StopType.PICKUP);

  useEffect(() => {
    dispatch(loadActions.removeSearchedList());
  }, []);

  useEffect(() => {
    if (loadById && loadById.isTonu === true) {
      setDocuments(true);
    } else {
      if (loadById?.id && loadById?.status !== CREDITCHECK && loadById?.status !== REJECTED && loadById?.status !== CONSIGNMENT_REQUEST) {
        setDocuments(true);
      }
    }
  }, [loadById]);

  return (
    <div>
      <CreateWrapper
        type={params.id ? 'load' : ''}
        noHead={true}
        body={
          <div>
            {/*{loadById?.status !== CANCELLED && <LoadActions loadById={loadById} />}*/}
            <LoadErrAndNotes loadById={loadById} />
            {loadById?.status && <FactoredLoadSummary loadById={loadById} />}
            <CustomerCredit
              tonu={tonu}
              info={loadById}
              customers={customers?.customers}
              handleSwitchTonu={() => setTonu(!tonu)}
            />
            {documents &&
              <CustomerInfo />
            }
            {documents &&
              <FactoredDestination
                info={loadById}
                id={params.id}
              />
            }
            {documents &&
              <DocumentsSection
                info={loadById}
                id={params.id}
                destination={last}
                andleChangeScreen={() => setLast(true)}
              />
            }
            {loadById?.status === 'PROCESSING' && loadById?.status !== CANCELLED &&
              <Processing info={loadById} />
            }
          </div>
        }
      />
    </div>
  );
};