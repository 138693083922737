import {
  FILTER_REC,
  FILTER_REC_DEFAULT,
  GET_CARRIER_LAST_LOAD_SUCCESS,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_LOADS_SUCCESS, GET_GROUP_LOADS_TXNS_SUCCESS,
  GET_GROUP_PAYABLE_LOADS_SUCCESS,
  GET_GROUPED_LOADS_SUCCESS,
  GET_LOAD_BY_ID_SUCCESS,
  GET_LOAD_CARRIER_SUCCESS,
  GET_LOAD_CARRIER_TRANSACTIONS_SUCCESS,
  GET_LOAD_CUSTOMER_SUCCESS,
  GET_LOAD_OTHER_TRANSACTIONS_SUCCESS,
  GET_LOAD_TOTALS_SUCCESS,
  GET_LOAD_TRANSACTIONS_SUCCESS,
  GET_LOADS_BY_PAYABLE_SUCCESS,
  GET_LOADS_BY_STATUS_SUCCESS,
  GET_LOADS_PAYABLE_SUCCESS,
  GET_LOADS_RECEIVABLES_SUCCESS,
  GET_PAST_BILLS_SUCCESS,
  GET_PAST_PAYABLE_SUCCESS,
  GET_RECEIVABLES_NOTE_SUCCESS,
  GET_SEARCH_LOAD_CUSTOMERS_SUCCESS,
  REMOVE_SEARCHED_CUSTOMERS,
  SEARCH_REC,
} from './loads.types';
import { paginate, searchByType, sortByAz } from 'utils';

const initialState = {
  loadsList: [],
  // loadsListReserve: [],
  loadCount: [],
  billableLoads: [],

  processingLoadsList: [],
  processingLoadsListReserve: [],

  loadById: null,
  customerLoads: [],
  customerLoadsReserve: [],

  carrierLoads: [],
  carrierLastLoads: [],

  loadTransactions: [],
  loadCarrierTxns: [],
  loadOtherTxns: [],
  receivablesInfo: null,
  payableInfo: null,

  pastPayable: null,
  pastBill: null,
  loadTotals: null,

  customers: [],
  receivableNotes: [],

  groupedLoads: [],
  groupById: null,
  groupLoads: null,
  groupPayableLoads: [],
  groupLoadTxns: [],
};

export const loadReducer = (state = initialState, action) => {
  switch (action.type) {

    /** Get Loads */

    case GET_LOAD_TOTALS_SUCCESS:
      return {
        ...state,
        loadTotals: action.payload,
      };

    case GET_LOADS_BY_PAYABLE_SUCCESS:
      return {
        ...state,
        loadsList: action.payload,
      };

    case GET_LOADS_BY_STATUS_SUCCESS:
      return {
        ...state,
        loadsList: action.payload,
      };

    case GET_LOAD_BY_ID_SUCCESS:
      return {
        ...state,
        loadById: action.payload,
      };

    case GET_LOAD_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerLoads: paginate((action.payload), 10),
        customerLoadsReserve: action.payload,
      };

    case GET_LOAD_CARRIER_SUCCESS:
      return {
        ...state,
        carrierLoads: action.payload,
      };

    case GET_CARRIER_LAST_LOAD_SUCCESS:
      return {
        ...state,
        carrierLastLoads: action.payload,
      };


    case GET_LOADS_RECEIVABLES_SUCCESS:
      return {
        ...state,
        receivablesInfo: action.payload,
      };

    case GET_LOADS_PAYABLE_SUCCESS:
      return {
        ...state,
        payableInfo: action.payload,
      };
    /** End */


    /** Carriers Filters */
    case SEARCH_REC:
      return {
        ...state,
        customerLoads: searchByType(state.customerLoadsReserve, action.payload.value, action.payload.type),
      };

    case FILTER_REC:
      return {
        ...state,
        customerLoads: sortByAz(state.customerLoadsReserve, action.payload.name),
      };

    case FILTER_REC_DEFAULT:
      return {
        ...state,
        customerLoads: paginate((state.customerLoadsReserve), 10),
      };

    /** End */

    /** Load Transactions */

    case GET_LOAD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadTransactions: action.payload,
      };

    case GET_LOAD_CARRIER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadCarrierTxns: action.payload,
      };

    case GET_LOAD_OTHER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadOtherTxns: action.payload,
      };

    /** End */

    /** Pasts */

    case GET_PAST_PAYABLE_SUCCESS:
      return {
        ...state,
        pastPayable: action.payload,
      };

    case GET_PAST_BILLS_SUCCESS:
      return {
        ...state,
        pastBill: action.payload,
      };

    case GET_SEARCH_LOAD_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      };

    case REMOVE_SEARCHED_CUSTOMERS:
      return {
        ...state,
        customers: [],
      };

    /** End */


    /** Receivable Notes */

    case GET_RECEIVABLES_NOTE_SUCCESS:
      return {
        ...state,
        receivableNotes: action?.payload,
      };


    /** End */

    /** Grouped Loads */

    case GET_GROUPED_LOADS_SUCCESS:
      return {
        ...state,
        groupedLoads: action.payload,
      };

    case GET_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        groupById: action.payload,
      };

    case GET_GROUP_LOADS_SUCCESS:
      return {
        ...state,
        groupLoads: action.payload,
      };

    case GET_GROUP_PAYABLE_LOADS_SUCCESS:
      return {
        ...state,
        groupPayableLoads: action.payload,
      };

    case GET_GROUP_LOADS_TXNS_SUCCESS:
      return {
        ...state,
        groupLoadTxns: action.payload,
      };
    /** End */

    default:
      return state;
  }
};
