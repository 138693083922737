import React, {useEffect, useState} from "react"
import {buttonsStyle} from "./styles"
import {EditButton} from './editButton'
import {Button} from "@material-ui/core"
import {useDispatch, useSelector} from "react-redux";
import {authActions, httpRequestsOnSuccessActions} from "store";
import {MinLoader} from "../loader";
import {Colors, FindLoad, FindSuccess, Images} from "../../utils";

export const EditSaveButtons = ({
                                    handleSaveInfo, handleSetEdit,
                                    handleChancel, type, butt, margin, tableType, loadLoading,icon,afterSuccess
                                }) => {
    const [edit, setEdit] = useState(false)
    const classes = buttonsStyle();
    const dispatch = useDispatch()
    const {httpOnSuccess, httpOnLoad} = useSelector((state) => ({
        httpOnSuccess: state.httpOnSuccess,
        httpOnLoad: state.httpOnLoad
    }));

    const successType = FindSuccess(type)
    const loader = FindLoad(type)

    useEffect(() => {
        if (successType?.length) {
            dispatch(authActions.removeSuccess(type))
            dispatch(httpRequestsOnSuccessActions.removeSuccess(type))
            setEdit(false)
            if(afterSuccess) {
                afterSuccess()
            }
        }
    }, [successType]);

    const handleSave = (info) => {
        handleSaveInfo()
        // if (info) {
        //     // setEdit(false)
        // }
    }

    const handleEdit = () => {
        setEdit(true)
        handleSetEdit && handleSetEdit()
    }

    const handleDone = () => {
        setEdit(false)
        handleChancel && handleChancel()
    }

    const cancel = () => {
        setEdit(false)
        handleChancel()
    }

    return (
        <div style={margin ? {marginTop: '-48px'} : {}}>
            {edit === false ?
                <EditButton handleClick={handleEdit} icon={icon}/>
                :
                 butt === 'done' ?
                    <EditButton text={'Done'} handleClick={handleDone}/>
                    :
                    <div className={classes.buttonsWrapper}>
                        <Button
                            className={classes.cancelButton}
                            onClick={cancel}
                        >
                            Cancel
                        </Button>

                        {tableType === 'load' ?
                            <Button
                                className={classes.saveButton}
                                onClick={handleSave}
                            >
                                <div className={classes.loaderWrapper}>
                                    {loadLoading === true ?
                                        <MinLoader margin={0} position={'relative'} color={Colors.ThemeBlue}/>
                                        :
                                        'Save'
                                    }
                                </div>
                            </Button>

                            :

                            <Button
                                className={classes.saveButton}
                                onClick={handleSave}
                            >
                                <div className={classes.loaderWrapper}>
                                    {loader.length ?
                                        <MinLoader margin={'9px 0 0 0'} position={'relative'}
                                                   color={Colors.ThemeBlue}/>
                                        :
                                        'Save'
                                    }
                                </div>
                            </Button>
                        }
                    </div>
            }
        </div>
    )
}