import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, Loader, PriceConvertor} from "components";
import {FindLoad, useGlobalStyles} from "utils";
import {loadActions} from "store";
import {carrierTransactionHead, transactionBody} from "./constants";
import {transactionStyles} from "../loads/transactions/core";

const ACTION_TYPE = 'GET_LOAD_TRANSACTIONS'

export const PaymentTableModal = ({loadId}) => {
    const {loadTransactions, loadById} = useSelector((state) => ({
        loadTransactions: state.loads.loadTransactions,
        loadById: state.loads.loadById,
    }));
    const globalStyles = useGlobalStyles();
    const classes = transactionStyles()

    const list = loadTransactions?.txns?.filter((i) => i?.onModel === "CARRIER")
    const loader = FindLoad(ACTION_TYPE)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadActions.getLoadTransactions(loadId, 'success'))
        dispatch(loadActions.getLoadById(loadId))
    }, [])

    return (
        <div className='payment-table-modal-wrapper'>
            {loader?.length ?
                <Loader height={'100%'} style={'relative'}/>
                :
                <div>
                    <p className='title'>Vendor Transactions</p>
                    <FullTable
                        height={'medium'}
                        head={carrierTransactionHead}
                        body={transactionBody}
                        loadingType={ACTION_TYPE}
                        list={list?.length ? list : []}
                        noText={'Carrier Transaction'}
                    />


                    <div style={{width: '100%'}} className={classes.itemsWrapper}>
                        <p className={classes.title}>Vendor Total</p>
                        <div className={globalStyles.flexAble}>
                            <div className={classes.items}>
                                <p>Rate Total: </p>
                                <span>
                                    <PriceConvertor price={loadById?.carrierRate}/>
                                </span>
                            </div>
                            <div className={classes.items}>
                                <p>Charges: </p>
                                <span>
                                    <PriceConvertor price={loadById?.carrierCharges ? (loadById?.carrierCharges * 100) / 100 : 0}/>
                                </span>
                            </div>
                            <div className={classes.items}>
                                <p>Paid Total:</p>
                                <span>
                                    <PriceConvertor price={loadById?.carrierPaid}/>
                                </span>
                            </div>
                            <div style={{margin: 0}} className={classes.items}>
                                <p>Balance: </p>
                                <span>
                                    <PriceConvertor price={((loadById?.carrierRate - loadById?.carrierPaid - loadById?.carrierCharges - loadById?.debtCollected) * 100) / 100}/>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/*<div className='close-wrapper'>*/}
                    {/*    <div className='grand-total'>*/}
                    {/*        <p>Grand Total:&nbsp;*/}
                    {/*            <b>*/}
                    {/*                <PriceConvertor price={total ? total : 0}/>*/}
                    {/*            </b>*/}
                    {/*        </p>&nbsp;*/}
                    {/*        {` ( ${list?.length ? list?.length : 0} )`}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            }
        </div>
    )
}