import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { navBarStyles } from './style';
import { CustomBreadcrumbs } from 'components';
import { UserInfo } from './userInfo';
import { AppBar, Toolbar } from '@material-ui/core';
import Notification from '../notifications/notification';
import { notificationsActions } from 'store';
import { getMenuTitle } from './constants';
import { VendorTables } from '../../../pages/vendors/constants';
import { loadTabs } from '../../../pages/loads/constants';

export const TopBar = ({ open }) => {
  const { notifications, carrierById } = useSelector((state) => ({
    notifications: state.notifications.notifications,
    carrierById: state.carriers.carrierById,
  }));
  const classes = navBarStyles();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));
  const url = window.location.pathname;
  const menuTittle = getMenuTitle(url);
  const location = useLocation();
  const originalPath = location?.pathname?.split('/')[1];
  const history = useHistory();
  const info = history?.location?.state;
  const currentTab = VendorTables(info?.vendorType);

  useEffect(() => {
    const params = { pageSize: 6, page: 1 };
    dispatch(notificationsActions.getNotifications(params));
  }, []);

  const renderLinks = () => {
    if (originalPath === 'humanResource') {
      return { parent: 'Human Resources', child: 'Human Resource Info', parentLink: '/humanResources' };
    }
    if (originalPath === 'createHumanResources') {
      return { parent: 'Human Resources', child: 'Add Human Resource', parentLink: '/humanResources' };
    }

    if (originalPath === 'office') {
      return { parent: 'Offices', child: 'Office Info', parentLink: '/offices' };
    }
    if (originalPath === 'createOffice') {
      return { parent: 'Offices', child: 'Add Office', parentLink: '/offices' };
    }

    if (originalPath === 'branch') {
      return { parent: 'Branches', child: 'Branch Info', parentLink: '/branches' };
    }
    if (originalPath === 'createBranch') {
      return { parent: 'Branches', child: 'Add Branch', parentLink: '/branches' };
    }

    if (originalPath === 'agent') {
      return { parent: 'Agents', child: 'Agent Info', parentLink: '/agents' };
    }
    if (originalPath === 'createAgent') {
      return { parent: 'Agents', child: 'Add Agent', parentLink: '/agents' };
    }

    if (originalPath === 'authority') {
      return { parent: 'Authorities', child: 'Authority Info', parentLink: '/authorities' };
    }

    if (originalPath === 'createAuthorities') {
      return { parent: 'Authorities', child: 'Add Authority', parentLink: '/authorities' };
    }

    if (originalPath === 'customer') {
      return { parent: 'Customers', child: 'Customer Info', parentLink: '/customers' };
    }
    if (originalPath === 'createCustomer') {
      return { parent: 'Customers', child: 'Add Customer', parentLink: '/customers' };
    }

    if (originalPath === 'vendor') {
      return { parent: 'Vendors', child: 'Vendor Info', parentLink: '/vendors', pushParams: true };
    }

    if (originalPath === 'create-vendor') {
      return { parent: 'Vendors', child: `Add ${currentTab?.title}`, parentLink: '/vendors', pushParams: true };
    }

    // if (originalPath === 'carrier') {
    //   return { parent: 'Carriers', child: 'Carrier Info', parentLink: '/carriers' };
    // }
    // if (originalPath === 'createCarriers') {
    //   return { parent: 'Carriers', child: 'Add Carrier', parentLink: '/carriers' };
    // }


    if (originalPath === 'factoring') {
      return { parent: 'Factoring Companies', child: 'Factoring Info', parentLink: '/factorings' };
    }
    if (originalPath === 'createFactoring') {
      return { parent: 'Factoring Companies', child: 'Add Factoring', parentLink: '/factorings' };
    }


    if (originalPath === 'load') {
      return { parent: 'Loads', child: 'Load Info', parentLink: '/loads' };
    }
    if (originalPath === 'grouped-load') {
      const url = location?.pathname;
      const groupId = url.split('group/').pop();
      return {
        parent: 'Load Groups',
        child: 'Load Info',
        third: 'Group Info',
        parentLink: '/loads',
        thirdLink: `/load-group/${groupId}`,
        customParams: { tabType: loadTabs?.LOAD_GROUPS },
      };
    }

    if (originalPath === 'create-group') {
      return {
        parent: 'Load Groups',
        child: 'Add Load Group',
        parentLink: '/loads',
        customParams: { tabType: loadTabs?.LOAD_GROUPS },
      };
    }

    if (originalPath === 'load-group') {
      return {
        parent: 'Load Groups',
        child: 'Group Info',
        parentLink: '/loads',
        customParams: { tabType: loadTabs?.LOAD_GROUPS },
      };
    }

    if (originalPath === 'create-load') {
      const url = location?.pathname;
      const parts = url.split(`create-load/`);
      const groupId = parts.length > 1 ? parts[1] : null;

      return groupId ? {
          parent: 'Load Groups',
          child: 'Add Load',
          third: 'Group Info',
          parentLink: '/loads',
          thirdLink: `/load-group/${groupId}`,
          customParams: { tabType: loadTabs?.LOAD_GROUPS },
        }
        :
        {
          parent: 'Loads',
          child: 'Add Load',
          parentLink: '/loads',
          customParams: { tabType: loadTabs?.LOADS }
        };

    }

    if (originalPath === 'create-group') {
      return {
        parent: 'Load Groups',
        child: 'Add Load Group',
        parentLink: '/loads',
        customParams: { tabType: loadTabs?.LOAD_GROUPS },
      };
    }


    if (originalPath === 'carrierPayment') {
      return { parent: 'Carrier Payments', child: 'Payment Details', parentLink: '/carrierPayments' };
    }

    if (originalPath === 'customerPayment') {
      return { parent: 'Customer Payment', child: 'Customer Payment Details', parentLink: '/customerPayments' };
    }


    if (originalPath === 'template') {
      return { parent: 'Templates', child: 'Templates Info', parentLink: '/templates' };
    }


    if (originalPath === 'equipment') {
      return {
        parent: 'Carriers',
        child: 'Equipment Details',
        third: `Carrier Info`,
        parentLink: '/customerPayments',
        thirdLink: `/carrier/${carrierById?.id}`,
      };
    }
  };


  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: !!open,
      })}
    >
      <Toolbar className={classes.headerContent}>
        <div className={classes.Toolbar}>
          <div className={!!open === true ? classes.openToolbar : classes.closeToolbar}>
            {menuTittle === 'detail' ?
              renderLinks()?.child ?
                <CustomBreadcrumbs
                  parentLink={renderLinks()?.parentLink}
                  parent={renderLinks()?.parent}
                  child={renderLinks()?.child}
                  third={renderLinks()?.third}
                  thirdLink={renderLinks()?.thirdLink}
                  pushParams={renderLinks()?.pushParams}
                  customParams={renderLinks()?.customParams}
                />
                : null
              :

              <p className={classes.getMenuTitle}>{menuTittle}</p>
            }
          </div>

          <div className={classes.notAndUser}>
            <Notification notifications={notifications} />
            <UserInfo userInfo={userInfo} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
