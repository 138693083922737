import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CsvFile, FullTable, TableWrapper, CustomButtonsTab } from 'components';
import { loadActions } from 'store';
import { PermissionsList, RolePermission, useModal, userType } from 'utils';
import { loadsBody, loadsHead, loadCsv, loadTabs, LOAD_ACTION_TYPE } from './constants';
import { CreditCheck } from '../core';
import { CreateLoadBtn } from '../../../fragments/loads/createLoad/core';

export const LoadCard = ({}) => {
  const { loadsList } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
  }));
  const { open } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    info?.status === 'ALLLOADS' && delete filteredInfo.status;
    if (info?.from) {
      filteredInfo.from = moment(info?.from).format('YYYY-MM-DDT00:00:01');
    }
    if (!info?.to) {
      filteredInfo.to = moment().format('YYYY-MM-DDT23:59:59');
    } else {
      filteredInfo.to = moment(filteredInfo.to).format('YYYY-MM-DDT23:59:59');
    }
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    delete filteredInfo.tabType;

    if (userType === 'AGENT' && !info?.statuses) {
      filteredInfo.statuses = ['PENDING', 'INTRANSIT', 'CREDITCHECK'];
      filteredInfo.isRevision = true;
      filteredInfo.isVerification = true;
    }
    if (info?.statuses && info?.statuses?.length === 0) {
      delete filteredInfo.isRevision;
      delete filteredInfo.isVerification;
      delete filteredInfo.statuses;
    }
    return filteredInfo;
  };

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }, loader));
  };

  const pushPageDetails = async (id) => {
    history.push({
      pathname: `/load/${id}`,
      state: { loadParams: { ...info } },
    });
  };

  return (
    <div>
      <div className="space-between" style={{ marginBottom: '16px', alignItems: 'flex-start' }}>
        <CustomButtonsTab
          first={'loads'}
          second={'Load Groups' }
          width={'150px'}
          maxWidth={'320px'}
          firstName={loadTabs?.LOADS}
          secondName={loadTabs?.LOAD_GROUPS }
        />
        <div className="align-center">
          {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_LOAD_CSV?.code]) &&
            <CsvFile params={loadCsv} />
          }
          {userType !== 'ADMIN' &&
            <div className="align-center" style={{ marginBottom: '16px' }}>
              <button className="credit-check-btn" onClick={() => open(<CreditCheck />)}>
                Credit Check
              </button>
              <CreateLoadBtn />
            </div>
          }
        </div>
      </div>

      <TableWrapper
        firstButton={'Active'}
        secondButton={'Inactive'}
        addButton={'Add Load'}
        buttonsTab={userType !== 'ADMIN'}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <FullTable
          head={loadsHead(info?.tabType)}
          body={loadsBody(info?.tabType)}
          loadingType={LOAD_ACTION_TYPE}
          list={loadsList?.loads}
          listCount={loadsList?.count}
          handleClick={pushPageDetails}
          noText={'Loads'}
          dateFilter={true}
          typeForFilter={true}
          dateFrom={true}
          padding={'12px 10px'}
          paddingHead={'16px 10px'}
        />
      </TableWrapper>
    </div>
  );
};
