import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const carrierInfoFragments = makeStyles(() => ({
    noInfoYet: {
        padding: '24px',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    access: {
        display: 'flex',
        width: '100%',
    },
    paymentWrapper: {
        display: 'flex',
        width: '100%',
        height: 'auto',
    },
    accessWrapper: {
        display: 'flex',
        width: '100%',
        // height: '400px',
        // background:'red'
    },
    paymentHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 24px',
        height: '50px',
        background: '#438AFE1A 0% 0% no-repeat padding-box',
        borderRadius: '4px 4px 0px 0px',
        width: '100%',
        justifyContent: 'space-between',
    },
    paymentContentWrapper: {
        width: '100%',
        marginTop: '24px',
    },
    paymentContent: {
        height: '274px',
    },
    paymentBody: {
        height: '245px',
        padding: '24px',
        overflow: 'auto',
    },
    paymentBodyBig: {
        height: '400px',
        padding: '24px',
        overflow: 'auto',
    },
    paymentBodyHeight: {
        minHeight: '500px',
        padding: '24px',
        overflow: 'auto',
    },
    paymentItemWrapper: {
        marginBottom: '24px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
    },
    radioBorder: {
        border: `1px solid ${Colors.ThemeBorderBlue}`,
        borderRadius: '4px',
        marginBottom: '16px',
        padding: '0 16px',
    },
    addEquipment: {
        position: 'absolute',
        right: 0,
        marginTop: '-50px',
    },
    select: {
        marginBottom: '24px',
    },
    blackWrapper: {
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 6px #8A8A8A29',
        borderRadius: '8px',
        height: '384px',
        width: '100%',
        margin: '24px 24px 0 0',
        padding: '16px',
        "@media (min-width: 1919px)": {
            padding: '24px',
        },
    },
    authoritiesSelect: {
        margin: '20px 0 16px 0',
        width: '49%'
    },
    rateSwitch: {
        display: 'flex',
        alignItems: 'center',
    },
    inputDescription: {
        height: '128px',
        width: '100%',
        '& .MuiOutlinedInput-inputMultiline': {
            maxHeight: '110px',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBorder,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '& .MuiOutlinedInput-root': {
            height: '128px',
            minHeight: '128px',
        },
    },
    inputDescriptionField: {
        height: '128px',
        width: '100%',
        '& .MuiOutlinedInput-inputMultiline': {
            maxHeight: '110px',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '& .MuiOutlinedInput-root': {
            height: '128px',
            minHeight: '128px',
        },
    },
    bodyItem: {
        background: '#FFFFFF',
        borderBottom: '1px solid #E6ECF3',
        minHeight: '50px',
        height: 'auto',
        display: 'flex',
        fontWeight: 400,
        fontSiz: '14px',
        lineHeight: '20px',
        color: '#51566D',
    },
    cardRow: {
        width: '40%',
        padding: '15px 0 15px 24px',
        display: 'flex',
        alignItems: 'center',

        '& img': {
            marginRight: '6px'
        },
        '& span':{
            width:'100%'
        }
    },
    commentRow: {
        width: '49%',
        padding: '15px 0 15px 10px',
    },
    actionsRow:{
        width: '10%',
        display: 'flex',
        alignItems: 'center',
    },
    rmisIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2A374E',
        '& p':{
          marginLeft:'11px'
        },

    },

    rmisActionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        background: '#E6ECF3',
        borderRadius: '8px',
        height: '68px',
        padding: '0 24px',
        marginBottom: '24px',
    },
    rmisSettings: {
        display: 'flex',
        alignItems: 'center',
    },
    rmisSync: {
        height: '36px',
        width: '124px',
        border: 'none',
        color: 'white',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        background: '#00C851',
        boxShadow: '0px 0px 6px rgba(0, 200, 81, 0.3)',
        borderRadius: '32px',
        marginLeft: '20px',
    },
    requireRmis: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginRight: '10px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#2A374E',
        }
    }
}))