import { carrierStatuses, statusesEnum } from '../../carriers/constants';
import { vendorEnums } from '../constants';

export const vendorStatusesEnum = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
  { id: 'PENDING', name: 'Pending' },
];

export const vendorCsv = {
  type: 'carrier',
  statusList: carrierStatuses,
  requiredStatus: true,
  statusEnum: 'statuses',
  statusesEnum: statusesEnum,
};


export const vendorTypes = [
  {id: vendorEnums.CARRIER, name: 'Carrier'},
  {id: vendorEnums.CO_BROKER, name: 'Co-Broker'},
  {id: vendorEnums.WAREHOUSE, name: 'Warehouse'},
  {id: vendorEnums.CUSTOM_BROKER, name: 'Custom Broker'},
  {id: vendorEnums.FREIGHT_FORWARDER, name: 'Freight Forwarder'},
  {id: vendorEnums.INTERMODAL_FREIGHT_FORWARDER, name: 'Int. Freight Forwarder'},
  {id: vendorEnums.FACTORED, name: 'Factored Vendor'},
  {id: vendorEnums.VENDOR, name: 'Vendor'},
  {id: vendorEnums.INHOUSE_PROFILE, name: 'Inhouse Profile'},
];

