import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Colors,
  createInputsWrapper,
  ErrorText, officeManager,
  useGlobalStyles,
  useGlobalText, userInfo, userType,
} from 'utils';
import {
  Circle, EditSaveButtons,
  ErrMessage, Line,
  SelectTypeAutocomplete,
  ValidationInput,
} from 'components';
import { httpRequestsOnSuccessActions, loadActions, LoadServices, mcActions } from 'store';
import { groupStatusEnums } from './constants';

const ACTION_TYPE = 'EDIT_GROUP';
export const EditGroup = ({}) => {
  const { mcsList, loadById, groupById, assignedAgentMc, customers } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
    loadById: state.loads.loadById,
    groupById: state.loads.groupById,
    customers: state.loads.customers,
    assignedAgentMc: state.mcs.assignedAgentMc,
  }));
  const mcsListReserve = userType === 'ADMIN' ? mcsList?.mcs : assignedAgentMc;
  const globalScreens = createInputsWrapper();
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [backError, setBackError] = useState('');
  const [loader, setLoader] = useState(false);
  const [disables, setDisabled] = useState(true);
  const [inputs, setInputs] = useState({});


  const selectedCustomer = [
    {
      name: groupById?.customer?.name,
      id: groupById?.customer?.id,
      mc: groupById?.customer?.mc,
    },
  ];

  const selectedMc = [
    {
      name: groupById?.mc?.name,
      id: groupById?.mc?.id,
      mcNumber: groupById?.mc?.mc,
    },
  ];

  const resetInputsByDefault = () => {
    setInputs({
      mc: groupById?.mc?.id,
      customer: groupById?.customer?.id,
      // customerReference: groupById?.customerReference,
      // contactName: groupById?.contactName,
    });
  };

  useEffect(() => {
    if (groupById?.id) {
      resetInputsByDefault();
    }
  }, [groupById]);

  useEffect(() => {
    dispatch(loadActions.removeSearchedList());
    if (userType === 'ADMIN' && !loadById) {
      // dispatch(mcActions.getMcs({ status: 'ACTIVE' }));
      dispatch(mcActions.getMcs());
    }
    if (userInfo && userType !== 'ADMIN' || !officeManager) {
      dispatch(mcActions.getAgentAssignedMcs(userInfo?.id, 'AGENT'));
    }
  }, []);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    if (error === e.target.name) {
      setError('');
      setBackError(null);
    }
  };
  // const handleCreate = async () => {
  //   if (inputs.mc && inputs.customer
  //     // && inputs.customerReference
  //   ) {
  //     const info = {
  //       'mc': inputs.mc,
  //       'customer': inputs.customer?.id ? inputs.customer.id : inputs.customer,
  //       // 'customerReference': inputs.customerReference,
  //       // 'contactName': inputs?.contactName,
  //     };
  //     setLoader(true);
  //     try {
  //       await LoadServices.EditGroup(groupById?.id, info);
  //       dispatch(loadActions.getGroupById(groupById?.id, 'noLoad'));
  //       dispatch(loadActions.getGroupLoads(groupById?.id));
  //       dispatch(httpRequestsOnSuccessActions.appendSuccess(ACTION_TYPE));
  //       setLoader(false);
  //       setDisabled(true);
  //     } catch (e) {
  //       if (e?.data?.message === 'The MC is not assigned to the agent') {
  //         setError('notAssigned');
  //       } else {
  //         setBackError(e?.data?.message);
  //       }
  //       setLoader(false);
  //     }
  //   } else {
  //     setError(
  //       !inputs.mc ? 'mc' :
  //         !inputs.customer ? 'customer' : ''
  //           // !inputs.customerReference ? 'customerReference'
  //           //   : '',
  //     );
  //   }
  // };

  const handle = (e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        dispatch(loadActions.searchList(e.target.value));
      }, 500);
    }
  };

  return (
    <div>
      <div className="space-between">
        <div className={globalStyles.centerItem}>
          <Circle number={2} back={Colors.ThemeAqua} />
          <p className={globalText.title}>Group Details</p>
        </div>

        {/*{groupById?.status !== groupStatusEnums.CANCELLED &&*/}
        {/*<>*/}
        {/*  <EditSaveButtons*/}
        {/*    type={ACTION_TYPE}*/}
        {/*    handleChancel={() => {*/}
        {/*      setDisabled(true);*/}
        {/*      resetInputsByDefault();*/}
        {/*      dispatch(loadActions.removeSearchedList());*/}
        {/*      setError('');*/}
        {/*    }}*/}
        {/*    handleSetEdit={() => setDisabled(false)}*/}
        {/*    handleSaveInfo={handleCreate}*/}
        {/*    tableType={'load'}*/}
        {/*    loadLoading={loader}*/}
        {/*  />*/}
        {/*</>*/}
        {/*}*/}
      </div>

      <div className={globalStyles.flexAndFullWidth}>
        <div className={'MuiAccordionDetails-root'}>
          <Line height={'auto'} />
        </div>

        <div className="full-width" style={{ marginBottom: 24 }}>
          <div className={globalScreens.basicInfoInputs}>
            <SelectTypeAutocomplete
              style={globalStyles.simpleInput}
              name={'mc'}
              label={'name'}
              type={'mcNumber'}
              title={'Booked As Authority*'}
              handleSelect={handleChange}
              defaultValue={inputs.mc}
              list={
                mcsListReserve?.length ? mcsListReserve?.filter((i) => i?.authorityType !== 'DEFAULT') :
                  groupById?.mc?.id ? selectedMc :
                    []}
              disabled={!!groupById?.mc?.id || disables}
              typeError={
                error === 'mc' ? ErrorText.field :
                  error === 'notAssigned' ? 'The MC is not assigned to the agent' :
                    ''}
              error={error === 'mc' && 'mc' || error === 'notAssigned' ? 'mc' : ''}
            />
            <SelectTypeAutocomplete
              style={globalStyles.simpleInputNoRight}
              loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
              name={'customer'}
              label={'name'}
              type={'mc'}
              title={'Customer (Name/MC)*'}
              handleSelect={handleChange}
              defaultValue={inputs?.customer?.id ? inputs.customer.id : inputs.customer}
              list={
                customers?.customers?.length ? customers?.customers :
                  groupById?.customer?.id ? selectedCustomer :
                    []
              }
              handleType={handle}
              disabled={!!groupById?.customer?.id || disables}
              typeError={backError === 'Cannot use this customer due to auto deny' ? 'Cannot use this customer due to auto deny' : ''}
              error={error === 'customer' ? 'customer' :
                backError === 'Cannot use this customer due to auto deny' ? 'customer' : ''
              }
            />
            {/*<ValidationInput*/}
            {/*  className={inputs.customerReference && globalStyles.inputTextFieldBlue}*/}
            {/*  style={globalStyles.simpleInput}*/}
            {/*  variant={'outlined'}*/}
            {/*  name={'customerReference'}*/}
            {/*  label={'Customer Reference*'}*/}
            {/*  type={'text'}*/}
            {/*  typeError={error === 'customerReference' ? ErrorText.field : ''}*/}
            {/*  onChange={handleChange}*/}
            {/*  value={inputs.customerReference}*/}
            {/*  disabled={disables}*/}
            {/*/>*/}
            {/*<ValidationInput*/}
            {/*  className={inputs.contactName && globalStyles.inputTextFieldBlue}*/}
            {/*  style={globalStyles.simpleInputNoRight}*/}
            {/*  variant={'outlined'}*/}
            {/*  name={'contactName'}*/}
            {/*  label={'Customer Contact Name'}*/}
            {/*  type={'text'}*/}
            {/*  typeError={error === 'contactName' ? ErrorText.field : ''}*/}
            {/*  onChange={handleChange}*/}
            {/*  value={inputs.contactName}*/}
            {/*  disabled={disables}*/}
            {/*/>*/}
          </div>

          {backError && <ErrMessage text={backError} />}
          {/*<CreateChancel*/}
          {/*  loader={loader}*/}
          {/*  disabled={loader}*/}
          {/*  classes={globalStyles.buttonsStyle}*/}
          {/*  create={'Create'}*/}
          {/*  chancel={'Cancel'}*/}
          {/*  onCreate={handleCreate}*/}
          {/*  onClose={close}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );
};