import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddButton, FullTable, MultipleSelect } from 'components';
import { carrierActions, httpRequestsOnSuccessActions, rmisActions } from 'store';
import { carriersBody, carriersHead, ACTION_TYPE, carrierCsv, carrierStatusesEnum } from './constants';
import { FindLoad, FindSuccess, PermissionsList, RolePermission, SaveParams } from 'utils';
import { CarrierCsvFile } from '../../components/buttons/carrierCsvFile';

const INVITE = 'INVITE_CARRIER_TO_APP';
const RE_INVITE = 'RE_INVITE_CARRIER_TO_APP';
const INVITE_RMIS = 'INVITE_RMIS';
const ACCEPT_DENY = 'INACTIVATE_ACTIVATE_CARRIER';

export const Carriers = ({}) => {
    const { carriersList } = useSelector((state) => ({
      carriersList: state.carriers.carriersList,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const loadInvite = FindLoad(INVITE);
    const loadReInvite = FindLoad(RE_INVITE);
    const reInviteSuccess = FindSuccess(RE_INVITE);
    const inviteSuccess = FindSuccess(INVITE);
    const loadRmis = FindLoad(INVITE_RMIS);
    const acceptDeny = FindLoad(ACCEPT_DENY);
    const acceptDenySuccess = FindSuccess(ACCEPT_DENY);
    const [selectedId, setSelectedId] = useState('');

    const getList = (loading) => {
      const filteredInfo = {
        ...info,
      };
      filteredInfo.skip = info?.skip ? info?.skip : 0;
      filteredInfo.limit = 100;
      delete filteredInfo.page;
      delete filteredInfo.activeTab;
      const loader = loading === 'loading' ? 'loading' : 'noLoad';
      if (info?.statuses) {
        let filtered = []
        info?.statuses.forEach((i) =>  filtered.push(i.id))
        filteredInfo.statuses = filtered;
        filtered = []
      } else {
        filteredInfo.statuses = ['ACTIVE', 'PENDING'];
      }
      dispatch(carrierActions.getCarriers({ ...filteredInfo }, loader));
    };

    useEffect(() => {
      if (reInviteSuccess?.length) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess(RE_INVITE));
        getList('noLoad');
      }
      if (inviteSuccess?.length) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess(INVITE));
        getList('noLoad');
      }
      if (acceptDenySuccess?.length) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess(ACCEPT_DENY));
        getList('noLoad');
      }
    }, [reInviteSuccess, inviteSuccess, acceptDenySuccess]);

    useEffect(() => {
      getList('loading');
    }, [info]);

    const handleSubmit = (item, name) => {
      setSelectedId(item.id);
      if (name === 'appInvitation') {
        dispatch(carrierActions.inviteCarrierApp(item.id));
      }
      if (name === 'appReInvitation') {
        dispatch(carrierActions.reInviteCarrierApp(item.id));
      }
      if (name === 'rmisInvite') {
        dispatch(rmisActions.inviteRMIS(item.id));
      }
      if (name === 'accept' || name === 'deny' ) {
        const status = name === 'accept' ? 'ACTIVE' : 'INACTIVE';
        dispatch(carrierActions.inactivateActivateCarrier(item?.id, status));
      }
    };

    const pushPageDetails = (id) => {
      history.push({
        pathname: `/carrier/${id}`,
        state: { carrierParams: { ...info } },
      });
    };

    const selectType = (item) => {
      SaveParams(history, {...info, statuses: item})
    };

    return (
      <>
        <div className="space-between">
          <MultipleSelect
            selectedList={info?.statuses ? info?.statuses : [carrierStatusesEnum[0], carrierStatusesEnum[2]] }
            handleChange={selectType}
            permissionsList={carrierStatusesEnum}
            label={'Select Status'}
            renderName={(item) => item?.name}
            smallSize={true}
          />
          <div>
            <div className="align-center" style={{ gap: '24px', alignItems: 'center' }}>
              {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_CARRIER_CSV?.code]) &&
                <CarrierCsvFile params={carrierCsv}/>
              }
              {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                <AddButton link={true} href={'/createCarriers'} text={'Add Carrier'} />
              }
            </div>
          </div>
        </div>
        <FullTable
          head={carriersHead}
          body={carriersBody({
            inviteLoader: loadInvite,
            reInviteLoader: loadReInvite,
            loadRmis: loadRmis,
            acceptDenyLoader: acceptDeny,
            id: selectedId,
          })}
          loadingType={ACTION_TYPE}
          list={carriersList?.carriers}
          listCount={carriersList?.count}
          handleClick={pushPageDetails}
          noText={'Carriers'}
          handleClickButton={handleSubmit}
        />
      </>
    );
  }
;
