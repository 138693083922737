import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, SaveParams, useGlobalStyles, useModal } from 'utils';
import { httpRequestsOnSuccessActions, loadActions, payrollActions } from 'store';


export const CreateCustomerOrder = ({ info }) => {
  const { loadById } = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const ACTION_TYPE = info ? 'UPDATE_CUSTOMER_ORDER' : 'CREATE_CUSTOMER_ORDER';
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState('');
  const globalStyles = useGlobalStyles();
  const loader = FindLoad(ACTION_TYPE);
  const success = FindSuccessItem(ACTION_TYPE);
  const { close } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (info) {
      setInputs({
        orderNumber: info?.orderNumber,
        palletCount: info?.palletCount,
        qtyPerPallet: info?.qtyPerPallet,
        caseQty: info?.caseQty,
        weight: info?.weight,
        dimensions: info?.dimensions,
        commodity: info?.commodity,
        classInfo: info?.classInfo,
        awbAblNumber: info?.awbAblNumber,
        memo: info?.memo,
      });
    }
  }, [info]);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      close();
    }
  }, [success]);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
  };

  const handleCreate = () => {
    function isNotEmptyObject(input) {
      return input && typeof input === 'object' && Object.keys(input).length > 0;
    }
    if (isNotEmptyObject(inputs)) {
      if (info) {
        dispatch(loadActions.editCustomerOrder(info?.id, inputs, loadById?.id));
      } else {
        dispatch(loadActions.createCustomerOrder(inputs, loadById?.id));

      }
    }
  };


  return (
    <div style={{ width: 500 }}>
      <p className="modal-title">Customer Order</p>
      <ValidationInput
        className={inputs.orderNumber && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.orderNumber}
        typeError={error === 'orderNumber' && ErrorText.field}
        label={'Order Number'}
        type={'text'}
        variant={'outlined'}
        name={'orderNumber'}
      />
      <ValidationInput
        className={inputs.palletCount && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.palletCount}
        typeError={error === 'palletCount' && ErrorText.field}
        label={'Pallet Count'}
        type={'number'}
        variant={'outlined'}
        name={'palletCount'}
      />
      <ValidationInput
        className={inputs.qtyPerPallet && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.qtyPerPallet}
        typeError={error === 'qtyPerPallet' && ErrorText.field}
        label={'Qty Per Pallet'}
        type={'number'}
        variant={'outlined'}
        name={'qtyPerPallet'}
      />
      <ValidationInput
        className={inputs.caseQty && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.caseQty}
        typeError={error === 'caseQty' && ErrorText.field}
        label={'Case Qty'}
        type={'number'}
        variant={'outlined'}
        name={'caseQty'}
      />
      <ValidationInput
        className={inputs.weight && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.weight}
        typeError={error === 'weight' && ErrorText.field}
        label={'Weight'}
        type={'number'}
        variant={'outlined'}
        name={'weight'}
      />
      <ValidationInput
        className={inputs.dimensions && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.dimensions}
        typeError={error === 'dimensions' && ErrorText.field}
        label={'Dimensions'}
        type={'text'}
        variant={'outlined'}
        name={'dimensions'}
      />
      <ValidationInput
        className={inputs.commodity && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.commodity}
        typeError={error === 'commodity' && ErrorText.field}
        label={'Commodity'}
        type={'text'}
        variant={'outlined'}
        name={'commodity'}
      />
      <ValidationInput
        className={inputs.classInfo && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.classInfo}
        typeError={error === 'classInfo' && ErrorText.field}
        label={'Class'}
        type={'text'}
        variant={'outlined'}
        name={'classInfo'}
      />
      <ValidationInput
        className={inputs.awbAblNumber && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.awbAblNumber}
        typeError={error === 'awbAblNumber' && ErrorText.field}
        label={'awb/abl number'}
        type={'text'}
        variant={'outlined'}
        name={'awbAblNumber'}
      />
      <ValidationInput
        className={inputs.memo && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.memo}
        typeError={error === 'memo' && ErrorText.field}
        label={'Memo'}
        type={'text'}
        variant={'outlined'}
        name={'memo'}
      />

      <CreateChancel
        loader={!!loader.length}
        classes={globalStyles.buttonsStyle}
        create={'Create'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={close}
      />
    </div>
  );
};