import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddButton, MultipleSelect } from 'components';
import { PermissionsList, RolePermission, SaveParams } from 'utils';
import { CarrierCsvFile } from '../../../components/buttons/carrierCsvFile';
import { vendorStatusesEnum, vendorTypes } from './constants';
import { carrierStatuses, carrierStatusesNoPending, vendorEnums, VendorTables } from '../constants';

export const VendorTypesAndFilters = ({}) => {
  const history = useHistory();
  const info = history?.location?.state;
  const currentTab = VendorTables(info?.vendorType);
  const selectType = (item, name) => {
    const params = {
      ...info,
    };
    params[name] = item;
    SaveParams(history, params);
  };

  const renderActive = (item) => {
    if (item?.id === info?.vendorType) {
      return 'selected';
    } else if (!info?.vendorType && item?.id === vendorEnums?.CARRIER) {
      return 'selected';
    } else {
      return 'not-selected';
    }
  };
  const pushPageDetails = () => {
    history.push({
      pathname: `/create-vendor`,
      state: { vendorType: currentTab?.type },
    });
  };

  return (
    <div className="vendor-types-filters-wrapper">
      <div className="vendor-types-wrapper">
        <div className="vendor-types-box">
          {vendorTypes.map((item, index) => (
            <button
              onClick={() => selectType(item?.id, 'vendorType')}
              className={`vendor-type-btn ${renderActive(item)}`}
              key={index}
            >
              {item?.name}
            </button>
          ))}
        </div>
      </div>
      <div className="vendor-action-box">
        <div className="space-between">
          <MultipleSelect
            selectedList={
              info?.statuses ? info?.statuses :
                currentTab?.type === vendorEnums?.CARRIER ?
                  [vendorStatusesEnum[0], vendorStatusesEnum[2]]
                  :
                  []
            }
            handleChange={(value) => selectType(value, 'statuses')}
            permissionsList={currentTab?.type === vendorEnums?.CARRIER ? carrierStatuses : carrierStatusesNoPending}
            label={'Select Status'}
            renderName={(item) => item?.name}
            smallSize={true}
          />
          <div>
            <div className="align-center" style={{ gap: '24px', alignItems: 'center' }}>
              {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_CARRIER_CSV?.code]) && currentTab?.vendorCsv &&
                <CarrierCsvFile params={currentTab?.vendorCsv} />
              }
              {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                <AddButton handleClick={pushPageDetails}
                           href={'/create-vendor'} text={`Add ${currentTab?.title}`} />
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};