import React from 'react';
import {useHistory} from "react-router-dom";
import {breadcrumbsStyle} from "./styles";
import {Breadcrumbs, Typography, Link} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const CustomBreadcrumbs = ({parent, parentLink, child, third, thirdLink, pushParams, customParams}) => {
    const classes = breadcrumbsStyle();
    const history = useHistory()
    const info = history?.location?.state

    const handleClick = (event, type) => {
        event.preventDefault();
        history.push({
            pathname: type === 'third' ? thirdLink : parentLink,
            state: pushParams ? {...info, ...customParams}  : {...customParams}
        })
    }

    return (
        <div>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                <Link className={classes.parent} href={parentLink} onClick={handleClick}>
                    {parent}
                </Link>
                {third ?
                    <Link className={classes.parent} href={thirdLink} onClick={(event) => handleClick(event, 'third')}>
                        {third}
                    </Link> : ''
                }
                <Typography className={classes.child}>{child}</Typography>
            </Breadcrumbs>
        </div>
    );
}