import React from "react";
import {DateRow, HtmlTooltip, MinLoader, PhoneRow, PriceRow, TextRow, TypeRow} from "components";
import {Colors, Images, RenderEquipmentType, userType} from "utils";
import {TruckModal} from "./modals";

export const truckBoardHead = [
    userType === 'AGENT' && {name: '', title: '', notSearch: true, custom: false, width: '0',},
    {name: 'carrierName', title: 'Vendor Name', searchKey: 'CARRIER', smallSize: 8, custom: false},
    {name: 'availableDate', title: 'Avail. date', smallSize: 8, width: '90px', icon:'date' },
    {name: 'equipmentType', title: 'Type', width: '80px', custom: false,
        // filterList: [
        //     {name: 'All', id: ''},
        //     {name: 'Dry Van', id: 'DRYVAN'},
        //     {name: 'Reefer', id: 'REEFER'},
        //     {name: 'Flatbed', id: 'FLATBED'},
        //     {name: 'Power Only', id: 'POWER_ONLY'},
        //     {name: 'Drayage', id: 'DRAYAGE'},
        //     {name: 'Box Truck', id: 'BOX_TRUCK'},
        //     {name: 'Sprinter Van', id: 'SPRINTER_VAN'},
        //     {name: 'Warehouse', id: 'WAREHOUSE'},
        // ]
    },
    {name: 'trailerLength', title: 'Length', width: '50px', custom: false},
    {name: 'originCity', title: 'Origin City', smallSize: 6, custom: false,},
    {name: '', title: 'Destinations', smallSize: 6, custom: false, disable:true},
    {name: 'serviceType', title: 'Service',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Solo', id: 'SOLO'},
            {name: 'Team', id: 'TEAM'}
        ], width: '80px'
    },
    {name: 'carrierPhone', title: 'Phone Number', custom: false, bigSize: 8, smallSize: 5},
    {name: '', title: 'Reviews', custom: false, width: '90px', smallSize: 5},
    {name: '', title: 'Alerts', custom: false, width: '50px'},
    userType === 'ADMIN' && {name: '', title: 'Action', custom: false, width: '50px'},
]

// const renderType = (type) => {
//     if (type === 'FREIGHT_GUARD') {
//         return 'Freight guard'
//     } else if (type === 'OFTEN_LATE') {
//         return 'Often late'
//     } else if (type === 'WARNING') {
//         return 'Warning'
//     } else if (type === 'OTHER') {
//         return 'Other'
//     } else {
//         return '...'
//     }
// }

export const truckBoardBody = (open, equipmentList, myProfile) => {
    const uniqSet = new Set();
    if (equipmentList?.items?.length) {
        equipmentList?.items?.map((i) => uniqSet.add(i?.carrierId))
    }

    return [
        userType === 'AGENT' && {
            button: (ev, item, load) => {
                const check = myProfile?.favoriteCarriers?.includes(item?.carrierId)
                return (
                    <button
                        onClick={() => ev(check ? 'unset' : 'set')}
                        className='favorite-btn'>
                        {load === true ?
                            <MinLoader style={{margin: 0}} position={'relative'} color={Colors.ThemeBlue}/>
                            :
                            <img src={check ? Images.favorite : Images.notFavorite} alt='icon'/>
                        }
                    </button>
                )

            }, notClickable: true
        },
        // {rowText: (item) => <TextRow name={item?.carrierDisplayId} textWidth={12}/>},
        {rowText: (item) => <TextRow name={item?.carrierName}/>},
        {rowText: (item) => <DateRow date={item?.availableDate}/>},
        {
            rowText: (item) => <TypeRow text={RenderEquipmentType(item?.equipmentType)}/>
        },
        {rowText: (item) => <TextRow name={item?.trailerLength ? `${item?.trailerLength}\` ` : '...'} textWidth={12}/>},
        {
            rowText: (item) => <TextRow
                name={ item?.originCity || item?.originState ?
                    `${item?.originCity ? `${item?.originCity} ${item?.originCity && item?.originState ? ',' : ''}` : ''}  ${item?.originState ? item?.originState : ''}`
             : 'Not Set'
            }
                textWidth={10}
            />
        },

        {
            rowText: (item) => {
                return item?.returnToHomebase? (
                    <TextRow name={
                        item?.homebaseCity && item?.homebaseState ? `${item?.homebaseCity}, ${item?.homebaseState}` :
                          item?.homebaseCity ? `${item?.homebaseCity}` :
                           item?.homebaseState ? `${item?.homebaseState}` :
                             'N/A'}
                             textWidth={10}
                    />
                    )
                    :
                    <HtmlTooltip
                        title={
                            <div>
                                {!!item?.regions?.length && item?.regions?.map((i, j) => (
                                    <p key={j}>{i}</p>
                                ))}
                            </div>
                        }
                        placement="top-start"
                    >
                        <p style={{textOverflow: 'ellipsis',}}>
                            {
                                item?.regions?.length > 0 ?
                                    item?.regions?.length >= 4 ? `${item?.regions[0]}, ${item?.regions[1]},  ${item?.regions[2]},  ${item?.regions[3]}...` :
                                        item?.regions?.length === 1 ? item?.regions[0] :
                                            item?.regions?.length >= 2 ? `${item?.regions[0]}, ${item?.regions[1]}...` :
                                                item?.regions[0] :
                                    'Not Set'
                            }
                        </p>
                    </HtmlTooltip>

            }
        },
        {rowText: (item) => <TypeRow text={item?.serviceType} textWidth={10}/>},
        {rowText: (item) => <PhoneRow phone={item?.carrierPhone} textWidth={10}/>},
        {
            rowText: (item) => <div className='align-center'>
                <img src={Images.star} alt=""/>
                <p>{returnStars(item, open)}</p>
            </div>
        },
        {
            rowText: (item) => <div className='align-justify-center'>
                {item?.alerts?.length ?
                    <HtmlTooltip
                        title={<div>
                            {item?.alerts?.map((i, j) => (
                                <p key={j}>{i}</p>
                            ))}
                        </div>}
                        placement="top-start"
                    >
                        <div className='alert-red'>
                            <p>{item?.alerts?.length}</p>
                        </div>
                    </HtmlTooltip>
                    :
                    <div className='alert-green'>
                        <p>0</p>
                    </div>
                }
            </div>
        },
        userType === 'ADMIN' && {
            button: (item) =>
                <div className='align-justify-center'>
                    <button onClick={() => item('edit')} className='align-justify-center button-transparent'>
                        <img src={Images.edit} alt="icon"/>
                    </button>
                </div>, notClickable: true
        },
    ]
}


const returnStars = (item, open) => {
    const checkStarsArray = Array.isArray(item?.reviews)
    const firstStar = checkStarsArray ? item?.reviews[0] : 0
    const secondStar = checkStarsArray ? item?.reviews[1] : 0
    const thirdStar = checkStarsArray ? item?.reviews[2] : 0
    const fourthStar = checkStarsArray ? item?.reviews[3] : 0
    const fifthStar = checkStarsArray ? item?.reviews[4] : 0
    const starCount = firstStar + secondStar + thirdStar + fourthStar + fifthStar
    const count = (firstStar * 1) + (secondStar * 2) + (thirdStar * 3) + (fourthStar * 4) + (fifthStar * 5)
    const star = count / starCount

    if (item?.reviews?.length) {
        return (
            <button className='table-review-btn' onClick={() => {
                open(<TruckModal item={item}/>)
            }}>
                {`${isNaN(star) ? 0 : star.toFixed(1)} / ${starCount}`}
            </button>
        )
    } else {
        return 0
    }

}

export const ACTION_TYPE = 'GET_EQUIPMENT'


export const lastLoadsHead = [
    {name: '', title: 'Pick Up Date', custom: false,},
    {name: '', title: 'Pick Up State', custom: false,},
    {name: '', title: 'Drop Up Date', custom: false,},
    {name: '', title: 'Drop Up State', custom: false,},
    {name: '', title: 'Vendor Rate', custom: false,},
]

export const lastLoadsBody = [
    {rowText: (item) => <DateRow date={item?.pickupDate}/>},
    {rowText: (item) => <TextRow name={item?.pickupState}/>},
    {rowText: (item) => <DateRow date={item?.dropoffDate}/>},
    {rowText: (item) => <TextRow name={item?.dropoffState}/>},
    {rowText: (item) => <PriceRow info={+item?.carrierRate}/>},
]

export const LAST_LOADS = 'GET_CARRIER_LAST_LOAD'


export const alertsHead = [
    {name: '', title: 'Date', custom: false,},
    {name: '', title: 'Type', custom: false,},
    {name: '', title: 'Creator Name', custom: false,},
    {name: '', title: 'Description', custom: false,},
    {name: '', title: 'Action', custom: false,},
]

export const alertsBody = [
    {rowText: (item) => <DateRow date={item?.pickupDate}/>},
    {rowText: (item) => <TextRow name={item?.pickupState}/>},
    {rowText: (item) => <DateRow date={item?.dropoffDate}/>},
    {rowText: (item) => <TextRow name={item?.dropoffState}/>},
    {
        rowText: (item) => <div className='align-center action-buttons'>
            <button><img src={Images.edit} alt='icon'/></button>
            <button><img src={Images.removeRed} alt='icon'/></button>
        </div>
    },
]

export const ALERTS_ACTION = 'GET_ALERTS'