import {
    CLEAN_AP_DETAILED,
    CLEAN_AP_SUMMARY,
    CLEAN_AR_DETAILED, CLEAN_AR_SUMMARY,
    GET_AP_ACTIVITY,
    GET_AP_APPLICATION,
    GET_AP_DETAILED,
    GET_AP_SUMMARY,
    GET_AR_ACTIVITY,
    GET_AR_APPLICATION,
    GET_AR_DETAILED,
    GET_AR_SUMMARY, GET_REPORTS,
} from './reports.types';

/** Ar Reports */
export const getArDetailReport = ( params, type ) => {
    return {
        type: GET_AR_DETAILED,
        payload: { params, type }
    }
}

export const cleanArDetailReport = ( ) => {
    return {
        type: CLEAN_AR_DETAILED,
    }
}

export const getArSummaryReport = ( params, type ) => {
    return {
        type: GET_AR_SUMMARY,
        payload: { params, type }
    }
}

export const cleanArSummaryReport = ( ) => {
    return {
        type: CLEAN_AR_SUMMARY,
    }
}

export const getArActivityReport = () => {
    return {
        type: GET_AR_ACTIVITY,
    }
}

export const getArApplication = ( param ) => {
    return {
        type: GET_AR_APPLICATION,
        payload: { param }
    }
}
/** End */

/** Ap Reports */
export const getApDetailReport = ( params, type ) => {
    return {
        type: GET_AP_DETAILED,
        payload: { params, type }
    }
}

export const cleanApDetailReport = ( ) => {
    return {
        type: CLEAN_AP_DETAILED,
    }
}

export const getApSummaryReport = ( params, type ) => {
    return {
        type: GET_AP_SUMMARY,
        payload: { params, type }
    }
}

export const cleanApSummaryReport = ( ) => {
    return {
        type: CLEAN_AP_SUMMARY,
    }
}

export const getApActivityReport = () => {
    return {
        type: GET_AP_ACTIVITY,
    }
}

export const getApApplication = ( params ) => {
    return {
        type: GET_AP_APPLICATION,
        payload: { params }
    }
}
/** End */


export const getReports = ( params ) => {
    return {
        type: GET_REPORTS,
        payload: { params }
    }
}