import { DateRow, TextRow, TypeRow } from 'components';

export const CUSTOMER_LOAD_ACTION_TYPE = 'GET_CUSTOMER_LOADS';


//   "createdDate": "2024-12-18T03:41:59",
//   "fromName": "FromName 3",
//   "fromAddress": "Random address",
//   "pickupDate": "2024-01-03T00:00:00",
//   "toName": "ToName 3",
//   "memo": "123 qdad",
//   "orderNumber": "111",
//   "caseQty": 123,
//   "palletCount": 22

export const customerLoadsHead = [
  { name: 'customerReference', searchKey: 'REFERENCE', title: 'Customer Reference', custom: false },
  { name: 'fromName', searchKey: 'FROM_NAME', title: 'From Name', custom: false },
  { name: 'fromAddress', title: 'From Address', custom: false },
  { name: '', title: 'Pickup Date', custom: false },
  { name: '', title: 'Memo', custom: false },
  { name: '', title: 'Item', custom: false },
  { name: '', title: 'Cases', custom: false },
  { name: '', title: 'Pallets', custom: false },
  {
    name: 'status', title: 'Status',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Pending', id: 'PENDING' },
      { name: 'Delivered', id: 'DELIVERED' },
      { name: 'In Transit', id: 'INTRANSIT' },
      { name: 'Cancelled', id: 'CANCELLED' },
    ],
  },
];
export const customerLoadsBody = [
  { rowText: (item) => <TextRow name={item?.customerReference}  /> },
  { rowText: (item) => <TextRow name={item?.fromName}  /> },
  { rowText: (item) => <TextRow name={item?.fromAddress}  /> },
  { rowText: (item) => <DateRow date={item?.pickupDate} name={item?.fromAddress} /> },
  { rowText: (item) => <TextRow name={item?.memo}  /> },
  { rowText: (item) => <TextRow name={item?.orderNumber}  /> },
  { rowText: (item) => <TextRow name={item?.caseQty}  /> },
  { rowText: (item) => <TextRow name={item?.palletCount}  /> },


  {
    rowText: (item) => <p
      className={
        item?.status === 'PENDING' ? 'pending-icon-color' :
          item?.status === 'INTRANSIT' ? 'inTransit-icon-color' :
            item?.status === 'REJECTED' ? 'rejected-icon-color' :
              (item?.status === 'COMPLETED' || item?.status === 'PROCESSING') ? 'completed-icon-color' :
                ''}
    >
      {
        item?.status === 'INTRANSIT' ? 'In Transit' :
          item?.status === 'CREDITCHECK' ? 'Credit Check' :
            item?.status === 'CONSIGNMENT_REQUEST' ? 'Consignment Request' :
              <TypeRow
                text={(item?.status === 'PROCESSING' || item?.status === 'COMPLETED') ? 'DELIVERED' : item?.status} />
      }
    </p>,
  },
];