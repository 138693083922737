import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Colors, ErrorText, FindError, SavePage, useGlobalStyles, useGlobalTextStyles, useModal } from 'utils';
import {
  ErrMessage,
  ErrWarn,
  PriceConvertor,
  SelectTransaction,
  ValidationInput,
} from 'components';
import { Button } from '@material-ui/core';
import { MinLoader } from 'components';
import { httpRequestsOnErrorsActions, loadActions } from 'store';
import { authService } from '../../../../store/loads/loads.service';
import { EFS, FUEL, OFFICE_CHARGE, PREPAY_FEE, PROCESSING_REVERSAL, QUICKPAY, transactionType } from './constants';
import { customerRate } from '../styles';

export const AddTransactionModal = ({ info, isFactored }) => {
  const globalText = useGlobalTextStyles();
  const globalStyles = useGlobalStyles();
  const classes = customerRate();
  const dispatch = useDispatch();
  const userType = localStorage.getItem('userType');
  const [inputs, setInputs] = useState({
    qty: 1,
  });
  const [error, setError] = useState('');
  const [backError, setBackError] = useState('');
  const [loader, setLoader] = useState(false);
  const err = FindError('NEGATIVE_TRANSACTION_FAIL');
  const [warnErr, setWarnErr] = useState('');
  const history = useHistory();
  const pushInfo = history?.location?.state;
  const { close } = useModal();
  const negative =
    +info?.payable?.totalRate - +info?.payable?.totalPaid
    - info?.payable?.charges + (inputs.carrierRate ? +inputs.carrierRate : 0);
  const checkAmountInput = inputs?.type === EFS || inputs?.type === OFFICE_CHARGE ||
    inputs?.type === FUEL || inputs?.type === PROCESSING_REVERSAL || inputs?.type === QUICKPAY || inputs?.type === PREPAY_FEE;

  useEffect(() => {
    dispatch(httpRequestsOnErrorsActions.removeError('NEGATIVE_TRANSACTION_FAIL'));
  }, []);

  useEffect(() => {
    if (inputs.carrierRate) {
      if (negative < 0) {
        setWarnErr(userType === 'AGENT' ? 'err' : 'warn');
      }
    }
  }, [inputs]);

  useEffect(() => {
    return () => setWarnErr('');
  }, []);

  const handleSelectType = (e) => {
    setInputs({ qty: 1, type: e.target.value });
    error === 'type' && setError('');
  };

  const handleChange = e => {
    if (inputs?.type === FUEL) {
      if ((e.target.value === '' || e.target.value > 0)
        // && (e.target.value <= info?.payable?.totalRate - info?.payable?.totalPaid) / 2
      ) {
        setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      }
      if (e.target.name === 'description') {
        setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      }
    } else {
      if (e.target.name === 'qty') {
        if (+e.target.value >= 0) {
          setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
      } else {
        setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      }
    }
    backError && setBackError('');
    error === e.target.name && setError('');
    error === 'oneOf' && setError('');
    e.target.name === 'carrierRate' && setWarnErr('');
  };

  const handleCreate = async () => {
    const checkRate =
      isFactored ? !!inputs.customerRate :
      inputs.carrierRate ? true : !!inputs.customerRate;


    if (checkAmountInput ? (inputs.type && inputs?.amount) : (inputs.type && checkRate)) {

      const date = {
        ...inputs,
        'load': info?.id,
        qty: inputs?.qty && inputs?.qty !== '0' ? +inputs?.qty : 1,
      };

      if(isFactored){
        date?.customerRate ? date.carrierRate = +inputs.customerRate : date.carrierRate = 0;
        date?.customerRate ? date.customerRate = +inputs.customerRate : date.customerRate = 0;
      }else {
        date?.carrierRate ? date.carrierRate = +inputs.carrierRate : date.carrierRate = 0;
        date?.customerRate ? date.customerRate = +inputs.customerRate : date.customerRate = 0;
      }

      if (inputs.type === FUEL || inputs.type === EFS || inputs.type === PROCESSING_REVERSAL || inputs.type === QUICKPAY) {
        date.carrierRate = inputs?.amount;
        delete date.customerRate;
        delete date.amount;
      }

      if (inputs.type === OFFICE_CHARGE || inputs.type === PREPAY_FEE){
        date.customerRate = inputs?.amount;
        delete date.carrierRate;
        delete date.amount;
      }
      setLoader(true);
      try {
        await authService.createLoadTransactionsService(info?.id, date);
        dispatch(loadActions.getLoadById(info?.id, 'noLoad'));
        SavePage(history, pushInfo, { ...pushInfo });
        close();
        setWarnErr('');
        setLoader(false);
      } catch (e) {
        setBackError(e?.data?.message);
        setLoader(false);
      }
    } else {
      checkAmountInput ?
        setError(
          !inputs.type ? 'type' :
            !inputs.amount ? 'amount' :
              '',
        )
        :
        setError(
          !inputs.type ? 'type' :
            checkRate === false ? 'oneOf' : '',
        );
    }
  };

  return (
    <div className={classes.modalWidth}>
      <p className={globalText.modalTitle}>Add Transaction</p>
      <p className={globalText.modalText}>To add transaction please, fulfil the below fields.</p>

      <SelectTransaction
        style={globalStyles.simpleInput}
        className={inputs.type && globalStyles.inputTextFieldBlue}
        name={'type'}
        label={'name'}
        title={'Transaction Type*'}
        handleSelect={handleSelectType}
        defaultValue={inputs.type}
        list={transactionType}
        typeError={error === 'type' ? ErrorText.field : ''}
        error={error === 'type' ? 'type' : ''}
      />
      {(inputs.type === PREPAY_FEE ? true : !checkAmountInput) &&
        <ValidationInput
          className={inputs?.qty && globalStyles.inputTextFieldBlue}
          style={classes.quantity}
          onChange={handleChange}
          value={inputs?.qty === '0' ? 1 : inputs?.qty}
          typeError={error === 'qty' && ErrorText.field}
          label={'Quantity*'}
          type={'number'}
          variant={'outlined'}
          name={'qty'}
        />
      }

      {checkAmountInput ?
        <div style={{ marginTop: '25px' }} className={classes.sectionTop}>
          <ValidationInput
            className={inputs.amount && globalStyles.inputTextFieldBlue}
            onChange={handleChange}
            value={inputs.amount}
            typeError={error === 'amount' && ErrorText.field}
            label={'Amount*'}
            type={'number'}
            variant={'outlined'}
            name={'amount'}
            style={classes.simpleInput}
          />

          <ValidationInput
            className={inputs.description ? classes.inputDescriptionBlue : classes.inputDescription}
            onChange={handleChange}
            typeError={error === 'description' ? ' ' : ''}
            variant={'outlined'}
            name={'description'}
            Length={200}
            value={inputs.description}
            label={'Description here...'}
            type={'text'}
            multiline={true}
          />
          <div className={globalStyles.flexEnd}>
            <p
              className={globalStyles.maxCharacter}>{`Max ${inputs.description ? inputs.description.length : 0}/200 characters`}</p>
          </div>


          {backError &&
            <ErrMessage
              style={{ position: 'relative' }}
              text={backError}
            />
          }
          {/*{inputs?.type === FUEL &&*/}
          {/*    <p style={{marginTop: '10px'}}>Available Balance <span*/}
          {/*        style={{fontWeight: 'bold'}}>${(info?.payable?.totalRate - info?.payable?.totalPaid - info?.payable?.charges) / 2 }</span>*/}
          {/*    </p>*/}
          {/*}*/}
        </div>

        :

        <div className={classes.sectionTop}>
          <div className={classes.ratesWrapper}>
            <div style={{ display: 'flex' }}>
              {inputs?.type !== 'QUICKPAY_REVERSAL' &&
                <ValidationInput
                  className={inputs.customerRate && globalStyles.inputTextFieldBlue}
                  onChange={handleChange}
                  value={inputs.customerRate}
                  typeError={error === 'customerRate' ? ErrorText.field :
                    error === 'oneOf'
                  }
                  label={'Customer Rate'}
                  type={'number'}
                  variant={'outlined'}
                  name={'customerRate'}
                  style={isFactored ? classes.simpleInputNoRight : classes.simpleInput}
                />
              }
              {!isFactored &&
                <ValidationInput
                  className={inputs.carrierRate && globalStyles.inputTextFieldBlue}
                  onChange={handleChange}
                  value={inputs.carrierRate}
                  typeError={error === 'carrierRate' ? ErrorText.field :
                    error === 'oneOf'
                  }
                  label={'Vendor Rate'}
                  type={'number'}
                  variant={'outlined'}
                  name={'carrierRate'}
                  style={classes.simpleInputNoRight}
                />
              }
            </div>
            <ErrMessage
              style={{ position: 'relative', marginTop: '-10px' }}
              text={
                error === 'oneOf' ? 'One Of Inputs must be field' :
                  err.length ? 'You can\'t  create negative transaction' :
                    backError
              }
            />
            <div style={{ margin: '8px 0 12px' }} className={globalStyles.columnAble}>
              <div className={classes.flexAbleColumn}>
                <div className={classes.transactionItems}>
                  <p>{isFactored ? 'Total:' : 'Customer Total:'} </p>
                  <span>
                                        <PriceConvertor
                                          price={inputs?.qty && inputs?.customerRate ? (inputs?.qty * inputs?.customerRate) : 0} />
                                        </span>
                </div>

                {!isFactored &&
                  <div style={{ marginRight: 0 }} className={classes.transactionItems}>
                    <p>Vendor Total: </p>
                    <span>
                                        <PriceConvertor
                                          price={inputs?.qty && inputs?.carrierRate ? (inputs?.qty * inputs?.carrierRate) : 0} />
                                        </span>
                  </div>
                }
              </div>

              {inputs?.type !== 'CORPORATE' && !isFactored &&
                <div style={{ margin: 0 }} className={classes.transactionItems}>
                  <p>Profit: </p>
                  <span>
                  <PriceConvertor
                    price={inputs?.qty * ((inputs.customerRate ? inputs.customerRate : 0) - (inputs.carrierRate ? inputs.carrierRate : 0))} />
                </span>
                </div>
              }
            </div>
          </div>
          <ValidationInput
            className={inputs.description ? classes.inputDescriptionBlue : classes.inputDescription}
            onChange={handleChange}
            typeError={error === 'description' ? ' ' : ''}
            variant={'outlined'}
            name={'description'}
            Length={200}
            value={inputs.description}
            label={'Description here...'}
            type={'text'}
            multiline={true}
          />
          <div className={globalStyles.flexEnd}>
            <p
              className={globalStyles.maxCharacter}>{`Max ${inputs.description ? inputs.description.length : 0}/200 characters`}</p>
          </div>
        </div>
      }

      {!checkAmountInput && warnErr &&
        <ErrWarn
          styles={{ marginTop: '16px' }}
          screenType={warnErr}
          text={
            warnErr === 'warn' ?
              'This transaction will make the carrier rate negative, but allow them to create the transaction.'
              :
              'This transaction will make the load carrier rate negative, please enter a different amount or contact an admin.'
          }
        />
      }
      <Button
        style={{ background: Colors.ThemeGreen }}
        disabled={negative < 0 && userType === 'AGENT'}
        onClick={handleCreate}
        className={classes.submit}
      >
        {loader === true ?
          <MinLoader color={'white'} />
          :
          'Submit'}
      </Button>
    </div>
  );
};