import { vendorEnums } from 'pages';


export const RenderFields = (type) => {

  switch (type) {
    case vendorEnums.CARRIER:
      return {

      };


    case vendorEnums.CO_BROKER:
      return {

      };


    case vendorEnums.WAREHOUSE:
      return {

      };

    case vendorEnums.CUSTOM_BROKER:
      return {

      };

    case vendorEnums.FREIGHT_FORWARDER:
      return {

      };



    case vendorEnums.INTERMODAL_FREIGHT_FORWARDER:
      return {

      };

    case vendorEnums.FACTORED:
      return {

      };


    case vendorEnums.VENDOR:
      return {

      };

    case vendorEnums.INHOUSE_PROFILE:
      return {

      };

    default:
      return {

      };
  }
};

export const createVendorRequiredFields = (inputs, type) => {
  if(type === vendorEnums.INHOUSE_PROFILE || type === vendorEnums.VENDOR) {
    return !!(inputs.firstName && inputs.lastName && inputs.email && inputs.companyName);
  }else if(type === vendorEnums.FACTORED){
    return !!(inputs.firstName && inputs.lastName && inputs.email && inputs.companyName && inputs.docketType && inputs.docketNumber && inputs?.loadMC)
  }else if(type === vendorEnums.CUSTOM_BROKER){
    return !!(inputs.firstName && inputs.lastName && inputs.email && inputs.companyName && inputs.docketNumber)
  }else{
    return !!(inputs.firstName && inputs.lastName && inputs.email && inputs.companyName && inputs.docketType && inputs.docketNumber);
  }
}

export const typeEnums = [
  {name: 'Mc Number', id: 'MC_NUMBER'},
  // {name: 'Doc Number', id: 'DOT_NUMBER'},
  {name: 'Ca Number', id: 'CA_NUMBER'},

  {name: 'USDOT', id: 'USDOT'},

  {name: 'MC_B_NUMBER', id: 'MC_B_NUMBER'},
  {name: 'NVOCC', id: 'NVOCC'},
  {name: 'VOCC', id: 'VOCC'},
  {name: 'OTI', id: 'OTI'},
  {name: 'IATA', id: 'IATA'},
  {name: 'FF', id: 'FF'},
]

export const createFlowWithoutDocket = (type) => {
  if(type === vendorEnums.INHOUSE_PROFILE || type === vendorEnums.VENDOR) {
    return true
  }else{
    return false
  }
}

export const createPageTitle = (type) =>{
  switch (type) {
    case vendorEnums.CARRIER:
      return {
        title: 'CARRIER NAME',
        pageTitle: 'Carrier Information',
      }

    case vendorEnums.CO_BROKER:
      return  {
        title: 'CO-BROKER NAME',
        pageTitle: 'Co-Broker Information',
      }

    case vendorEnums.WAREHOUSE:
      return  {
        title: 'WAREHOUSE NAME',
        pageTitle: 'Warehouse Information',
      }

    case vendorEnums.CUSTOM_BROKER:
      return  {
        title: 'CUSTOM-BROKER NAME',
        pageTitle: 'Custom Broker Information',
      }

    case vendorEnums.FREIGHT_FORWARDER:
      return  {
        title: 'FREIGHT FORWARDER NAME',
        pageTitle: 'Freight Forwarder Information',
      }

    case vendorEnums.INTERMODAL_FREIGHT_FORWARDER:
      return {
        title: 'INT.FREIGHT NAME',
        pageTitle: 'Int. Freight Information',
      }

    case vendorEnums.FACTORED:
      return {
        title: 'FACTORED VENDOR NAME',
        pageTitle: 'Factored Vendor Information',
      }

    case vendorEnums.VENDOR:
      return {
        title: 'VENDOR NAME',
        pageTitle: 'Vendor Information',
      }

    case vendorEnums.INHOUSE_PROFILE:
      return {
        title: 'IN HOUSE PROFILE',
        pageTitle: 'Inhouse Profile Information',
      }

    default: return {
      title: ' ',
      pageTitle: ' ',
    }
  }
};