import React, { useEffect, Fragment } from 'react';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MenuBar } from 'fragments';
import { LandingPage, LoginPage, TermsPage, PrivacyPage, MobileApp, CustomerLoads } from 'pages';

export const RouterSwitcher = ({}) => {
  const { accessToken } = useSelector((state) => ({
    accessToken: state.auth.accessToken,
  }));
  const token = accessToken ? accessToken : localStorage.getItem('access-token');
  const link = window.location.search;
  const history = useHistory();
  const info = history?.location;
  const checkIfMobileReset = link.search('resetCarrierToken=') !== -1;

  const checkRouting = !token ||
    info?.pathname === '/goApp' ||
    link.search('resetCarrierToken=') !== -1 ||
    link.search('resetToken=') !== -1 ||
    link.search('token=') !== -1;
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: '/customer/:id/loads',
    exact: true,
    strict: false,
  });
  const checkCustomerLoad = !!match;

  useEffect(() => {
    if (link.search('token=') !== -1 || link.search('resetToken=') !== -1 || link.search('resetCarrierToken=') !== -1) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('poloUserInfo');
      localStorage.removeItem('userType');
      localStorage.removeItem('permissions');
      localStorage.removeItem('searches');
    }
    if (info?.pathname === '/goApp' || link.search('resetCarrierToken=') !== -1) {
      const userAgent = navigator.userAgent;
      if (/Android/i.test(userAgent)) {
        open('https://play.google.com/store/apps/details?id=com.polotms.carrierapp');
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        open('https://apps.apple.com/us/app/polotms/id6478936123');
      }
    }
  }, []);

  return (
    <Fragment>
      {checkCustomerLoad ?
        <Switch>
          <Route path={'/customer/:id/loads'} exact component={CustomerLoads} />
        </Switch>
        :
        checkRouting ? (
          <Switch>
            <Route path={'/'} exact component={LandingPage} />
            <Route path={'/terms'} component={TermsPage} />
            <Route path={'/privacy'} component={PrivacyPage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/resetPassword/:resetToken?" exact component={checkIfMobileReset ? MobileApp : LoginPage} />
            <Route path="/resetPassword/:resetCarrierToken?" exact component={MobileApp} />
            <Route path="/register/:token?" exact component={LoginPage} />
            <Route path="/goApp" exact component={MobileApp} />
            <Redirect to={'/login'} />
          </Switch>
        ) : (
          <MenuBar />
        )}
    </Fragment>
  );
};
