export class LoadService {
    static getPayableBalance(payable){
        if(!payable) return 0
        const totalRate = payable?.totalRate ? Number(payable?.totalRate?.toFixed(2)) : 0;
        const totalPaid = payable?.totalPaid ? Number(payable?.totalPaid?.toFixed(2)) : 0 ;
        const charges =  payable?.charges ? Number(payable?.charges?.toFixed(2)) : 0
        const corporateCharges = payable?.corporateCharges ? Number(payable?.corporateCharges?.toFixed(2)) : 0;
        return totalRate - totalPaid - charges + corporateCharges
    }

    static getBillBalance(bill){
        if(!bill) return 0
        const totalRate = bill?.totalRate ? Number(bill?.totalRate?.toFixed(2)) : 0;
        const totalPaid = bill?.totalPaid ? Number(bill?.totalPaid?.toFixed(2)) : 0;
        const corporateCharges = bill?.corporateCharges ? Number(bill?.corporateCharges?.toFixed(2)) : 0;
        return totalRate - totalPaid + corporateCharges ;
    }
}