import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FindLoad,
  SavePage,
  useGlobalStyles,
  useGlobalTextStyles,
  useModal,
} from 'utils';
import { CreateChancel, ValidationInput } from 'components';
import {
  httpRequestsOnLoadActions,
  httpRequestsOnSuccessActions,
  loadActions,
} from 'store';
import { authService } from '../../../../store/loads/loads.service';
import { customerRate } from '../styles';

const ACTION_TYPE = 'EDIT_TRANSACTION_DESC';
export const EditTransactionDescription = ({ info }) => {
  const globalWrapper = useGlobalStyles();
  const globalText = useGlobalTextStyles();
  const globalStyles = useGlobalStyles();
  const classes = customerRate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState('');
  const { close } = useModal();
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const history = useHistory();
  const pushInfo = history?.location?.state;

  useEffect(() => {
    if (info?.description) {
      setInputs({ description: info?.description });
    }
  }, [info]);
  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
  };

  const handleEdit = async () => {
    if (inputs?.description) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      dispatch(httpRequestsOnLoadActions.appendLoading(ACTION_TYPE));
      try {
        await authService.editTransactionsDescService(info?.id, { description: inputs?.description });
        dispatch(loadActions.getLoadById(info?.load, 'noLoad'));
        close();
        dispatch(httpRequestsOnLoadActions.removeLoading(ACTION_TYPE));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(ACTION_TYPE));
        SavePage(history, pushInfo, { ...pushInfo });
      } catch (e) {
        close();
        dispatch(httpRequestsOnLoadActions.removeLoading(ACTION_TYPE));
        SavePage(history, pushInfo, { ...pushInfo });
      }
    } else {
      setError('description');
    }
  };

  return (
    <div style={{ width: '495px!important' }}>
      <p className={globalText.modalTitle}>Edit Transaction Description</p>
      <div className={classes.sectionTop}>
        <ValidationInput
          className={inputs.description ? classes.inputDescriptionBlue : classes.inputDescription}
          onChange={handleChange}
          typeError={error === 'description' ? ' ' : ''}
          variant={'outlined'}
          name={'description'}
          Length={200}
          value={inputs.description}
          label={'Description here...'}
          type={'text'}
          multiline={true}
        />
        <div className={globalStyles.flexEnd}>
          <p
            className={globalStyles.maxCharacter}>{`Max ${inputs.description ? inputs.description.length : 0}/200 characters`}</p>
        </div>
      </div>
      <CreateChancel
        loader={!!loader.length}
        classes={globalWrapper.buttonsStyle}
        create={'Save'}
        chancel={'Cancel'}
        onCreate={handleEdit}
        onClose={close}
      />
    </div>
  );
};