import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Colors,
  createInputsWrapper,
  ErrorText, officeManager,
  useGlobalStyles,
  useGlobalText, userInfo, userType,
} from 'utils';
import {
  Circle, CreateChancel, CreateWrapper,
  ErrMessage,
  SelectTypeAutocomplete,
} from 'components';
import { loadActions, LoadServices, mcActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { loadTabs } from './constants';

export const CreateLoadGroup = ({}) => {
  const { mcsList, loadById, assignedAgentMc, customers } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
    loadById: state.loads.loadById,
    customers: state.loads.customers,
    assignedAgentMc: state.mcs.assignedAgentMc,
  }));
  const mcsListReserve = userType === 'ADMIN' ? mcsList?.mcs : assignedAgentMc;
  const globalScreens = createInputsWrapper();
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [backError, setBackError] = useState('');
  const [loader, setLoader] = useState(false);
  const [disables, setDisabled] = useState(false);
  const [inputs, setInputs] = useState({});
  // const info = history?.location?.state;

  useEffect(() => {
    dispatch(loadActions.removeSearchedList());
    if (userType === 'ADMIN' && !loadById) {
      dispatch(mcActions.getMcs({ status: 'ACTIVE' }));
    }
    if (userInfo && userType !== 'ADMIN' || !officeManager) {
      dispatch(mcActions.getAgentAssignedMcs(userInfo?.id, 'AGENT'));
    }
  }, []);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    if (error === e.target.name) {
      setError('');
      setBackError(null);
    }
  };

  const handleCreate = async () => {
    // const checkRequiredMc = isFactored ? true : !!inputs.mc;
    if (inputs.mc && inputs.customer
      // && inputs.customerReference
    ) {
      const info = {
        'mc': inputs.mc,
        'customer': inputs.customer?.id ? inputs.customer.id : inputs.customer,
        // 'customerReference': inputs.customerReference,
        // 'contactName': inputs?.contactName,
      };
      setLoader(true);
      try {
        let res = await LoadServices.CreateGroup(info);
        history.push(`/load-group/${res.data.id}`);
        setLoader(false);
        setDisabled(true);
      } catch (e) {
        if (e?.data?.message === 'The MC is not assigned to the agent') {
          setError('notAssigned');
        } else {
          setBackError(e?.data?.message);
        }
        setLoader(false);
      }
    } else {
      // const errMessage = isFactored ?
      //   !inputs.customer ? 'customer' :
      //     !inputs.customerReference ? 'customerReference' : ''
      //   :
      //   !inputs.mc ? 'mc' :
      //     !inputs.customer ? 'customer' :
      //       !inputs.customerReference ? 'customerReference' : '';

      setError(
        !inputs.mc ? 'mc' :
          !inputs.customer ? 'customer' :
            '');
    }
  };

  const handle = (e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        dispatch(loadActions.searchList(e.target.value));
      }, 500);
    }
  };

  return (
    <CreateWrapper
      wrapperStyles={{ height: 'auto' }}
      noHead={true}
      body={
        <div>
          <div>
            <div className={globalStyles.centerItem}>
              <Circle number={1} back={Colors.ThemeAqua} />
              <p className={globalText.title}>Customer Request</p>
            </div>
          </div>
          <div className={globalStyles.flexAndFullWidth}>
            <div style={{ width: '100%', marginLeft: '48px' }}>
              <div className={globalScreens.basicInfoInputs} style={{ height: 73 }}>
                <SelectTypeAutocomplete
                  style={globalStyles.simpleInput}
                  name={'mc'}
                  label={'name'}
                  type={'mcNumber'}
                  title={'Booked As Authority*'}
                  handleSelect={handleChange}
                  defaultValue={inputs.mc}
                  list={mcsListReserve && mcsListReserve?.filter((i) => i?.authorityType !== 'DEFAULT') || []}
                  disabled={disables}
                  typeError={
                    error === 'mc' ? ErrorText.field :
                      error === 'notAssigned' ? 'The MC is not assigned to the agent' :
                        ''}
                  error={error === 'mc' && 'mc' || error === 'notAssigned' ? 'mc' : ''}
                />
                <SelectTypeAutocomplete
                  style={globalStyles.simpleInput}
                  loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
                  name={'customer'}
                  label={'name'}
                  type={'mc'}
                  title={'Customer (Name/MC)*'}
                  handleSelect={handleChange}
                  defaultValue={inputs?.customer?.id ? inputs.customer.id : inputs.customer}
                  list={customers?.customers?.length ? customers?.customers : []}
                  handleType={handle}
                  disabled={disables}
                  typeError={backError === 'Cannot use this customer due to auto deny' ? 'Cannot use this customer due to auto deny' : ''}
                  error={error === 'customer' ? 'customer' :
                    backError === 'Cannot use this customer due to auto deny' ? 'customer' : ''
                  }
                />
                {/*<ValidationInput*/}
                {/*  style={globalStyles.simpleInput}*/}
                {/*  variant={'outlined'}*/}
                {/*  name={'customerReference'}*/}
                {/*  label={'Customer Reference*'}*/}
                {/*  type={'text'}*/}
                {/*  typeError={error === 'customerReference' ? ErrorText.field : ''}*/}
                {/*  onChange={handleChange}*/}
                {/*  value={inputs.customerReference}*/}
                {/*  disabled={disables}*/}
                {/*/>*/}
                {/*<ValidationInput*/}
                {/*  className={inputs.customerRate && globalStyles.inputTextFieldBlue}*/}
                {/*  variant={'outlined'}*/}
                {/*  name={'contactName'}*/}
                {/*  label={'Customer Contact Name'}*/}
                {/*  type={'text'}*/}
                {/*  typeError={error === 'contactName' ? ErrorText.field : ''}*/}
                {/*  onChange={handleChange}*/}
                {/*  value={inputs.contactName}*/}
                {/*  disabled={disables}*/}
                {/*/>*/}
              </div>

              {backError && <ErrMessage text={backError} />}
              <CreateChancel
                loader={loader}
                disabled={loader}
                classes={globalStyles.buttonsStyle}
                create={'Create'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={() =>
                  history.push({
                    pathname: `/loads`,
                    state: { tabType: loadTabs?.LOAD_GROUPS },
                  })
                }
              />
            </div>
          </div>

        </div>
      }
    />
  );
};