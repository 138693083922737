import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './customers.service';
import {
  ACTIVATE_CUSTOMER,
  INACTIVATE_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  CREATE_CUSTOMER,
  EDIT_CUSTOMER,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_BY_ID_SUCCESS,
  ADD_CUSTOMER_BLACKLIST,
  REMOVE_CUSTOMER_BLACKLIST,
  CUSTOMER_BLACKLIST_SUCCESS,
  GET_CUSTOMER_DENY,
  GET_CUSTOMER_DENY_SUCCESS,
  ACTIVATE_DENY,
  INACTIVATE_DENY,
  GET_CUSTOMER_DEBT,
  GET_CUSTOMER_DEBT_SUCCESS,
  GET_CUSTOMER_AVAILABLE_DEBT_SUCCESS,
  ADD_CUSTOMER_DOCK,
  DELETE_CUSTOMER_DOCK,
  CUSTOMER_NON_FACTORING,
  CONSIGN_CUSTOMER,
  CUSTOMER_NO_POD,
  GET_CUSTOMERS_SEARCHED,
  EDIT_CUSTOMER_DOCK,
  GET_CUSTOMER_LOADS,
  GET_CUSTOMER_LOADS_SUCCESS, VERIFY_CUSTOMER,
} from './customers.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

/** Create, Edit Customer */

function* createCustomer({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.createCustomerService, payload.body);
    window.location.replace('/customers');
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

function* editCustomer({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editCustomerService, payload.id, payload.body);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

/** End */

/** Get Carriers */

function* getCustomer({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getCustomerService, payload?.params);
    yield put({
      type: GET_CUSTOMERS_SUCCESS,
      payload: res.data,
    });
    if (payload?.params?.searchKey) {
      yield put(httpRequestsOnLoadActions.removeLoading(payload?.params?.searchKey));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getCustomerSearched({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getCustomerSearchedService, payload?.params);
    yield put({
      type: GET_CUSTOMERS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getCustomerById({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getCustomerByIdService, payload.id);
    yield put({
      type: GET_CUSTOMER_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Activate or Inactivate Customer */

function* activateCustomer(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.activateCustomerService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

function* inactivateCustomer(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.inactivateCustomerService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

function* nonFactoringCustomer(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.nonFactoringCustomerService, action?.payload?.id, action?.payload?.nonFactoring);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

function* noPod(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.noPodService, action?.payload?.id, action?.payload?.noPod);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

/** End */

/**Customer Mc BlackList */

function* addMcToBlacklist(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.addMcToBlacklist, action.payload.id, action.payload.mcId, action.payload.message);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.customerId, load: 'noLoad' },
    });
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  }
}

function* removeMcFromBlacklist(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.removeMcFromBlacklistService, action.payload.id, action.payload.mcId);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.customerId, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {

    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.customerId, load: 'noLoad' },
    });
    // yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));

    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* removeMcFromBlacklistSuccess({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    yield call(authService.getCustomerByIdService, payload.id);
    // yield put({
    //   type: GET_CUSTOMER_BY_ID_SUCCESS,
    //   payload: res.data,
    // });

  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* getCustomerDebt({ payload, type }) {
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getCustomerDebtService, payload.id, payload.mcId);
    yield put({
      type: GET_CUSTOMER_DEBT_SUCCESS,
      payload: res.data,
    });
    if (!payload.mcId) {
      yield put({
        type: GET_CUSTOMER_AVAILABLE_DEBT_SUCCESS,
        payload: res.data.owedAmount,
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

/** AutoDeny  */
function* getDeny({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getCustomerDenyService, payload.id, payload.mcId);
    if (payload.mcId) {
      yield put({
        type: GET_CUSTOMER_DENY_SUCCESS,
        payload: res.data,
      });
    } else {
      yield put({
        type: GET_CUSTOMER_DENY_SUCCESS,
        payload: res.data.credit.customerDebt,
      });
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}


function* activateDeny(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  try {
    yield call(authService.activateDenyService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* inactivateDeny(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  try {
    yield call(authService.inactivateDenyService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

/** Customer Documents */

function* addCustomerDocument(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.addCustomerDocumentService, action.payload);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* deleteCustomerDocument(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.deleteCustomerDocumentService, action?.payload?.id, action?.payload?.dockId);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* editCustomerDocument(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.editCustomerDocumentService, action?.payload?.id, action?.payload?.dockId, action?.payload?.body);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

function* consignCustomer(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.consignCustomerService, action?.payload?.id, action?.payload?.type);
    yield put({
      type: GET_CUSTOMER_BY_ID,
      payload: { id: action.payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}


function* getCustomerLoads(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    const res = yield call(authService.getCustomerLoadsService, action?.payload?.params);
    yield put({
      type: GET_CUSTOMER_LOADS_SUCCESS,
      payload: res?.data,
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* verifyCustomer(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.verifyCustomerService, action?.payload?.id, action?.payload?.password);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
  }
}

export const watchCustomer = function* watchCustomerSaga() {
  /** Create, Edit Customer */
  yield takeLatest(CREATE_CUSTOMER, createCustomer);
  yield takeLatest(EDIT_CUSTOMER, editCustomer);
  /** End */

  /** Get Carriers */
  yield takeLatest(GET_CUSTOMERS, getCustomer);
  yield takeLatest(GET_CUSTOMERS_SEARCHED, getCustomerSearched);
  yield takeLatest(GET_CUSTOMER_BY_ID, getCustomerById);
  /** End */

  /** Activate or Inactivate Customer */
  yield takeLatest(ACTIVATE_CUSTOMER, activateCustomer);
  yield takeLatest(INACTIVATE_CUSTOMER, inactivateCustomer);
  yield takeLatest(CUSTOMER_NON_FACTORING, nonFactoringCustomer);
  yield takeLatest(CUSTOMER_NO_POD, noPod);
  /** End */

  /**Customer Mc BlackList */
  yield takeLatest(ADD_CUSTOMER_BLACKLIST, addMcToBlacklist);
  yield takeLatest(REMOVE_CUSTOMER_BLACKLIST, removeMcFromBlacklist);
  yield takeLatest(CUSTOMER_BLACKLIST_SUCCESS, removeMcFromBlacklistSuccess);
  yield takeLatest(GET_CUSTOMER_DEBT, getCustomerDebt);
  /** End */

  /** AutoDeny  */
  yield takeLatest(GET_CUSTOMER_DENY, getDeny);
  yield takeLatest(ACTIVATE_DENY, activateDeny);
  yield takeLatest(INACTIVATE_DENY, inactivateDeny);
  /** End */

  /** Customer Documents */
  yield takeLatest(ADD_CUSTOMER_DOCK, addCustomerDocument);
  yield takeLatest(DELETE_CUSTOMER_DOCK, deleteCustomerDocument);
  yield takeLatest(EDIT_CUSTOMER_DOCK, editCustomerDocument);
  /** End */

  yield takeLatest(CONSIGN_CUSTOMER, consignCustomer);

  /** Customer Verification */
  yield takeLatest(GET_CUSTOMER_LOADS, getCustomerLoads);
  yield takeLatest(VERIFY_CUSTOMER, verifyCustomer);
  /** End */
};
