import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportActions } from 'store';
import { CalendarInput, ReportDateRow, Loader, MinLoader, NoYet } from 'components';
import { Colors, DownloadFile, FindLoad, Images } from 'utils';
import moment from 'moment';
import { reportStyles } from '../styles';
import { GET_REPORTS, reportEnums } from '../comstants';

export const ApSummary = ({}) => {
  const { allReports } = useSelector((state) => ({
    allReports: state.reports.allReports,
  }));
  const dispatch = useDispatch();
  const loader = FindLoad(GET_REPORTS);
  const { loadDownload, handleDownload } = DownloadFile();
  const [date, selectedDate] = useState(null);
  const classes = reportStyles();

  useEffect(() => {
    handleGetDate();
  }, []);

  const handleGetDate = (sendInfo) => {
    dispatch(reportActions.getReports({ type: reportEnums.AP_SUMMARY, ...sendInfo }));
  };

  const handleChangeDate = (e) => {
    selectedDate(e.target.value);
    const date = moment(e.target.value);
    const sendInfo = {};
    if (e.target.value) {
      sendInfo.month = date.month();
      sendInfo.year = date.year();
    } else {
      delete sendInfo.month;
      delete sendInfo.year;
    }
    handleGetDate(sendInfo);
  };

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div>
      <div className="flex-end">
        <CalendarInput
          value={date}
          handleChangeDate={handleChangeDate}
        />
      </div>
      <div className={classes.arActivityStyles} style={{ marginTop: 24 }}>
        {allReports?.reports?.length ?
          allReports?.reports.map((i, k) => (
            <div key={k} className={classes.arActivityRow}>
              <div className={classes.arActivityRowYear}>
                <img src={Images.exportCalendar} alt="" />
                <p className="align-center"><span style={{ marginRight: 6 }}>Date</span>
                  <ReportDateRow startDate={moment.utc(`01-${i?.month}-${i?.year}`).format()}/>
                  {/*<DateRow date={i?.createdDate} />*/}
                </p>
              </div>
              <button onClick={() => handleDownload({ file: i?.file, name: `${i?.file?.originalName}.csv` })}
                      className={classes.exportButton}>
                {loadDownload === i?.file?._id ?
                  <MinLoader margin={'0 8px 0 0'} color={Colors.ThemeBlue} position={'relative'} />
                  :
                  <img src={Images.exportIcon} alt="" />
                }
                Export
              </button>
            </div>
          ))
          :
          <NoYet position={'center'} text={'No AP Summary Yet'} />
        }
      </div>
    </div>
  );
};
