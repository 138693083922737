import React, { useEffect, useState } from 'react';
import {
  CreateChancel,
  DocumentsComponent,
  ErrMessage,
  SelectTypeAutocomplete,
} from 'components';
import {
  FindErrorItem, FindLoad,
  FindSuccess,
  FindSuccessItem,
  Images,
  useGlobalStyles,
  useGlobalTextStyles,
  useModal,
} from 'utils';
import { loadsStyle } from '../styles';
import { httpRequestsOnLoadActions, httpRequestsOnSuccessActions, loadActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';

const UPLOAD_CSV_ACTION_TYPE = 'UPLOAD_AWS_LOAD_LIST';
const UPLOAD_LOAD_LIST = 'UPLOAD_LOAD_LIST';
export const UploadCsv = ({ afterSuccess }) => {
  const { customers } = useSelector((state) => ({
    customers: state.loads.customers,
  }));
  const globalWrapper = useGlobalStyles();
  const globalStyles = useGlobalStyles();
  const customClass = loadsStyle();
  const globalText = useGlobalTextStyles();
  const dispatch = useDispatch();
  const { close } = useModal();
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const success = FindSuccessItem(UPLOAD_LOAD_LIST);
  const loader = FindLoad(UPLOAD_LOAD_LIST);
  const backError = FindErrorItem(UPLOAD_LOAD_LIST);

  useEffect(() => {
    if (success) {
      afterSuccess();
      close();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(UPLOAD_LOAD_LIST));
    }
  }, [success]);

  const handleUpload = () => {
    if (inputs.customer && currentFile) {
      dispatch(loadActions.uploadLoadList( currentFile, inputs.customer ));
      // dispatch(loadActions.uploadLoadList({ ...currentFile, customerId: inputs.customer }));
    } else {
      setError(
        !inputs.customer ? 'customer' :
          !currentFile ? 'document' : '',
      );
    }
  };

  const handle = (e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        dispatch(loadActions.searchList(e.target.value));
      }, 500);
    }
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    if (error === e.target.name) {
      setError('');
    }
  };

  return (
    <div style={{ width: 500 }}>
      <div>
        <p className={globalText.modalTitle}>{'Choose Customer'}</p>
        <div className={customClass.inputAndButton} style={{ marginTop: 24 }}>
          <SelectTypeAutocomplete
            alwaysError={true}
            style={globalStyles.simpleInputNoRight}
            loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
            name={'customer'}
            label={'name'}
            type={'mc'}
            title={'Select Customer*'}
            handleSelect={handleChange}
            defaultValue={inputs.customer}
            list={customers?.customers?.length ? customers?.customers : []}
            handleType={handle}
            typeError={error === 'customer' ? 'Cannot use this customer due to auto deny' : ''}
            error={error === 'customer' ? 'customer' : ''}
          />
        </div>
        <div style={{ margin: ' 0 0 24px 0' }}>
          <DocumentsComponent
            dockType={'text/csv'}
            actionType={UPLOAD_CSV_ACTION_TYPE}
            handleUpload={(file) => {
              setError('');
              dispatch(httpRequestsOnLoadActions.removeLoading(UPLOAD_CSV_ACTION_TYPE));
              setCurrentFile(file);
            }}
            noDemo={true}
            documents={[]}
            name={'loads'}
            notUpload={true}
          />
          {currentFile &&
            <div className="uploaded-csv">
              <p>Uploaded File</p>
              <button onClick={() => setCurrentFile('')}>
                <img src={Images.remove} alt="" />
              </button>
            </div>
          }
        </div>
        {(error === 'document' || backError?.error) &&
          <div style={{ position: 'relative' }}>
            <ErrMessage
              text={
                backError?.error ? backError.error :
                  error === 'document' ? 'Please upload document' : ''
              }
            />
          </div>
        }
        <CreateChancel
          loader={!!loader.length}
          classes={globalWrapper.buttonsStyle}
          create={'Upload'}
          chancel={'Cancel'}
          onCreate={handleUpload}
          onClose={close}
        />
      </div>
    </div>
  );
};