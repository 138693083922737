import {
  CREATE_LOAD,
  EDIT_LOAD,
  GET_LOAD_BY_ID,
  GET_LOAD_CUSTOMER,
  SEARCH_REC,
  FILTER_REC,
  FILTER_REC_DEFAULT,
  GET_LOAD_CARRIER,
  SEARCH_CARR,
  FILTER_CARR,
  FILTER_CARR_DEFAULT,
  SEARCH_LOAD,
  FILTER_LOAD,
  FILTER_LOAD_DEFAULT,
  GET_LOAD_TRANSACTIONS,
  GET_LOADS_BY_STATUS,
  SEND_TO_PAYABLE,
  APPROVE_PAYABLE,
  GET_LOADS_BY_PAYABLE,
  GET_LOADS_RECEIVABLES,
  GET_PAST_PAYABLE,
  GET_PAST_BILLS,
  CANCEL_LOAD,
  GET_SEARCH_LOAD_CUSTOMERS,
  REMOVE_SEARCHED_CUSTOMERS,
  RE_BILL,
  GET_CARRIER_LAST_LOAD,
  GET_LOAD_CARRIER_TRANSACTIONS,
  CREATE_LOAD_BY_TEMPLATE,
  GET_LOAD_OTHER_TRANSACTIONS,
  GET_LOAD_TOTALS,
  GET_RECEIVABLES_NOTE,
  CREATE_RECEIVABLES_NOTE,
  EDIT_RECEIVABLES_NOTE,
  DELETE_RECEIVABLES_NOTE,
  GET_LOADS_PAYABLE,
  UPLOAD_LOAD_LIST,
  CREATE_CUSTOMER_ORDER,
  UPDATE_CUSTOMER_ORDER,
  DELETE_CUSTOMER_ORDER,
  GET_GROUPED_LOADS,
  GET_GROUP_BY_ID,
  GET_GROUP_LOADS,
  CANCEL_GROUPED_LOADS,
  GET_GROUP_PAYABLE_LOADS,
  GET_GROUP_LOADS_TXNS,
} from './loads.types';

/** Loads Requests, here is All requests for Load page */

/** Create, Edit Load */

export const createLoad = (body) => {
  return {
    type: CREATE_LOAD,
    payload: { body },
  };
};

export const createLoadTemplate = (id, body) => {
  return {
    type: CREATE_LOAD_BY_TEMPLATE,
    payload: { id, body },
  };
};

export const editLoad = (body, id) => {
  return {
    type: EDIT_LOAD,
    payload: { body, id },
  };
};

export const cancelLoad = (id) => {
  return {
    type: CANCEL_LOAD,
    payload: { id },
  };
};

/** End */

/** Get Loads */

export const getLoadTotals = () => {
  return {
    type: GET_LOAD_TOTALS,
  };
};
export const getLoadsByStatus = (params, load) => {
  return {
    type: GET_LOADS_BY_STATUS,
    payload: { params, load },
  };
};

export const getCustomerLoads = (id) => {
  return {
    type: GET_LOAD_CUSTOMER,
    payload: { id },
  };
};

export const getCarrierLoads = (id, params) => {
  return {
    type: GET_LOAD_CARRIER,
    payload: { id, params },
  };
};

export const getCarrierLastLoads = (id, params) => {
  return {
    type: GET_CARRIER_LAST_LOAD,
    payload: { id, params },
  };
};

export const getLoadById = (id, loading) => {
  return {
    type: GET_LOAD_BY_ID,
    payload: { id, loading },
  };
};

export const getLoadsByPayable = (id) => {
  return {
    type: GET_LOADS_BY_PAYABLE,
    payload: id,
  };
};

export const getReceivablesInfo = (params) => {
  return {
    type: GET_LOADS_RECEIVABLES,
    payload: params,
  };
};

export const getPayableInfo = (params) => {
  return {
    type: GET_LOADS_PAYABLE,
    payload: params,
  };
};

/** End */

/** Carriers CarrierPayment */

export const searchCustomerReceivables = (value, type, from) => {
  return {
    type: from === 'carrier' ?
      SEARCH_CARR :
      from === 'load' ?
        SEARCH_LOAD :
        SEARCH_REC,
    payload: { value, type },
  };
};

export const filterByAlphabeticalReceivables = (name, from) => {
  return {
    type: from === 'carrier' ? FILTER_CARR : from === 'load' ? FILTER_LOAD : FILTER_REC,
    payload: { name },
  };
};

export const filterByDefaultReceivables = (name, from, id) => {
  return {
    type: from === 'carrier' ? FILTER_CARR_DEFAULT : from === 'load' ? FILTER_LOAD_DEFAULT : FILTER_REC_DEFAULT,
    payload: { name, id },
  };
};

/** End */


/** Load Transactions */

export const getLoadTransactions = (id, success, params) => {
  return {
    type: GET_LOAD_TRANSACTIONS,
    payload: { id, success, params },
  };
};

export const getLoadCarrierTransactions = (id, success, params) => {
  return {
    type: GET_LOAD_CARRIER_TRANSACTIONS,
    payload: { id, success, params },
  };
};

export const getLoadOtherTransactions = (id, success, params) => {
  return {
    type: GET_LOAD_OTHER_TRANSACTIONS,
    payload: { id, success, params },
  };
};

/** End */

/** Load Payable */

export const sendToPayable = (id, type) => {
  return {
    type: SEND_TO_PAYABLE,
    payload: { id, type },
  };
};

export const approvePayable = (id, type) => {
  return {
    type: APPROVE_PAYABLE,
    payload: { id, type },
  };
};

/** End */

/** Pasts */

export const getPastBill = (id) => {
  return {
    type: GET_PAST_BILLS,
    payload: id,
  };
};

export const reBill = (id) => {
  return {
    type: RE_BILL,
    payload: id,
  };
};

export const getPastPayable = (id) => {
  return {
    type: GET_PAST_PAYABLE,
    payload: id,
  };
};
/** End */

export const searchList = (name) => {
  return {
    type: GET_SEARCH_LOAD_CUSTOMERS,
    payload: name,
  };
};

export const removeSearchedList = () => {
  return {
    type: REMOVE_SEARCHED_CUSTOMERS,
  };
};

/** Receivable Notes */

export const getRecNotes = (params, load, search) => {
  return {
    type: GET_RECEIVABLES_NOTE,
    payload: { params, load, search },
  };
};
export const createRecNote = (body) => {
  return {
    type: CREATE_RECEIVABLES_NOTE,
    payload: { body },
  };
};

export const editRecNote = (id, body, sendParams) => {
  return {
    type: EDIT_RECEIVABLES_NOTE,
    payload: { id, body, sendParams },
  };
};

export const deleteRecNote = (id) => {
  return {
    type: DELETE_RECEIVABLES_NOTE,
    payload: { id },
  };
};


export const uploadLoadList = (file, id) => {
  return {
    type: UPLOAD_LOAD_LIST,
    payload: { file, id },
  };
};

/** End */


/** Customer Order */
export const createCustomerOrder = (body, loadId) => {
  return {
    type: CREATE_CUSTOMER_ORDER,
    payload: { body, loadId },
  };
};

export const editCustomerOrder = (orderId, body, loadId) => {
  return {
    type: UPDATE_CUSTOMER_ORDER,
    payload: { orderId, body, loadId },
  };
};
export const deleteCustomerOrder = (loadId, orderId) => {
  return {
    type: DELETE_CUSTOMER_ORDER,
    payload: { loadId, orderId },
  };
};

/** End */

/** Grouped Loads */

export const getGroupedLoads = (params, load) => {
  return {
    type: GET_GROUPED_LOADS,
    payload: { params, load },
  };
};

export const getGroupById = (id, load) => {
  return {
    type: GET_GROUP_BY_ID,
    payload: { id, load },
  };
};

export const getGroupLoads = (id, load) => {
  return {
    type: GET_GROUP_LOADS,
    payload: { id, load },
  };
};

export const cancelGroup = (id, load) => {
  return {
    type: CANCEL_GROUPED_LOADS,
    payload: { id, load },
  };
};
export const getGroupPayableLoads = (id) => {
  return {
    type: GET_GROUP_PAYABLE_LOADS,
    payload: { id },
  };
};

export const getGroupLoadsTxns = (id) => {
  return {
    type: GET_GROUP_LOADS_TXNS,
    payload: { id },
  };
};

/** End */