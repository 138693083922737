import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField} from '@material-ui/core';
import { inputsStyle } from './styles';
import { ErrorText, FindLoad, useGlobalStyles} from 'utils';
import { ErrMessage } from '../messages';
import {InputMinLoader} from "./inputMiniLoader";

export const SelectTypeAutocomplete = ({
                                         list,
                                         label,
                                         type,
                                         title,
                                         defaultValue,
                                         name,
                                         handleSelect,
                                         style,
                                         disabled,
                                         error,
                                         typeError,
                                         handleType,
                                         loadType,
                                         custom,
                                         right,
                                         alwaysError
                                       }) => {
  const classes = inputsStyle();
  const globalStyles = useGlobalStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const loader = FindLoad(loadType);

  const defaultProps = {
    options: value ? list?.length && list?.filter((i) => i.id !== value.id) : list?.length ? list : [],
    getOptionLabel: (option) => {
      if (custom) {
        return custom(option);
      }
      if (label) {
        const typeLabel = type && option[type] ? ` (${option[type]})` : '';
        return `${option[label]}${typeLabel}`;
      }
      return '';
    },
    // getOptionLabel: (option) =>
    //   custom ? custom(option) :
    //     label && type ? `${option[label]}  ${ option[type ? `(${option[type]})` : ''}` : option[label] || '',
  };

  const handleClick = (e, ev) => {
    setValue(ev);
    if (ev) {
      let info = { target: { value: ev.id, name: name } };
      handleSelect(info);
      setValue(ev.id);
    } else {
      let info = { target: { value: '', name: name } };
      handleSelect(info);
      setValue('');
    }
  };

  useEffect(() => {
    if (defaultValue) {
      list?.find((i, k) => {
        i.id === defaultValue ? setValue(i) : '';
      });
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  return (
    <>
      <div className={style ? style : ''}>
        <Autocomplete
          loading={!!loader?.length}
          className={
            error === name ? globalStyles.inputTextFieldError :
              defaultValue ? globalStyles.inputTextFieldBlue :
                classes.inputTextField
          }
          value={value}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disabled={disabled}
          {...defaultProps}
          style={{ width: '100%', marginRight: right ? right : '56px' }}
          onChange={(event, newValue) => {
            handleClick(event, newValue);
          }}
          id="disable-close-on-select"
          name={name}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label={title}
                  variant="outlined"
                  onChange={(e) => handleType && handleType(e)}
                  InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                          <>
                              {params?.InputProps?.endAdornment}
                              {loader?.length ? <InputMinLoader /> : null}
                          </>
                      ),
                  }}
              />
          )}
        />
        { (error === name || alwaysError) &&
          <ErrMessage text={typeError ? typeError : alwaysError ? '' : ErrorText.field} style={{ position: 'relative' }} />
        }
      </div>
    </>
  );
};